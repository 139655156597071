<template>
  <div style="width: 100%;">
    <Navbar :title="$t('MiddleEdit.title')" :show_back="true" :sticky="false" />

    <div class="user-outer" v-show="user_info">
      <div class="user-left-container">
        <img :src="'https://i.boatonland.com/avatar/' + (user_avatar || user_info.user_avatar) + '.svg'"
          class="user-avatar" v-show="user_info.user_avatar">
        <div class="user-serial">{{ user_serial || user_info.user_serial }}</div>
      </div>
      <div class="user-button" @click="updateProfile">{{ $t('MiddleEdit.save') }}</div>
    </div>

    <div class="menu-outer">
      <div class="menu-item">
        <div class="menu-left">
          <div class="menu-left-text">
            <div class="menu-left-title">{{ $t('MiddleEdit.edit_user_id_title') }}</div>
            <div class="menu-left-info">{{ $t('MiddleEdit.edit_user_id_info') }}</div>
          </div>
        </div>
        <div class="menu-right">
          <input class="menu-input" v-model="user_serial" :placeholder="$t('MiddleEdit.edit_user_id_placeholder')"
            @keyup.enter="updateProfile" />
        </div>
      </div>
    </div>

    <div class="menu-outer">
      <div class="menu-item">
        <div class="menu-left">
          <div class="menu-left-text">
            <div class="menu-left-title">{{ $t('MiddleEdit.exchange_avatar_title') }}</div>
            <div class="menu-left-info">{{ $t('MiddleEdit.exchange_avatar_info') }}</div>
          </div>
        </div>
        <div class="menu-right">
          <input class="menu-input" v-model="avatar_sdk_content"
            :placeholder="$t('MiddleEdit.exchange_avatar_placeholder')" @keyup.enter="exchangeAvatar" />
        </div>
      </div>
      <div class="big-button" v-if="avatar_sdk_content" @click="exchangeAvatar">
        <p style="font-weight: 600">{{ $t('MiddleEdit.exchange') }}</p>
      </div>
    </div>

    <div class="avatar-collection-outer" v-for="(collection, index) in avatar_collection" :key="index">
      <div>{{ collection.avatar_collection_name }}<span v-if="!collection.avater_collection_is_owned">{{
        $t('MiddleEdit.not_owned') }}</span></div>
      <div class="avatar-collection-container">
        <div v-for="(avatar, avatar_index) in collection.avatar_list" :key="avatar_index"
          :class="(user_avatar == avatar.avatar_name) ? 'avatar-outer-active' : 'avatar-outer'"
          @click="chooseAvatar(avatar.avatar_name)">
          <img class="single-avatar" :src="'https://i.boatonland.com/avatar/' + avatar.avatar_name + '.svg'" />
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import request from '../utils/request.js'
import notice from '../utils/notice.js'
import Navbar from './Navbar.vue'

export default {
  name: 'UniMiddleEdit',
  components: { Navbar },
  created() {
    this.$loading.show()
    this.getUserProfile()
    this.getAvatarCollection()
    const user_school_label = localStorage.getItem('user_school_label')
    if (user_school_label == "HKU") {
      document.title = this.$t('MiddleEdit.title') + " / " + this.$t('AppNameTitle.HKU')
    }
    if (user_school_label == "CUHK") {
      document.title = this.$t('MiddleEdit.title') + " / " + this.$t('AppNameTitle.CUHK')
    }
    if (user_school_label == "UST") {
      document.title = this.$t('MiddleEdit.title') + " / " + this.$t('AppNameTitle.UST')
    }
  },
  data() {
    return {
      user_info: {},
      avatar_collection: [],
      user_serial: '',
      user_avatar: '',
      avatar_sdk_content: ''
    }
  },
  methods: {
    getUserProfile() {
      request("/user/profile/get")
        .then((res) => {
          if (!res) return
          this.user_info = res.user_info
        })
    },
    getAvatarCollection() {
      request("/user/avatar/get")
        .then((res) => {
          this.$loading.hide()
          if (!res) return
          this.avatar_collection = res.avatar_collection
        })
    },
    exchangeAvatar() {
      this.$loading.show()
      request("/user/avatar/exchange", {
        avatar_sdk_content: this.avatar_sdk_content
      })
        .then((res) => {
          this.$loading.hide()
          if (!res) return
          notice({
            content: this.$t('/user/avatar/exchange.200')
          })
          this.getAvatarCollection()
        })
    },
    updateProfile() {
      const user_serial = this.user_serial || this.user_info.user_serial
      if (user_serial.match(/^\s*$/) || user_serial.length < 3) {
        this.$modal.show({
          content: this.$t('MiddleEdit.user_id_at_least_3_char')
        })
        return
      }
      if (!user_serial.match(/^^[a-z0-9]+$/)) {
        this.$modal.show({
          content: this.$t('MiddleEdit.user_id_illegal')
        })
        return
      }
      this.$loading.show()
      request("/user/profile/update", {
        user_serial: user_serial,
        user_avatar: this.user_avatar || this.user_info.user_avatar
      })
        .then((res) => {
          this.$loading.hide()
          if (!res) return
          notice({
            content: this.$t('/user/profile/update.200')
          })
          this.getUserProfile()
        })
    },
    chooseAvatar(avatar_name) {
      if (this.user_avatar == avatar_name) {
        this.user_avatar = ''
      } else {
        this.user_avatar = avatar_name
      }
    }

  }
}
</script>

<style scoped>
.user-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-top: 0.1rem solid var(--border-grey);
  border-bottom: 0.1rem solid var(--border-grey);
  padding: 20px;
  background: var(--bg);
  position: sticky;
  top: 0;
  z-index: 100;
}

.user-left-container {
  display: flex;
  align-items: center;
}

.user-avatar {
  height: 45px;
}

.user-serial {
  color: var(--text);
  font-weight: 600;
  margin-left: 15px;
  font-size: 24px;
}

.user-button {
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 20px;
  color: #FFFFFF;
  background-color: var(--main);
}

.user-button:hover {
  background-color: var(--main-darker);
  cursor: pointer;
}

.menu-outer {
  padding: 20px 15px;
  background-color: var(--bg);
  border-bottom: 0.1rem solid var(--border-grey);
}

.menu-item {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.menu-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.menu-left-text {
  display: flex;
  flex-direction: column;
}

.menu-left-title {
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text);
}

.menu-left-info {
  font-size: 12px;
  color: var(--text-grey);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10rpx;
}

.menu-input {
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
  padding: 0;
  text-align: right;
  font-size: 14px;
  color: var(--text);
}

.big-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 20px 0 20px;
  padding: 0.8rem 1rem;
  gap: 0.5rem;
  background-color: var(--main);
  border-radius: 2rem;
}

.big-button p {
  font-size: 1rem;
  color: #FFFFFF;
  margin: 0;
}

.big-button:hover {
  background-color: var(--main-darker);
  cursor: pointer;
}

.avatar-collection-outer {
  padding: 20px 15px;
  font-size: 16px;
  background-color: var(--bg);
  border-bottom: 0.1rem solid var(--border-grey);
  color: var(--text);
}

.avatar-collection-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}


.single-avatar {
  width: 50px;
  height: 50px;
}

.avatar-outer {
  text-align: center;
  padding: 10px;
  margin: 5px;
  border: 0.15rem solid var(--border-grey);
  border-radius: 16px;
}

.avatar-outer-active {
  text-align: center;
  padding: 10px;
  margin: 5px;
  border: 0.15rem solid var(--main);
  border-radius: 16px;
}

@media only screen and (max-width: 700px) {
  .user-outer{
    padding: 15px;
  }
}
</style>