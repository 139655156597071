<template>
  <div style="width: 100%;">
    <Navbar :title="$t('MiddleAbout.title')" />
    <div class="main-outer">
      <div class="logo-outer">
        <div class="logo-wrapper">
          <div class="logo-ripple" />
          <img style="background-color: white;" class="logo" :src="logo_src" />
        </div>
        <div class="app-name">{{ app_name }}</div>
        <div class="version-text">Web: 1.3.1</div>
      </div>
      <div class="info-outer">
        <div class="info-container">
          <img class="qr" src="../assets/qr-HKU.jpg" />
          <div class="info-text">{{ $t('MiddleAbout.developed_by') }}</div>
        </div>
        <div class="info-container">
          <img class="qr" :src="qr_src" />
          <div class="info-text">{{ $t('MiddleAbout.operated_by', {team_name: team_name}) }}</div>
        </div>
      </div>
    </div>

    <div class="log-outer">
      <div class="log-version">1.3.0</div>
      <p>{{ $t('MiddleAbout.log.1-3-0.1') }}</p>
      <p>{{ $t('MiddleAbout.log.1-3-0.2') }}</p>
      <p>{{ $t('MiddleAbout.log.1-3-0.3') }}</p>
    </div>

    <div class="log-outer">
      <div class="log-version">1.2.0</div>
      <p>{{ $t('MiddleAbout.log.1-2-0.1') }}</p>
      <p>{{ $t('MiddleAbout.log.1-2-0.2') }}</p>
      <p>{{ $t('MiddleAbout.log.1-2-0.3') }}</p>
    </div>

    <div class="log-outer">
      <div class="log-version">1.1.0</div>
      <p>{{ $t('MiddleAbout.log.1-1-0.1') }}</p>
      <p>{{ $t('MiddleAbout.log.1-1-0.2') }}</p>
      <p>{{ $t('MiddleAbout.log.1-1-0.3') }}</p>
    </div>

    <div class="log-outer">
      <div class="log-version">1.0.0</div>
      <p>{{ $t('MiddleAbout.log.1-0-0.1') }}</p>
      <p>{{ $t('MiddleAbout.log.1-0-0.2') }}</p>
      <p>{{ $t('MiddleAbout.log.1-0-0.3') }}</p>
      <p>{{ $t('MiddleAbout.log.1-0-0.4') }}</p>
    </div>

  </div>
</template>
    
<script>
// import request from '../utils/request.js'
// import formatTime from '../utils/formatTime.js'
import Navbar from './Navbar.vue'

export default {
  name: 'UniMiddleAbout',
  components: { Navbar },
  created() {
    const user_school_label = localStorage.getItem('user_school_label')
    if (user_school_label == "HKU") {
      this.app_name = this.$t('AppName.HKU');
      this.team_name = this.$t('TeamName.HKU');
      this.logo_src = require('../assets/logo-HKU.png');
      this.qr_src = require('../assets/qr-HKU.jpg');
      document.title = this.$t('MiddleAbout.title') + " / " + this.$t('AppNameTitle.HKU')
    }
    if (user_school_label == "CUHK") {
      this.app_name = this.$t('AppName.CUHK');
      this.team_name = this.$t('TeamName.CUHK');
      this.logo_src = require('../assets/logo-CUHK.png');
      this.qr_src = require('../assets/qr-CUHK.jpg');
      document.title = this.$t('MiddleAbout.title') + " / " + this.$t('AppNameTitle.CUHK')
    }
    if (user_school_label == "UST") {
      this.app_name = this.$t('AppName.UST');
      this.team_name = this.$t('TeamName.UST');
      this.logo_src = require('../assets/logo-UST.png');
      this.qr_src = require('../assets/qr-UST.jpg');
      document.title = this.$t('MiddleAbout.title') + " / " + this.$t('AppNameTitle.UST')
    }
  },
  data() {
    return {
      app_name: '',
      team_name: '',
      qr_src: '',
      logo_src: ''
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.logo-outer {
  margin: 50px 0;
  text-align: center;
  flex: 1;
}

.logo-wrapper {
  width: 80px;
  height: 80px;
  position: relative;
  display: inline-block;
}

.logo {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 80px;
}

.logo-ripple {
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  border-radius: 50%;
  border: 0.1rem solid var(--main);
  z-index: -1;
  animation-name: logo-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes logo-animation {
  0% {
    opacity: 0.8;
    transform: scale(1);
  }

  80% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.app-name {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
  color: var(--text);
}

.version-text {
  margin-top: 10px;
  font-size: 16px;
  color: var(--text);
}

.info-outer {
  margin: 50px 0;
  text-align: center;
  display: flex;
}

.info-container {
  flex: 1;
}

.info-text {
  font-size: 16px;
  color: var(--text);
}

.qr {
  height: 80px;
  width: 80px;
}

.log-outer {
  border-bottom: 0.1rem solid var(--border-grey);
  padding: 20px;
  background-color: var(--bg);
}

.main-outer {
  border-bottom: 0.1rem solid var(--border-grey);
}

.log-version {
  line-height: 30px;
  font-size: 18px;
  font-weight: bold;
  color: var(--text);
}

.log-outer p{
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
  font-size: 15px;
  color: var(--text);
}
</style>