import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en.json'
import zh_CN from './zh_CN.json'

Vue.use(VueI18n)
export const i18n = new VueI18n({
  fallbackLocale: 'zh_CN',
  messages:{
    en: en,
    zh_CN: zh_CN
  }
})

const user_language = localStorage.getItem('language') || navigator.language || navigator.userLanguage
if (user_language.startsWith('zh')) {
    i18n.locale = "zh_CN"
  } else {
    i18n.locale = "en"
  }

export default i18n