<template>
    <div>
        <Splash v-show="!finish_loading" />
        <div class="body" v-show="finish_loading">
            <!-- Left Sidebar -->
            <div class="left-sidebar">
                <div class="links-flex">
                    <div class="logo" @click="nav2('/home')">
                        <img :src="logo_src" class="main-logo" alt="Logo" />
                    </div>
                    <div :class="($route.path == '/home') ? 'link-flex link-active' : 'link-flex'"
                        @click="nav2('/home')">
                        <HomeSvg class="link-icon" />
                        <p class="link-text">{{ $t('Main.home') }}</p>
                    </div>
                    <div :class="$route.path.startsWith('/CUCampus') ? 'link-flex link-active' : 'link-flex'"
                        @click="nav2('/CUCampus')" v-if="user_school_label == 'CUHK'">
                        <CalendarSvg class="link-icon" />
                        <p class="link-text">{{ $t('Main.CUCampus') }}</p>
                    </div>
                    <div :class="$route.path.startsWith('/search') ? 'link-flex link-active' : 'link-flex'"
                        @click="nav2('/search/')">
                        <SearchSvg class="link-icon" />
                        <p class="link-text">{{ $t('Main.search') }}</p>
                    </div>
                    <div :class="($route.path == '/notice') ? 'link-flex link-active' : 'link-flex'"
                        @click="nav2Notice">
                        <NoticeSvg class="link-icon" />
                        <p class="link-text">{{ $t('Main.notice') }}</p>
                        <div class="notice-tip" v-if="unread_notice > 0">{{ unread_notice }}</div>
                    </div>
                    <div :class="$route.path.startsWith('/chat') ? 'link-flex link-active' : 'link-flex'"
                        @click="nav2('/chat')">
                        <PmSvg class="link-icon" />
                        <p class="link-text">{{ $t('Main.pm') }}</p>
                    </div>
                    <div :class="($route.path == '/mine') ? 'link-flex link-active' : 'link-flex'"
                        @click="nav2('/mine')">
                        <MineSvg class="link-icon" />
                        <p class="link-text">{{ $t('Main.mine') }}</p>
                    </div>
                    <div :class="($route.path == '/write') ? 'link-flex link-active' : 'link-flex'"
                        @click="nav2('/write')">
                        <WriteSvg class="link-icon" />
                        <p class="link-text">{{ $t('Main.write') }}</p>
                    </div>
                    <div :class="($route.path == '/orgadmin') ? 'link-flex link-active' : 'link-flex'"
                        @click="nav2('/orgadmin')" v-if="user_is_org">
                        <OrgSvg class="link-icon" />
                        <p class="link-text">{{ $t('Main.org') }}</p>
                    </div>
                </div>
            </div>


            <template v-if="$route.path == '/chat'">
                <div class="middle-section-chat-list">
                    <keep-alive><router-view name="middle" v-if="$route.meta.keepAlive"></router-view></keep-alive>
                    <router-view name="middle" v-if="!$route.meta.keepAlive"></router-view>
                </div>
                <div class="right-sidebar-chat-list">
                    <keep-alive><router-view name="right" v-if="$route.meta.keepAlive"></router-view></keep-alive>
                    <router-view name="right" v-if="!$route.meta.keepAlive"></router-view>
                </div>
            </template>



            <template v-else-if="$route.path.startsWith('/chat/')">
                <div class="middle-section-chat-detail">
                    <keep-alive><router-view name="middle" v-if="$route.meta.keepAlive"></router-view></keep-alive>
                    <router-view name="middle" v-if="!$route.meta.keepAlive"></router-view>
                </div>
                <div class="right-sidebar-chat-detail">
                    <keep-alive><router-view name="right" v-if="$route.meta.keepAlive"
                            :key="$route.params.chat_id"></router-view></keep-alive>
                    <router-view name="right" v-if="!$route.meta.keepAlive" :key="$route.params.chat_id"></router-view>
                </div>
            </template>



            <template
                v-else-if="$route.path == '/home' || $route.path.startsWith('/search') || $route.path == '/chat' || $route.path == '/mine'">
                <div class="middle-section-tab">
                    <keep-alive><router-view name="middle" v-if="$route.meta.keepAlive"></router-view></keep-alive>
                    <router-view name="middle" v-if="!$route.meta.keepAlive"></router-view>
                </div>
                <div class="right-sidebar">
                    <keep-alive><router-view name="right" v-if="$route.meta.keepAlive"></router-view></keep-alive>
                </div>
            </template>



            <template v-else-if="$route.path.startsWith('/CUCampus')">
                <div class="middle-section-iframe">
                    <keep-alive><router-view name="middle" v-if="$route.meta.keepAlive"
                            :key="$route.fullPath"></router-view></keep-alive>
                    <router-view name="middle" v-if="!$route.meta.keepAlive"></router-view>
                </div>
            </template>



            <template v-else>
                <div class="middle-section">
                    <keep-alive><router-view name="middle" v-if="$route.meta.keepAlive"
                            :key="$route.fullPath"></router-view></keep-alive>
                    <router-view name="middle" v-if="!$route.meta.keepAlive"></router-view>
                </div>
                <div class="right-sidebar">
                    <router-view name="right" v-if="$route.path == '/write'"></router-view>
                    <keep-alive v-else><router-view name="right"></router-view></keep-alive>
                </div>
            </template>




            <div class="tabbar"
                v-show="$route.path == '/home' || $route.path.startsWith('/CUCampus') || $route.path.startsWith('/search') || $route.path == '/chat' || $route.path == '/mine'">
                <div :class="($route.path == '/home') ? 'link-flex link-active' : 'link-flex'" @click="nav2('/home')">
                    <HomeSvg class="link-icon" />
                </div>
                <div v-if="user_school_label == 'CUHK'" :class="($route.path.startsWith('/CUCampus')) ? 'link-flex link-active' : 'link-flex'" @click="nav2('/CUCampus')">
                    <CalendarSvg class="link-icon" />
                </div>
                <div :class="$route.path.startsWith('/search') ? 'link-flex link-active' : 'link-flex'"
                    @click="nav2('/search/')">
                    <SearchSvg class="link-icon" />
                </div>
                <div :class="($route.path == '/chat') ? 'link-flex link-active' : 'link-flex'" @click="nav2('/chat')">
                    <PmSvg class="link-icon" />
                    <div class="pm-tip" v-if="unread_notice > 0">{{ unread_notice }}</div>
                </div>
                <div :class="($route.path == '/mine') ? 'link-flex link-active' : 'link-flex'" @click="nav2('/mine')">
                    <MineSvg class="link-icon" />
                </div>
            </div>
            <img :src="write_src" class="write-button" v-show="$route.path == '/home'" @click="nav2('/write')" />
        </div>
    </div>
</template>

<script>
import HomeSvg from './Icon/HomeSvg.vue'
import SearchSvg from './Icon/SearchSvg.vue'
import NoticeSvg from './Icon/NoticeSvg.vue'
import PmSvg from './Icon/PmSvg.vue'
import MineSvg from './Icon/MineSvg.vue'
import WriteSvg from './Icon/WriteSvg.vue'
import OrgSvg from './Icon/OrgSvg.vue'
import CalendarSvg from './Icon/CalendarSvg.vue'
import Splash from './Splash.vue'

import { finish_loading } from '../utils/splash.js'

export default {
    name: 'UniMain',
    components: { HomeSvg, SearchSvg, NoticeSvg, PmSvg, MineSvg, WriteSvg, OrgSvg, CalendarSvg, Splash },
    mounted() {
        this.logo_src = require('../assets/logo-' + localStorage.getItem('user_school_label') + '.png')
        this.write_src = require('../assets/write-' + localStorage.getItem('user_school_label') + '.svg')
        this.user_is_org = localStorage.getItem('user_is_org')
        this.user_school_label = localStorage.getItem('user_school_label')
        this.updateUnreadCount()
        window.addEventListener('updateUnreadNotice', () => {
            this.updateUnreadCount()
        })
        window.addEventListener('finishInitLoading', () => {
            this.finishInitLoading()
        })
        if (this.$route.path.startsWith('/CUCampus')) this.finish_loading = true
    },
    data() {
        return {
            logo_src: null,
            write_src: null,
            user_is_org: false,
            user_school_label: '',
            unread_notice: 0,
            finish_loading: finish_loading,
        }
    },
    methods: {
        nav2(url, query = {}) {
            this.$router.push({ path: url, query: query })
        },
        updateUnreadCount() {
            this.unread_notice = localStorage.getItem('unread_notice')
        },
        finishInitLoading() {
            if (!this.finish_loading) this.finish_loading = true
        },
        nav2Notice() {
            this.$router.push('/notice')
            let event = new Event('refreshNotice')
            window.dispatchEvent(event)
        }
    }
}
</script>

<style scoped>
.body {
    background-color: var(--white);
    color: var(--black);
    margin: 0;
    display: flex;
}

.left-sidebar {
    position: sticky;
    width: 25vw;
    height: 100vh;
    height: calc(100vh - env(safe-area-inset-bottom));
    left: 0;
    top: 0;
    border-right: 0.1rem solid var(--border-grey);
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.middle-section {
    position: sticky;
    width: 45vw;
    top: 0;
    background-color: var(--white);
}

.middle-section-iframe {
    position: sticky;
    width: calc(75vw - 0.1rem);
    top: 0;
    background-color: var(--white);
}

.right-sidebar {
    position: sticky;
    width: 30vw;
    height: 100vh;
    height: calc(100vh - env(safe-area-inset-bottom));
    right: 0;
    top: 0;
    border-left: 0.1rem solid var(--border-grey);
}

.middle-section-tab {
    position: sticky;
    width: 45vw;
    top: 0;
    background-color: var(--white);
}

.middle-section-chat-list {
    position: sticky;
    width: 30vw;
    top: 0;
    background-color: var(--white);
}

.right-sidebar-chat-list {
    position: sticky;
    width: calc(45vw - 0.1rem);
    height: 100vh;
    height: calc(100vh - env(safe-area-inset-bottom));
    right: 0;
    top: 0;
    border-left: 0.1rem solid var(--border-grey);
    border-right: 0.1rem solid var(--border-grey);
}

.middle-section-chat-detail {
    position: sticky;
    width: 30vw;
    top: 0;
    background-color: var(--white);
}

.right-sidebar-chat-detail {
    position: sticky;
    width: calc(45vw - 0.1rem);
    height: 100vh;
    height: calc(100vh - env(safe-area-inset-bottom));
    right: 0;
    top: 0;
    border-left: 0.1rem solid var(--border-grey);
    border-right: 0.1rem solid var(--border-grey);
}

.tabbar {
    display: none;
}

.links-flex {
    display: flex;
    align-items: flex-start;
    margin: 0.5rem 1rem;
    cursor: pointer;
    width: 16rem;
    flex-direction: column;
}

.logo {
    padding: 1rem;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
}

.logo:hover {
    background-color: var(--border-grey);
}

.main-logo {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
}

.link-flex {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
    padding-right: 2rem;
    margin: 0.1rem 0;
    border-radius: 2rem;
    transition: 0.3s;
}

.link-active {
    background-color: var(--border-grey);
}

.link-flex:hover {
    background-color: var(--border-grey);
}

.link-icon {
    width: 1.8rem;
    height: 1.8rem;
    color: var(--text);
}

.link-text {
    font-size: 1.3rem;
    font-weight: 600;
    margin: 1rem 0rem;
    color: var(--text);
}

.write-button {
    display: none;
}

.notice-tip {
    position: absolute;
    right: 84px;
    top: 12px;
    height: 18px;
    line-height: 18px;
    background: var(--tip);
    border-radius: 9px;
    font-size: 12px;
    color: #FFFFFF;
    padding: 0 6px;
}

.pm-tip {
    display: none;
}

@media only screen and (min-width: 1600px) {
    .left-sidebar {
        width: 400px !important;
    }

    .middle-section {
        width: 720px !important;
    }

    .middle-section-iframe {
        max-width: calc(1200px + 0.1rem) !important;
    }

    .right-sidebar {
        width: 480px !important;
    }

    .middle-section-tab {
        width: 720px !important;
    }

    .middle-section-chat-list {
        width: 480px !important;
    }

    .right-sidebar-chat-list {
        width: calc(720px - 0.1rem) !important;
    }

    .middle-section-chat-detail {
        width: 480px !important;
    }

    .right-sidebar-chat-detail {
        width: calc(720px - 0.1rem) !important;
    }

    .body {
        display: flex;
        justify-content: center;
    }

}

@media only screen and (max-width: 1200px) {
    .left-sidebar {
        width: 4rem !important;
        padding-left: env(safe-area-inset-left);
    }

    .link-text {
        display: none;
    }

    .links-flex {
        margin: 0;
    }

    .link-flex {
        /* display: block; */
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
        padding: 0.3rem;
    }

    .link-flex:hover {
        background-color: var(--border-grey);
        border-radius: 50%;
    }

    .link-active {
        background-color: var(--bg-grey);
        border-radius: 50%;
    }

    .middle-section {
        flex: 1;
    }

    .middle-section-iframe {
        flex: 1;
    }

    .right-sidebar {
        flex: 0.7;
    }

    .middle-section-tab {
        flex: 1;
    }

    .middle-section-chat-list {
        flex: 1;
    }

    .right-sidebar-chat-list {
        flex: 1;
    }

    .middle-section-chat-detail {
        flex: 1;
    }

    .right-sidebar-chat-detail {
        flex: 1;
    }

    .notice-tip {
        right: 0px;
        top: 0px;
    }

}

@media only screen and (max-width: 1000px) {
    .right-sidebar {
        display: none;
    }

    .right-sidebar-chat-list {
        display: none;
    }

    .middle-section-chat-detail {
        display: none;
    }

    .right-sidebar-chat-detail {
        border: none;
    }
}

@media only screen and (max-width: 700px) {
    .left-sidebar {
        display: none;
    }

    .middle-section-tab {
        margin-bottom: 55px;
        margin-bottom: calc(55px + env(safe-area-inset-bottom));
    }

    .middle-section-chat-list {
        margin-bottom: 55px;
        margin-bottom: calc(55px + env(safe-area-inset-bottom));
    }


    .tabbar {
        position: fixed;
        bottom: 0;
        z-index: 99;
        width: 100%;
        height: 55px;
        display: flex;
        background-color: var(--bg);
        border-top: 0.1rem solid var(--border-grey);
        padding-bottom: env(safe-area-inset-bottom);
    }

    .link-flex {
        margin-top: auto;
        margin-bottom: auto;
        padding: 0.5rem 1rem;
        border-radius: 1rem;
        /* margin-bottom: 1rem; */
    }

    .link-flex:hover {
        background-color: var(--bg-grey);
        border-radius: 1rem;
    }


    .write-button {
        display: block;
        position: fixed;
        background-color: var(--bg);
        width: 60px;
        height: auto;
        border-radius: 50%;
        right: 20px;
        bottom: 70px;
        bottom: calc(70px + env(safe-area-inset-bottom));
        z-index: 999;
    }

    .notice-tip {
        display: none;
    }

    .pm-tip {
        display: block;
        position: absolute;
        right: 8px;
        top: 3px;
        height: 16px;
        line-height: 16px;
        background: var(--tip);
        border-radius: 8px;
        font-size: 10px;
        color: #FFFFFF;
        padding: 0 6px;
    }
}
</style>