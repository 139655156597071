<template>
  <div style="width: 100%;">
    <BackSvg class="nav-bar-title-back" @click.native="navBack" />
    <div class="org-outer" v-show="org_info.org_name">
      <div class="org-bg-container">
        <div class="org-bg-ratio"></div>
        <img :src="org_info.org_bg_image" class="org-bg">
      </div>
      <div class="org-container">

        <div class="org-name-and-avatar">
          <div class="org-name">{{ org_info.org_name }}</div>
          <div class="org-avatar-container">
            <img class="org-avatar" :src="org_info.org_avatar" />
          </div>
        </div>

        <div class="org-intro-container">
          <span class="org-intro">{{ org_info.org_intro }}</span>
        </div>

        <Swiper :showIndicator="false" interval="3500" v-if="org_info.org_banner && org_info.org_banner.length > 0"
          class="banner-container">
          <Slide v-for="(banner, index) in org_info.org_banner" :key="index">
            <img :src="banner.banner_image" class="banner-image" @click="onTapBanner(banner)">
          </Slide>
        </Swiper>

        <div class="org-function-outer" v-if="org_info.org_function_main">

          <div class="org-function-container" v-if="org_info.org_function_main"
            @click="handleFunction($event, org_function_main)">
            <div class="org-function-inner">
              <div class="org-function-title">{{ org_info.org_function_main.function_title }}</div>
              <img class="org-function-arrow" src="../assets/arrow.svg" />
            </div>
          </div>

        </div>


        <div class="org-function-outer" v-if="org_info.org_function_half && org_info.org_function_half[0]">

          <div class="org-function-container" v-if="org_info.org_function_half[0]"
            @click="handleFunction($event, org_info.org_function_half[0])">
            <div class="org-function-inner">
              <div class="org-function-title">{{ org_info.org_function_half[0].function_title }}</div>
              <img class="org-function-arrow" src="../assets/arrow.svg" />
            </div>
          </div>

          <div class="org-function-container" v-if="org_info.org_function_half[1]"
            @click="handleFunction($event, org_info.org_function_half[1])">
            <div class="org-function-inner">
              <div class="org-function-title">{{ org_info.org_function_half[1].function_title }}</div>
              <img class="org-function-arrow" src="../assets/arrow.svg" />
            </div>
          </div>

        </div>

        <div class="org-function-outer" v-if="org_info.org_function_half && org_info.org_function_half[2]">

          <div class="org-function-container" v-if="org_info.org_function_half[2]"
            @click="handleFunction($event, org_info.org_function_half[2])">
            <div class="org-function-inner">
              <div class="org-function-title">{{ org_info.org_function_half[2].function_title }}</div>
              <img class="org-function-arrow" src="../assets/arrow.svg" />
            </div>
          </div>

          <div class="org-function-container" v-if="org_info.org_function_half[3]"
            @click="handleFunction($event, org_info.org_function_half[3])">
            <div class="org-function-inner">
              <div class="org-function-title">{{ org_info.org_function_half[3].function_title }}</div>
              <img class="org-function-arrow" src="../assets/arrow.svg" />
            </div>
          </div>

        </div>


      </div>
    </div>
    <Refresh :on-refresh="onRefresh">
      <One :type="item.type" :data="item.data" v-for="(item, index) in one_list" :key="index" />
      <LoadMore :is_last="is_last" v-show="is_loading_more || is_last" />
    </Refresh>
  </div>
</template>
    
<script>
import request from '../utils/request.js'
import { localizeTopic } from '../utils/localize';

import LoadMore from './LoadMore.vue'
import One from './One.vue'
import { Swiper, Slide } from 'vue-swiper-component'

import BackSvg from './Icon/BackSvg.vue'

export default {
  name: 'UniMiddleOrg',
  components: { LoadMore, One, Swiper, Slide, BackSvg },
  created() {
    this.org_name = this.$route.params.org_name
    this.$loading.show()
    this.page = 0
    this.getOrgProfile()
  },
  activated() {
    document.title = this.title
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  data() {
    return {
      org_name: null,
      page: 0,
      one_list: [],
      is_last: false,
      is_loading_more: false,
      finishRefresh: null,
      org_info: {},
      title: ''
    }
  },
  methods: {
    navBack() {
      if (window.history.length <= 1) {
        this.$router.push('/home')
      } else {
        this.$router.back()
      }
    },
    getOrgProfile() {
      request("/user/profile/org", {
        visit_user_serial: this.org_name,
      }, true, [400])
        .then((res) => {
          if (!res) return
          if (res.code == 400) {
            this.$modal.show({
              content: this.$t('/user/profile/org.400'),
              success: () => {
                if (window.history.length <= 1) {
                  this.$router.push('/home')
                } else {
                  this.$router.back()
                }
              }
            })
            return
          }
          this.org_info = res.org_info
          if (!this.org_info.org_function) {
            this.org_info.org_function_main = null
            this.org_info.org_function_half = []
          } else if (this.org_info.org_function.length % 2 == 0) {
            this.org_info.org_function_main = null
            this.org_info.org_function_half = this.org_info.org_function
          } else {
            this.org_info.org_function_main = this.org_info.org_function[0]
            this.org_info.org_function_half = this.org_info.org_function.slice(1)
          }
          this.getPostByUser()
          const user_school_label = localStorage.getItem('user_school_label')
          if (user_school_label == "HKU") {
            this.title = this.$t('MiddleOrg.title', {org_name: res.org_info.org_name}) + " / " + this.$t('AppNameTitle.HKU')
          }
          if (user_school_label == "CUHK") {
            this.title = this.$t('MiddleOrg.title', {org_name: res.org_info.org_name}) + " / " + this.$t('AppNameTitle.CUHK')
          }
          if (user_school_label == "UST") {
            this.title = this.$t('MiddleOrg.title', {org_name: res.org_info.org_name}) + " / " + this.$t('AppNameTitle.UST')
          }
          document.title = this.title
        })
    },
    getPostByUser() {
      request("/post/list/user", {
        visit_user_serial: this.org_name,
        visit_user_school_label: this.org_info.user_school_label,
        page: this.page
      })
        .then((res) => {
          this.$loading.hide()
          if (this.finishRefresh) this.finishRefresh()
          if (!res) return
          this.is_loading_more = false
          this.is_last = res.is_last
          res.one_list.forEach((one_item) => {
            if(one_item.type == 'post'){
              one_item.data.post_topic = localizeTopic(one_item.data.post_topic)
            }
          })
          if (this.page == 0) {
            this.one_list = res.one_list
          } else {
            this.one_list = this.one_list.concat(res.one_list)
          }
        })
    },
    onRefresh(finishRefresh) {
      this.finishRefresh = finishRefresh
      this.page = 0
      this.is_loading_more = true
      this.getPostByUser()
    },
    loadMore() {
      if (!this.org_info.user_school_label) return;
      if (this.is_loading_more) return;
      if (this.is_last) return;
      this.is_loading_more = true
      this.page = this.page + 1
      this.getPostByUser()
    },
    handleScroll() {
      const scrollHeight = document.documentElement.scrollHeight
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const clientHeight = document.documentElement.clientHeight
      if (scrollTop + clientHeight >= scrollHeight - 1400) {
        this.loadMore()
      }
    },
    onTapBanner(banner) {
      switch (banner.banner_type) {
        case 'article':
          window.open(banner.article_link)
          break;
        case 'post':
          this.$router.push("/post/" + banner.uni_post_id)
          break;
        case 'inner':
          notice({
            content: this.$t('Banner.inner')
          })
          break;
        case 'miniapp':
          notice({
            content: this.$t('Banner.miniapp')
          })
          break;
        case 'none':
        default:
          break;
      }
    },
    pm(event) {
      this.$contextmenu({
        items: [
          {
            label: this.$t('MiddleOrg.realname_pm'),
            onClick: () => {
              this.createChat(true)
            }
          },
          {
            label: this.$t('MiddleOrg.anonymous_pm'),
            onClick: () => {
              this.createChat(false)
            }
          },
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
      return false
    },
    createChat(is_realname) {
      this.$loading.show()
      request("/pm/chat/create", {
        sender_is_real_name: is_realname,
        to_type: 'user',
        receiver_serial: this.org_info.org_name,
        receiver_school_label: this.org_info.user_school_label
      })
        .then((res) => {
          this.$loading.hide()
          if (!res) return
          this.$router.push('/chat/' + res.chat_id)
        })
    },
    handleFunction(event, function_item) {
      switch (function_item.function_type) {
        case 'list':
          this.$contextmenu({
            items: function_item.function_list.map(x => {
              return {
                label: x.function_title,
                onClick: () => {
                  this.handleFunction(event, x)
                }
              }
            }),
            event,
            customClass: "menu-theme",
            zIndex: 99,
            minWidth: 150
          });
          break;
        case 'article':
          window.open(function_item.article_link)
          break;
        case 'post':
          this.$router.push("/post/" + function_item.uni_post_id)
          break;
        case 'inner':
          break;
        case 'miniapp':
          this.$modal.show({
            content: this.$t('MiddleOrg.miniapp_not_support')
          })
          break;
        case 'pm':
          this.pm(event)
          break;
        case 'none':
        default:
          break;
      }
    }

  }
}
</script>

<style scoped>
.nav-bar-title-back {
  position: absolute;
  top: 0.3rem;
  width: 1rem;
  height: 1rem;
  padding: 1rem;
  border-radius: 50%;
  z-index: 100;
  background-color: rgb(255, 255, 255, 0.5);
}

.nav-bar-title-back:hover {
  background-color: rgb(255, 255, 255, 1);
}

@media (prefers-color-scheme: dark) {
  .nav-bar-title-back {
    background-color: rgb(0, 0, 0, 0.5);
  }

  .nav-bar-title-back:hover {
    background-color: rgb(0, 0, 0, 1);
  }
}

.org-outer {
  width: 100%;
}

.org-bg-container {
  /* width: 100%; */
  overflow: hidden;
  position: relative;
}

.org-bg-ratio {
  padding-top: 40%;
}

.org-bg {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center 10%;
}

.org-container {
  background-color: var(--bg);
  padding: 20px;
  border-bottom: 0.1rem solid var(--border-grey);
}

.org-name-and-avatar {
  display: flex;
  align-items: flex-end;
}

.org-name {
  font-size: 21px;
  line-height: 26px;
  font-weight: 600;
  max-width: 150px;
  display: -webkit-box;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--text);
}

.org-avatar-container {
  display: flex;
  position: absolute;
  right: 5%;
}

.org-avatar {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 100%;
  border: 5px solid var(--bg);
  background-color: var(--bg);
}

.org-intro-container {
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
}

.org-intro {
  font-size: 12px;
  line-height: 18px;
  color: var(--text-grey);
  font-weight: 400;
  display: -webkit-box;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.org-function-outer {
  display: flex;
  flex-direction: row;
  margin: 10px -5px 0 -5px;
}

.org-function-container {
  height: 50px;
  margin: 0 5px;
  background-color: var(--bg);
  border: 0.1rem solid var(--border-grey);
  border-radius: 10px;
  flex: 1;
  cursor: pointer;
}

.org-function-container:hover {
  background-color: var(--hover-grey);
}

.org-function-inner {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0 15px;
  display: flex;
  flex-direction: row;
}

.org-function-title {
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text);
}

.org-function-arrow {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 15px;
}

.banner-container {
  margin: 15px 0;
  border-radius: 16px;
  text-align: center;
}

.banner-image {
  width: 100%;
  height: auto;
  border-radius: 16px;
  cursor: pointer;
}
</style>