<template>
  <div style="width: 100%;">

    <div class="write-nav-bar">
      <div class="write-nav-bar-left">
        <BackSvg class="write-nav-bar-title-back" @click.native="navBack" />
        <div class="title">{{ $t('MiddleWrite.title') }}</div>
      </div>
      <div class="write-nav-bar-send-button" @click="post">{{ $t('MiddleWrite.send') }}</div>
    </div>

    <div id="vditor" ref="markdowneditor" v-show="post_is_markdown" />


    <div class="write-body" v-if="!post_is_markdown">

      <textarea class="write-textarea" :placeholder="$t('MiddleWrite.placeholder')" v-model="post_msg" ref="textarea"
        @input="adjustHeight" />

      <div class="write-image-container" v-if="post_image[0]">
        <img class="write-image" :src="post_image[0]" @click="onTapImage" />
      </div>

      <div class="function-container" v-if="post_media_show && post_media_show.media_type == 'netease'"
        @click="functionNetease">
        <img class="function-netease-image" :src="post_media_show.netease_image" />
        <div class="function-right">
          <div class="function-text-container">
            <div class="function-text-title">{{ post_media_show.netease_title }}</div>
            <div class="function-text-detail">{{ post_media_show.netease_artist }} -
              {{ post_media_show.netease_epname }}</div>
          </div>
          <img class="function-netease-icon" src="../assets/netease.svg" />
        </div>
      </div>

      <div class="function-container" v-if="post_media_show && post_media_show.media_type == 'bilibili'"
        @click="functionBilibili">
        <img class="function-bilibili-image" :src="post_media_show.bilibili_image" />
        <div class="function-right">
          <div class="function-text-container">
            <div class="function-text-title">{{ post_media_show.bilibili_title }}</div>
            <div class="function-text-detail">{{ post_media_show.bilibili_author }}</div>
          </div>
          <img class="function-bilibili-icon" src="../assets/bilibili.svg" />
        </div>
      </div>

      <div class="function-container" v-if="post_media_show && post_media_show.media_type == 'quote'"
        @click="functionQuote">
        <img class="function-quote-image" :src="logo_src" />
        <div class="function-right">
          <div class="function-text-container">
            <div class="function-text-title">{{ post_media_show.quote_title }}</div>
            <div class="function-text-detail">{{ post_media_show.quote_content }}</div>
          </div>
        </div>
      </div>

      <div class="function-container" v-if="post_media_show && post_media_show.media_type == 'file'"
        @click="functionFile">
        <img class="function-file-image" src="../assets/file-doc.png" v-if="post_media_show.file_type == 'doc'" />
        <img class="function-file-image" src="../assets/file-ppt.png" v-else-if="post_media_show.file_type == 'ppt'" />
        <img class="function-file-image" src="../assets/file-xls.png" v-else-if="post_media_show.file_type == 'xls'" />
        <img class="function-file-image" src="../assets/file-pdf.png" v-else-if="post_media_show.file_type == 'pdf'" />
        <img class="function-file-image" src="../assets/file-unknown.png" v-else />
        <div class="function-right">
          <div class="function-text-container">
            <div class="function-text-title">{{ post_media_show.file_name }}</div>
            <div class="function-text-detail">{{ post_media_show.file_size }}</div>
          </div>
        </div>
      </div>

      <div class="vote-container" v-if="post_media_show && post_media_show.media_type == 'vote'" @click="functionVote">
        <div class="vote-title">{{ post_media_show.vote_title }}</div>

        <div class="vote-subtitle" v-if="post_media_show.user_voted_option_title">
          {{ $t('MiddlePostDetail.vote_have_already_vote') }}{{ post_media_show.user_voted_option_title }}
        </div>
        <div class="vote-subtitle" v-else>{{ $t('MiddlePostDetail.vote_not_vote_yet') }}</div>

        <div class="vote-option-list" v-for="(option, index) in post_media_show.option_list" :key="index">

          <div class="vote-option-lower">
            <div :class="option.is_voted ? 'vote-option-lower-text-left-voted' : 'vote-option-lower-text-left'">
              {{ option.option_title }}</div>
            <div :class="option.is_voted ? 'vote-option-lower-text-right-voted' : 'vote-option-lower-text-right'">
              {{ option.option_num }}</div>
          </div>

          <div :class="option.is_voted ? 'vote-option-upper-voted' : 'vote-option-upper'"
            :style="'width:' + option.option_percentage * 100 + '%'">
            <div class="vote-option-upper-inner" :style="'width:' + 1 / option.option_percentage * 100 + '%'">
              <div class="vote-option-upper-text-left">{{ option.option_title }}</div>
              <div class="vote-option-upper-text-right">{{ option.option_num }}</div>
            </div>
          </div>

        </div>

      </div>

    </div>

    <div class="write-tab-bar">

      <div class="write-tab-bar-choose" style="margin-left: 10px;" @click="changeTopic">
        <img :src="topic_src" />
        <span v-if="post_topic">{{ post_topic_show }}</span>
        <span v-else>{{ $t('MiddleWrite.topic_null') }}</span>
      </div>

      <div class="write-tab-bar-choose" @click="changePublic">
        <img :src="public_src" />
        <span v-if="post_public == 2">{{ $t('MiddleWrite.public_false') }}</span>
        <span v-else>{{ $t('MiddleWrite.public_true') }}</span>
      </div>

      <div class="write-tab-bar-choose" @click="changeRealname">
        <img :src="realname_src" />
        <span v-if="user_is_real_name">{{ $t('MiddleWrite.realname_true') }}</span>
        <span v-else>{{ $t('MiddleWrite.realname_false') }}</span>
      </div>

      <div class="write-tab-bar-choose" @click="changeUni">
        <img :src="uni_src" />
        <span v-if="post_is_uni">{{ $t('MiddleWrite.uni_true') }}</span>
        <span v-else>{{ $t('MiddleWrite.uni_false') }}</span>
      </div>

      <img class="write-tab-bar-icon" :src="image_src" @click="uploadImage" />

      <img class="write-tab-bar-icon" :src="plus_src" style="margin-right: 10px;" @click="setMedia" />

    </div>

    <input type="file" ref="imageInput" accept="image/*" @change="getImage" style="display: none" />
    <input type="file" ref="fileInput" accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.xlsm,.pdf" @change="getFile"
      style="display: none" />


  </div>
</template>
    
<script>
import request from '../utils/request.js'
import notice from '../utils/notice.js'
import { subscribeWebpush } from '../utils/webpush.js'
import { upload, getExt } from '../utils/upload.js'
import { localizeTopic } from '../utils/localize.js'
import { formatTimeToDisplay } from '../utils/formatTime.js'

import Vditor from 'vditor'
import '../../node_modules/vditor/src/assets/less/index.less'

import BackSvg from './Icon/BackSvg.vue'

import checkEmojiFromText from '../utils/emoji.js'

export default {
  name: 'UniMiddleWrite',
  components: { BackSvg },
  created() {
    this.getTopicList()
    const user_school_label = localStorage.getItem('user_school_label')
    if (user_school_label == "HKU") {
      document.title = this.$t('MiddleWrite.title') + " / " + this.$t('AppNameTitle.HKU')
    }
    if (user_school_label == "CUHK") {
      document.title = this.$t('MiddleWrite.title') + " / " + this.$t('AppNameTitle.CUHK')
    }
    if (user_school_label == "UST") {
      document.title = this.$t('MiddleWrite.title') + " / " + this.$t('AppNameTitle.UST')
    }
    this.topic_src = require('../assets/write-topic-' + localStorage.getItem('user_school_label') + '.svg')
    this.public_src = require('../assets/write-public-' + localStorage.getItem('user_school_label') + '.svg')
    this.realname_src = require('../assets/write-realname-' + localStorage.getItem('user_school_label') + '.svg')
    this.uni_src = require('../assets/write-uni-' + localStorage.getItem('user_school_label') + '.svg')
    this.image_src = require('../assets/write-image-' + localStorage.getItem('user_school_label') + '.svg')
    this.plus_src = require('../assets/write-plus-' + localStorage.getItem('user_school_label') + '.svg')
    this.logo_src = require('../assets/logo-' + localStorage.getItem('user_school_label') + '.png')
  },
  mounted() {
    var navBar = document.querySelector('.write-nav-bar');
    if (!navBar) return
    document.querySelector('.write-tab-bar').style.width = navBar.offsetWidth + 'px'
    window.addEventListener('resize', this.listenResize)
    window.addEventListener('writeChangeTopic', this.listenChangeTopic)
    window.addEventListener('writeChangePublic', this.listenChangePublic)
    window.addEventListener('writeChangeRealname', this.listenChangeRealname)
    window.addEventListener('writeChangeUni', this.listenChangeUni)
    window.addEventListener('writeChangeMarkdown', this.listenChangeMarkdown)
    window.addEventListener('writeChangeMedia', this.listenChangeMedia)
    window.addEventListener('writePost', this.post)
    this.markdown_editor = new Vditor("vditor", {
      mode: 'wysiwyg',
      placeholder: this.$t('MiddleWrite.markdown_placeholder'),
      height: window.innerHeight - 60,
      tab: '\t',
      toolbar: ['headings', 'bold', 'italic', 'strike', 'link', '|', 'list', 'ordered-list', 'check', '|', 'line', 'quote', 'code', 'inline-code', '|', 'upload', 'table'],
      counter: {
        enable: true,
        type: 'text'
      },
      cache: {
        enable: false
      },
      preview: {
        delay: 50,
        markdown: {
          autoSpace: true
        }
      },
      upload: {
        multiple: false,
        accept: "image/*",
        handler: (files) => {
          this.$loading.show()
          upload(files[0], localStorage.getItem('user_school_label'), 'post')
            .then((image_link) => {
              this.$loading.hide()
              this.markdown_editor.insertValue(`![](${image_link})`)
            })
        }
      }

    })
  },
  destroyed() {
    window.removeEventListener('resize', this.listenResize)
    window.removeEventListener('writeChangeTopic', this.listenChangeTopic)
    window.removeEventListener('writeChangePublic', this.listenChangePublic)
    window.removeEventListener('writeChangeRealname', this.listenChangeRealname)
    window.removeEventListener('writeChangeUni', this.listenChangeUni)
    window.removeEventListener('writeChangeMarkdown', this.listenChangeMarkdown)
    window.removeEventListener('writeChangeMedia', this.listenChangeMedia)
    window.removeEventListener('writePost', this.post)
  },
  data() {
    return {
      post_msg: '',
      post_image: [],
      post_public: 1,
      user_is_real_name: false,
      post_topic: '',
      post_topic_show: '',
      post_is_uni: false,
      post_media: null,
      post_media_show: null,
      topic_list: [],
      markdown_editor: null,
      post_is_markdown: false,
    }
  },
  methods: {
    getTopicList() {
      request("/info/posttopic")
        .then((res) => {
          if (!res) return
          this.topic_list = res.topic_list.map(topic => {
            return { topic: topic, topic_show: localizeTopic(topic) };
          })
        })
    },
    post() {
      this.$loading.show()

      var user_is_real_name = 'false'
      if (this.user_is_real_name) {
        user_is_real_name = 'true'
      }

      var post_is_uni = 'false'
      if (this.post_is_uni) {
        post_is_uni = 'true'
      }

      if (this.post_is_markdown) {
        var post_msg = "[Triple Uni Markdown]\n" + this.markdown_editor.getValue()
        var post_media = null
        var post_image = "[]"
      } else {
        var post_msg = this.post_msg
        var post_media = this.post_media
        var post_image = JSON.stringify(this.post_image)
      }

      var data = {
        post_msg: post_msg,
        post_topic: this.post_topic,
        user_is_real_name: user_is_real_name,
        post_public: this.post_public,
        post_is_uni: post_is_uni,
        post_image: post_image,
      }

      if (post_media) Object.assign(data, { post_media: JSON.stringify(this.post_media) })

      request("/post/single/post", data, true, [402])
        .then((res) => {
          this.$loading.hide()
          if (!res) return
          if (res.code == 402) {
            this.$modal.show({
              title: this.$t('Modal.error'),
              content: this.$t('/post/single/post.402', { silence_until: formatTimeToDisplay(res.silence_until) }),
              show_cancel: false,
            })
            return
          }
          notice({
            content: this.$t('/post/single/post.200'),
          })
          this.$router.push({
            path: '/home',
            query: {
              method: 'refresh'
            }
          })
          checkEmojiFromText(post_msg)
          this.checkWebpush()
        })
    },
    adjustHeight() {
      const textarea = this.$refs.textarea;
      textarea.style.height = 'auto';
      const scrollHeight = textarea.scrollHeight - 30;
      const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight);
      var rows = Math.ceil(scrollHeight / lineHeight);
      if (rows < 10) rows = 10
      if (rows >= 20) {
        textarea.style.overflow = 'auto';
        textarea.style.height = `${lineHeight * 20}px`;
      } else {
        textarea.style.overflow = 'hidden';
        textarea.style.height = `${lineHeight * rows}px`;
      }
    },
    onTapImage(event) {
      this.$contextmenu({
        items: [
          {
            label: this.$t('MiddleWrite.preview_image'),
            onClick: () => {
              this.$hevueImgPreview({
                url: this.post_image[0],
                clickMaskCLose: true,
                controlBar: false
              })
            }
          },
          {
            label: this.$t('MiddleWrite.delete_image'),
            onClick: () => {
              this.post_image = []
            }
          },
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
    },
    navBack() {
      this.$modal.show({
        title: this.$t('MiddleWrite.nav_back_warning_title'),
        content: this.$t('MiddleWrite.nav_back_warning_content'),
        show_cancel: true,
        success: (res) => {
          if (!res) return
          if (window.history.length <= 1) {
            this.$router.push('/home')
          } else {
            this.$router.back()
          }
        }
      })
    },
    changeTopic(event) {
      this.$contextmenu({
        items: this.topic_list.map(x => {
          return {
            label: x.topic_show,
            onClick: () => {
              this.post_topic = x.topic
              this.post_topic_show = x.topic_show
            }
          }
        }),
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
    },
    changePublic(event) {
      this.$contextmenu({
        items: [
          {
            label: this.$t('MiddleWrite.public_true'),
            onClick: () => {
              this.post_public = 1
            }
          },
          {
            label: this.$t('MiddleWrite.public_false'),
            onClick: () => {
              this.post_public = 2
            }
          },
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
    },
    changeRealname(event) {
      var that = this
      this.$contextmenu({
        items: [
          {
            label: this.$t('MiddleWrite.realname_false'),
            onClick: () => {
              that.user_is_real_name = false
            }
          },
          {
            label: this.$t('MiddleWrite.realname_true'),
            onClick: () => {
              that.user_is_real_name = true
            }
          },
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
    },
    changeUni(event) {
      this.$contextmenu({
        items: [
          {
            label: this.$t('MiddleWrite.uni_false'),
            onClick: () => {
              this.post_is_uni = false
            }
          },
          {
            label: this.$t('MiddleWrite.uni_true'),
            onClick: () => {
              this.post_is_uni = true
            }
          },
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
    },
    uploadImage() {
      this.$refs.imageInput.click()
    },
    getImage(event) {
      this.$loading.show()
      upload(event.target.files[0], localStorage.getItem('user_school_label'), 'post')
        .then((image_link) => {
          this.post_image = [image_link]
          this.$loading.hide()
        })
    },
    getFile(event) {
      this.$loading.show()
      upload(event.target.files[0], localStorage.getItem('user_school_label'), 'file')
        .then((file_link) => {
          const file_name = event.target.files[0].name
          const file_size = Math.floor(event.target.files[0].size / 1024) + 'KB'
          const file_ext = getExt(file_link)
          var file_type = null
          if (file_ext == '.doc' || file_ext == '.docx') {
            file_type = 'doc'
          } else if (file_ext == '.ppt' || file_ext == '.pptx') {
            file_type = 'ppt'
          } else if (file_ext == '.xls' || file_ext == '.xlsx' || file_ext == '.xlsx') {
            file_type = 'xls'
          } else if (file_ext == '.pdf') {
            file_type = 'pdf'
          } else {
            this.$modal.show({
              content: this.$t('MiddleWrite.file_type_not_support'),
            })
            return
          }
          const post_media = {
            media_type: "file",
            file_link: file_link,
            file_name: file_name,
            file_type: file_type,
            file_size: file_size
          }
          this.post_media = post_media
          this.post_media_show = post_media
          this.$loading.hide()
        })
    },
    setMedia(event) {
      this.$contextmenu({
        items: [
          {
            label: this.$t('MiddleWrite.media_netease'),
            onClick: () => {
              this.$media.show({
                media_type: 'netease',
                success: (post_media) => {
                  this.post_media = post_media
                  this.post_media_show = post_media
                }
              })
            }
          },
          {
            label: this.$t('MiddleWrite.media_bilibili'),
            onClick: () => {
              this.$media.show({
                media_type: 'bilibili',
                success: (post_media) => {
                  this.post_media = post_media
                  this.post_media_show = post_media
                }
              })
            }
          },
          {
            label: this.$t('MiddleWrite.media_vote'),
            onClick: () => {
              this.$media.show({
                media_type: 'vote',
                success: (post_media, post_media_show) => {
                  this.post_media = post_media
                  this.post_media_show = post_media_show
                }
              })
            }
          },
          {
            label: this.$t('MiddleWrite.media_quote'),
            onClick: () => {
              this.$media.show({
                media_type: 'quote',
                success: (post_media, post_media_show) => {
                  this.post_media = post_media
                  this.post_media_show = post_media_show
                }
              })
            }
          },
          {
            label: this.$t('MiddleWrite.media_file'),
            onClick: () => {
              this.$refs.fileInput.click()
            }
          }
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
    },
    functionNetease(event) {
      this.$contextmenu({
        items: [
          {
            label: this.$t('MiddleWrite.preview_media'),
            onClick: () => {
              window.open('https://music.163.com/#/song?id=' + this.post_media_show.netease_id)
            }
          },
          {
            label: this.$t('MiddleWrite.delete_media'),
            onClick: () => {
              this.post_media = null
              this.post_media_show = null
            }
          },
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
    },
    functionBilibili(event) {
      this.$contextmenu({
        items: [
          {
            label: this.$t('MiddleWrite.preview_media'),
            onClick: () => {
              window.open('https://www.bilibili.com/video/' + this.post_media_show.bilibili_bv)
            }
          },
          {
            label: this.$t('MiddleWrite.delete_media'),
            onClick: () => {
              this.post_media = null
              this.post_media_show = null
            }
          },
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
    },
    functionMiniapp() {
      this.$modal.show({
        content: this.$t('MiddlePostDetail.function_miniapp_not_support')
      })
    },
    functionQuote(event) {
      this.$contextmenu({
        items: [
          {
            label: this.$t('MiddleWrite.preview_media'),
            onClick: () => {
              window.open('https://tripleuni.com/post/' + this.post_media_show.uni_post_id)
            }
          },
          {
            label: this.$t('MiddleWrite.delete_media'),
            onClick: () => {
              this.post_media = null
              this.post_media_show = null
            }
          },
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
    },
    functionFile(event) {
      this.$contextmenu({
        items: [
          {
            label: this.$t('MiddleWrite.preview_media'),
            onClick: () => {
              window.open(this.post_media_show.file_link)
            }
          },
          {
            label: this.$t('MiddleWrite.delete_media'),
            onClick: () => {
              this.post_media = null
              this.post_media_show = null
            }
          },
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
    },
    functionVote(event) {
      this.$contextmenu({
        items: [
          {
            label: this.$t('MiddleWrite.delete_media'),
            onClick: () => {
              this.post_media = null
              this.post_media_show = null
            }
          },
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
    },
    checkWebpush() {
      if (!("Notification" in window)) {
        return
      } else if (Notification.permission === "granted") {
        return
      } else if (Notification.permission == "denied") {
        return
      } else {
        this.$modal.show({
          title: this.$t('Webpush.post_title'),
          content: this.$t('Webpush.post_content'),
          show_cancel: true,
          success: (res) => {
            if (!res) return
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                subscribeWebpush(true)
              } else {
                this.$modal.show({
                  title: this.$t('Webpush.deny_title'),
                  content: this.$t('Webpush.deny_content'),
                  show_cancel: false,
                })
              }
            })
          }
        })
      }
    },
    listenResize() {
      var navBar = document.querySelector('.write-nav-bar');
      if (!navBar) return
      document.querySelector('.write-tab-bar').style.width = navBar.offsetWidth + 'px'
    },
    listenChangeTopic(e) {
      this.post_topic = e.detail.post_topic
      this.post_topic_show = e.detail.post_topic_show
    },
    listenChangePublic(e) {
      this.post_public = e.detail.post_public
    },
    listenChangeRealname(e) {
      this.user_is_real_name = e.detail.user_is_real_name
    },
    listenChangeUni(e) {
      this.post_is_uni = e.detail.post_is_uni
    },
    listenChangeMarkdown(e) {
      this.post_is_markdown = e.detail.post_is_markdown
    },
    listenChangeMedia(e) {
      if (e.detail.media_type == "image") {
        this.uploadImage()
      } else if (e.detail.media_type == "netease") {
        this.$media.show({
          media_type: 'netease',
          success: (post_media) => {
            this.post_media = post_media
            this.post_media_show = post_media
          }
        })
      } else if (e.detail.media_type == "bilibili") {
        this.$media.show({
          media_type: 'bilibili',
          success: (post_media) => {
            this.post_media = post_media
            this.post_media_show = post_media
          }
        })
      } else if (e.detail.media_type == "vote") {
        this.$media.show({
          media_type: 'vote',
          success: (post_media, post_media_show) => {
            this.post_media = post_media
            this.post_media_show = post_media_show
          }
        })
      } else if (e.detail.media_type == "quote") {
        this.$media.show({
          media_type: 'quote',
          success: (post_media, post_media_show) => {
            this.post_media = post_media
            this.post_media_show = post_media_show
          }
        })
      } else if (e.detail.media_type == "file") {
        this.$refs.fileInput.click()
      }
    }
  }
}
</script>

<style scoped>
.write-nav-bar {
  font-size: 1.3rem;
  line-height: 1.5rem;
  font-weight: 600;
  margin: 0;
  display: flex;
  background-color: var(--bg);
  position: sticky;
  top: 0;
  z-index: 99;
  border-bottom: 0.1rem solid var(--border-grey);
  justify-content: space-between;
  align-items: center;
}

.write-nav-bar-left {
  display: flex;
}

.write-nav-bar-title-back {
  margin-top: 0.3rem;
  width: 1rem;
  height: 1rem;
  padding: 1rem;
  border-radius: 50%;
}

.write-nav-bar-title-back:hover {
  background-color: var(--border-grey);
}

.write-nav-bar-send-button {
  display: none;
}

.title {
  padding-left: 0rem;
  margin: 1rem 0;
  color: var(--text);
}

.write-tab-bar {
  display: none;
}

.write-tab-bar-choose {
  display: flex;
  font-size: 12px;
  line-height: 20px;
  height: calc(26px - 0.2rem);
  margin: 7px 0;
  padding: 0 7px;
  border: 0.1rem solid var(--main);
  border-radius: calc(26px - 0.2rem);
  align-items: center;
  color: var(--main);
  font-weight: 600;
  cursor: pointer;
}

.write-tab-bar-choose img {
  width: 15px;
  height: 15px;
  margin-right: 3px;
}

.write-tab-bar-icon {
  width: 26px;
  height: 26px;
  margin: 7px 0;
  cursor: pointer;
}

.write-body {
  padding: 10px 20px;
  margin-bottom: 40px;
  margin-bottom: calc(40px + env(safe-area-inset-bottom));
}

.write-textarea {
  margin: 10px 0;
  padding: 0;
  resize: none;
  width: 100%;
  line-height: 20px;
  height: 200px;
  border: none;
  font-size: 15px;
  font-family: "Chirp", sans-serif;
  color: var(--text);
  background-color: var(--bg);
}

.write-textarea:focus {
  outline: none;
}

.write-image-container {
  margin-top: 10px;
  display: block;
  justify-content: center;
  align-items: center;
}

.write-image {
  max-width: min(350px, 100%);
  max-height: 400px;
  height: auto;
  border-radius: 16px;
  object-fit: contain;
  cursor: pointer;
}

.function-container {
  margin-top: 10px;
  text-align: center;
  background-color: var(--bg);
  border-radius: 16px;
  display: flex;
  height: 75px;
  width: calc(100% - 0.2rem);
  max-width: 350px;
  overflow: hidden;
  border: 0.1rem solid var(--border-grey);
  cursor: pointer;
}

.function-text-container {
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}

.function-right {
  flex: 1;
  height: 75px;
  position: relative;
}

.function-text-title {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 230px;
  color: var(--text);
}

.function-text-detail {
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
  color: var(--text);
}

.function-netease-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.function-miniapp-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.function-bilibili-icon {
  width: 40px;
  height: 20px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.function-netease-image {
  height: 75px;
  width: 75px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.function-bilibili-image {
  height: 75px;
  width: 115px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.function-miniapp-image {
  height: 75px;
  width: 75px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.function-quote-image {
  height: 75px;
  width: 75px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.function-file-image {
  height: 75px;
  width: 75px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.vote-container {
  margin-top: 10px;
  text-align: center;
  background-color: var(--bg);
  border-radius: 16px;
  width: calc(100% - 0.2rem);
  max-width: 350px;
  overflow: hidden;
  border: 0.1rem solid var(--border-grey);
  cursor: pointer;
}

.vote-title {
  margin: 10px 15px 0 15px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: var(--text);
}

.vote-subtitle {
  margin: 10px 15px 0 15px;
  text-align: left;
  font-size: 13px;
  color: var(--text);
}

.vote-option-list {
  background-color: var(--bg);
  height: 35px;
  margin: 10px 10px 5px 10px;
  border-radius: 16px;
  position: relative;
  border: 0.1rem solid var(--border-grey);
}

.vote-option-lower {
  height: 100%;
  width: 100%;
  background-color: var(--bg);
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
}

.vote-option-upper {
  height: 100%;
  width: 0;
  background-color: var(--vote-grey);
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.vote-option-upper-voted {
  height: 100%;
  width: 0;
  background-color: var(--main);
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.vote-option-upper-inner {
  width: 100%;
  height: 100%;
  position: relative;
}

.vote-option-lower-text-left {
  color: var(--vote-grey);
  font-size: 15px;
  position: absolute;
  text-align: left;
  left: 15px;
  right: 50px;
  top: 7.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.vote-option-lower-text-right {
  color: var(--vote-grey);
  font-size: 15px;
  position: absolute;
  right: 15px;
  top: 7.5px;
  white-space: nowrap;
}

.vote-option-lower-text-left-voted {
  color: var(--main);
  font-size: 15px;
  position: absolute;
  text-align: left;
  left: 15px;
  right: 50px;
  top: 7.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.vote-option-lower-text-right-voted {
  color: var(--main);
  font-size: 15px;
  position: absolute;
  right: 15px;
  top: 7.5px;
  white-space: nowrap;
}

.vote-option-upper-text-left {
  color: var(--bg);
  font-size: 15px;
  position: absolute;
  left: 15px;
  top: 7.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.vote-option-upper-text-right {
  color: var(--bg);
  font-size: 15px;
  position: absolute;
  right: 15px;
  top: 7.5px;
  white-space: nowrap;
}

@media only screen and (max-width: 1000px) {
  .write-nav-bar-send-button {
    display: block;
    font-size: 12px;
    padding: 3px 10px;
    margin: 0 1rem;
    border-radius: 20px;
    color: #FFFFFF;
    background-color: var(--main);
    cursor: pointer;
  }

  .write-nav-bar-send-button:hover {
    background-color: var(--main-darker);
  }

  .write-tab-bar {
    position: fixed;
    bottom: 0;
    z-index: 99;
    width: 100%;
    height: 40px;
    display: flex;
    background-color: var(--bg);
    border-top: 0.1rem solid var(--border-grey);
    padding-bottom: env(safe-area-inset-bottom);
    justify-content: space-between;
  }
}
</style>