import { render, staticRenderFns } from "./RightChat.vue?vue&type=template&id=cdee8b28&scoped=true&"
import script from "./RightChat.vue?vue&type=script&lang=js&"
export * from "./RightChat.vue?vue&type=script&lang=js&"
import style0 from "./RightChat.vue?vue&type=style&index=0&id=cdee8b28&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdee8b28",
  null
  
)

export default component.exports