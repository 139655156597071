import Vue from "vue";
import componentMedia from "./Media.vue";

const comMedia = Vue.extend(componentMedia);

const instance = new comMedia({
    el: document.createElement("div"),
});

import i18n from "../../locales";

instance.show = false;
const media = {
    show({ media_type, success = null }) {
        instance.show = true
        instance.media_type = media_type
        instance.success = success
        if(media_type == 'netease'){
            instance.title_txt = i18n.t('Media.netease_title')
            instance.info_txt = i18n.t('Media.netease_info')
            instance.placeholder_txt = i18n.t('Media.netease_placeholder')
            instance.button_txt = i18n.t('Media.netease_button')
        }else if(media_type == 'bilibili'){
            instance.title_txt = i18n.t('Media.bilibili_title')
            instance.info_txt = i18n.t('Media.bilibili_info')
            instance.placeholder_txt = i18n.t('Media.bilibili_placeholder')
            instance.button_txt = i18n.t('Media.bilibili_button')
        }else if(media_type == 'quote'){
            instance.title_txt = i18n.t('Media.quote_title')
            instance.info_txt = i18n.t('Media.quote_info')
            instance.button_txt = i18n.t('Media.quote_button')
        }else if(media_type == 'vote'){
            instance.title_txt = i18n.t('Media.vote_title')
            instance.info_txt = ''
            instance.button_txt = i18n.t('Media.vote_button')
            instance.switch_color= {
                checked: window.getComputedStyle(document.documentElement).getPropertyValue('--main'),
                unchecked: window.getComputedStyle(document.documentElement).getPropertyValue('--hover-grey')
            }
            instance.plus_src = require('../../assets/write-plus-' + localStorage.getItem('user_school_label') + '.svg')
        }
        document.body.appendChild(instance.$el);
    },
    hide() {
        instance.show = false;
        instance.uni_post_id = null
        instance.comment_father_id = null
    },
};
export default {
    install() {
        if (!Vue.$media) {
            Vue.$media= media;
        }
        Vue.mixin({
            created() {
                this.$media = Vue.$media;
            },
        });
    },
};
