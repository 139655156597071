<template>
  <div class="body">
    <div class="ban-container" v-if="status == 'not_permanent'">
          <div class="ban-inner">
            <div class="title_container">
              <h2>{{ $t('Ban.not_permanent_1') }}</h2>
            </div>
              <p>{{ $t('Ban.not_permanent_2', {app_name: app_name}) }}</p>
              <h3>{{ $t('Ban.not_permanent_3') }}</h3>
              <p>{{ $t('Ban.not_permanent_4') }}<span style="font-weight: bold; text-decoration: underline; cursor: pointer;" @click="nav2Agreement">{{ $t('Ban.not_permanent_5', {app_name: app_name}) }}</span>{{ $t('Ban.not_permanent_6') }}<span style="font-weight: bold;">{{ ban_detail.ban_period_now }}</span>{{ $t('Ban.not_permanent_7') }}</p>
              <h3>{{ ban_detail.user_ban_until }}</h3>
              <p>{{ $t('Ban.not_permanent_8') }}<span style="font-weight: bold;">{{ ban_detail.user_ban_times }}</span>{{ $t('Ban.not_permanent_9') }}<span style="font-weight: bold;">{{ ban_detail.ban_period_next }}</span>{{ $t('Ban.not_permanent_10') }}</p>
              <p>{{ $t('Ban.not_permanent_11') }}<span style="font-weight: bold; text-decoration: underline; cursor: pointer;" @click="nav2Agreement">{{ $t('Ban.not_permanent_5', {app_name: app_name}) }}</span>{{ $t('Ban.not_permanent_12') }}</p>
          </div>
      </div>

      <div class="ban-container" v-if="status == 'permanent'">
          <div class="ban-inner">
            <div class="title_container">
              <h2>{{ $t('Ban.permanent_1') }}</h2>
            </div>
              <p>{{ $t('Ban.permanent_2', {app_name: app_name}) }}<span style="font-weight: bold; text-decoration: underline; cursor: pointer;" @click="nav2Agreement">{{ $t('Ban.permanent_3', {app_name: app_name}) }}</span>{{ $t('Ban.permanent_4') }}</p>
              <h3>{{ $t('Ban.permanent_5') }}</h3>
          </div>
      </div>

  </div>
</template>

<script>
import request from '../utils/request.js'

export default {
  name: 'UniBan',
  created() {
    this.getBanDetail()
    const user_school_label = localStorage.getItem('user_school_label')
    if (user_school_label == "HKU") {
      this.app_name = this.$t('AppName.HKU');
      document.title = this.$t('Ban.title') + " / " + this.$t('AppNameTitle.HKU')
    }
    if (user_school_label == "CUHK") {
      this.app_name = this.$t('AppName.CUHK');
      document.title = this.$t('Ban.title') + " / " + this.$t('AppNameTitle.CUHK')
    }
    if (user_school_label == "UST") {
      this.app_name = this.$t('AppName.UST');
      document.title = this.$t('Ban.title') + " / " + this.$t('AppNameTitle.UST')
    }
  },
  data() {
      return {
          status: 'login',
          user_school_label: null,
          app_name: null,
          ban_detail: {}
      }
  },
  methods: {
      getBanDetail() {
          this.$loading.show()
          request("/user/ban/web")
              .then((res) => {
                  this.$loading.hide()
                  if (!res) return
                  if (res.code == 200) {
                    this.ban_detail = res.ban_detail
                    this.status = 'not_permanent'
                  } else if (res.code == 201) {
                    this.status = 'permanent'
                  } else {
                    this.$router.push('/home')
                  }
              })
      },
      nav2Agreement() {
        const user_school_label = localStorage.getItem('user_school_label')
        window.open('https://terms.tripleuni.com/' + user_school_label.toLocaleLowerCase() + '/agreement')
      }
  }
}
</script>


<style scoped>
.body {
  background-color: var(--bg-grey);
  color: var(--text);
  margin: 0;
  font-family: "Chirp", sans-serif;
  overflow-x: hidden;
  display: flex;
  height: 100vh;
  height: calc(100vh - env(safe-area-inset-bottom));
  align-items: center;
}

.ban-container {
  display: block;
  padding: 1rem;
  background-color: var(--bg);
  border: 0.1rem solid var(--border-grey);
  width: 80%;
  max-width: 30rem;
  margin: 1rem auto;
  border-radius: 1rem;
}

.title_container{
  width: 100%;
  border-bottom: 0.1rem solid var(--border-grey);
}

.ban-container p {
  color: var(--text);
  font-size: 1rem;
  line-height: 1.5rem;
}

@media only screen and (max-width: 700px) {
  .ban-container {
      width: calc(100vw - 3rem);
      height: calc(100vh - 3rem);
      height: calc(100vh - 3rem - env(safe-area-inset-bottom));
      margin: 0;
      border: none;
      padding: 1.5rem;
      border-radius: 0;
      max-width: none;
      align-items: center;
      display: flex;
  }

  .ban-inner {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 450px;
      padding-bottom: 100px;
  }
}
</style>