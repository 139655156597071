import Vue from "vue";
import componentLoading from "./Loading.vue";

const comLoading = Vue.extend(componentLoading);

const instance = new comLoading({
    el: document.createElement("div"),
});

instance.show = false

import { finish_loading } from '../../utils/splash.js'
instance.finish_loading = finish_loading
window.addEventListener('finishInitLoading', () => {
    instance.finish_loading = true
})

const loading = {
    show() {
        instance.show = true;
        const user_school_label = localStorage.getItem('user_school_label');
        if(user_school_label){
            instance.src = require('../../assets/loading-' + localStorage.getItem('user_school_label') + '.svg');
        }else{
            instance.src = require('../../assets/loading.svg');
        }
        document.body.appendChild(instance.$el);
    },
    hide() {
        instance.show = false;
        let uni_loading = document.getElementById('uni-loading')
        setTimeout(() => {
            try {
                uni_loading.parentNode.removeChild(uni_loading)
            } catch (err) {}
        }, 100)
    },
};
export default {
    install() {
        if (!Vue.$loading) {
            Vue.$loading = loading;
        }
        Vue.mixin({
            created() {
                this.$loading = Vue.$loading;
            },
        });
    },
};
