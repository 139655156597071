import request from './request.js'

export const checkUnreadNotice = () => {
    request("/notice/checkunread")
        .then((res) => {
            if (!res) return
            localStorage.setItem('unread_notice', res.notice_count)
            let event = new Event("updateUnreadNotice")
            window.dispatchEvent(event)
        })
}