<template>
  <div style="width: 100%;">
    <div class="nav-bar-title sticky">
      <div class="input-outer">
        <label :class="is_focus ? 'input-container input-container-focus' : 'input-container'">
          <div style="display: flex; width: 100%;">
            <SearchSvg class="input-icon-search" />
            <ClearSvg class="input-icon-clear" @click.native="clear" v-show="is_focus && key_word" />
            <input class="input" :placeholder="placeholder" v-model="key_word" @keyup.enter="search" @focus="focus"
              @input="getSearchSuggestion" @blur="blur" ref="input" id="searchInput" />
          </div>
          <div v-show="is_focus">
            <div @click="selectSuggestion(index)"
              :class="index == search_suggestion_list.length - 1 ? (index == suggestion_index ? 'search-suggestion-item search-suggestion-item-last search-suggestion-item-selected ' : 'search-suggestion-item  search-suggestion-item-last') : (index == suggestion_index ? 'search-suggestion-item search-suggestion-item-selected' : 'search-suggestion-item')"
              v-for="(item, index) in search_suggestion_list" :key="index">{{ item.before }}<span
                class="search-suggestion-item-bold">{{ item.matched }}</span>{{ item.after }}</div>
          </div>
        </label>
      </div>
      <div class="topic-tab">
        <button :class="(search_mode_index == index) ? 'topic-button active' : 'topic-button'"
          @click="switchSearchMode(index)" v-for="(search_mode, index) in search_mode_list" :key="index">{{
          search_mode.text }}</button>
      </div>
    </div>
    <Refresh :on-refresh="onRefresh">

      <div v-if="aisearch_id" class="ai-outer" :style="!ai_is_expand ? 'max-height: 200px;' : ''">
        <div class="ai-mask" v-if="!ai_is_expand">
          <div class="ai-mask-prompt" @click="aiExpand">{{ $t('MiddleSearch.ai_expand') }}</div>
        </div>
        <div v-if="!ai_answer_json.title" class="ai-title" style="padding-bottom: 20px;">{{ $t('MiddleSearch.ai_loading') }}</div>
        <div v-if="ai_answer_json.title" class="ai-title">{{ ai_answer_json.title }}</div>
        <template v-if="ai_answer_json.answer">
          <div v-if="ai_answer_json.answer && ai_answer_json.answer.emojiBullets"
            v-for="answer in ai_answer_json.answer.emojiBullets">
            <div v-if="answer" class="ai-answer-block">
              <div class="ai-answer-emoji">{{ Array.from(answer)[0] }}</div>
              <div class="ai-answer-msg">{{ Array.from(answer).slice(2).join('') }}</div>
            </div>
          </div>
          <One v-if="ai_answer_json.answer.one_item" class="ai-post" :type="ai_answer_json.answer.one_item.type" :data="ai_answer_json.answer.one_item.data"
            :show_border="false" />
        </template>
        <div v-if="ai_answer_json.sections" v-for="(section, title) in ai_answer_json.sections">
          <template v-if="section">
            <div v-if="title" class="ai-title">{{ title }}</div>
            <div v-if="section.emojiBullets" v-for="answer in section.emojiBullets">
              <div v-if="answer" class="ai-answer-block">
                <div class="ai-answer-emoji">{{ Array.from(answer)[0] }}</div>
                <div class="ai-answer-msg">{{ Array.from(answer).slice(2).join('') }}</div>
              </div>
            </div>
            <One v-if="section.one_item" class="ai-post" :type="section.one_item.type" :data="section.one_item.data"
              :show_border="false" />
          </template>
        </div>
      </div>


      <One :type="item.type" :data="item.data" v-for="(item, index) in one_list" :key="index" />
      <LoadMore :is_last="is_last" v-show="is_loading_more || is_last" />
    </Refresh>
  </div>
</template>

<script>
import request from '../utils/request.js'
import { localizeTopic, localizeString } from '../utils/localize'
import { parse } from 'best-effort-json-parser'

import One from './One.vue'
import LoadMore from './LoadMore.vue'

import SearchSvg from './Icon/SearchSvg.vue'
import ClearSvg from './Icon/ClearSvg.vue'

export default {
  name: 'UniMiddleSearchNew',
  components: { One, LoadMore, SearchSvg, ClearSvg },
  created() {
    this.key_word = this.$route.params.key_word
    this.$loading.show()
    this.getPostPre()
    this.getPlaceholder()
    const user_school_label = localStorage.getItem('user_school_label')
    if (this.key_word) {
      if (user_school_label == "HKU") {
        this.title = this.key_word + " - " + this.$t('MiddleSearch.title') + " / " + this.$t('AppNameTitle.HKU')
      }
      if (user_school_label == "CUHK") {
        this.title = this.key_word + " - " + this.$t('MiddleSearch.title') + " / " + this.$t('AppNameTitle.CUHK')
      }
      if (user_school_label == "UST") {
        this.title = this.key_word + " - " + this.$t('MiddleSearch.title') + " / " + this.$t('AppNameTitle.UST')
      }
    } else {
      if (user_school_label == "HKU") {
        this.title = this.$t('MiddleSearch.title') + " / " + this.$t('AppNameTitle.HKU')
      }
      if (user_school_label == "CUHK") {
        this.title = this.$t('MiddleSearch.title') + " / " + this.$t('AppNameTitle.CUHK')
      }
      if (user_school_label == "UST") {
        this.title = this.$t('MiddleSearch.title') + " / " + this.$t('AppNameTitle.UST')
      }
    }
  },
  activated() {
    window.addEventListener('scroll', this.handleScroll)
    document.addEventListener('keydown', this.wathcKeyboard)
    if (this.$route.query.method == 'refresh') {
      this.key_word = this.$route.params.key_word
      window.scrollTo(0, 0);
      this.page = 0
      this.is_loading_more = true
      this.getPostPre()
      this.$router.replace({
        path: this.$route.path,
        params: this.$route.params
      })
    }
    if (!this.title) {
      const user_school_label = localStorage.getItem('user_school_label')
      if (user_school_label == "HKU") {
        document.title = this.$t('MiddleSearch.title') + " / " + this.$t('AppNameTitle.HKU')
      }
      if (user_school_label == "CUHK") {
        document.title = this.$t('MiddleSearch.title') + " / " + this.$t('AppNameTitle.CUHK')
      }
      if (user_school_label == "UST") {
        document.title = this.$t('MiddleSearch.title') + " / " + this.$t('AppNameTitle.UST')
      }
    } else {
      document.title = this.title
    }
  },
  deactivated() {
    window.removeEventListener('scroll', this.handleScroll)
    document.removeEventListener('keydown', this.wathcKeyboard)
  },
  data() {
    return {
      search_mode_index: 0,
      page: 0,
      key_word: '',
      last_key_word: '',
      placeholder: this.$t('MiddleSearch.placeholder'),
      search_mode_list: [
        { "mode": "default", "text": this.$t('MiddleSearch.default') },
        { "mode": "latest", "text": this.$t('MiddleSearch.latest') },
        { "mode": "hot", "text": this.$t('MiddleSearch.hot') }
      ],
      one_list: [],
      is_last: false,
      is_loading_more: false,
      finishRefresh: null,
      is_focus: false,
      title: '',
      search_suggestion_list: [],
      suggestion_index: -1,
      suggestion_keyword: '',
      orginal_key_word: '',
      aisearch_id: null,
      ai_is_getting_answer: false,
      ai_answer: '',
      ai_answer_json: {},
      ai_is_expand: false
    }
  },
  methods: {
    clear() {
      this.key_word = ''
      window.scrollTo(0, 0);
    },
    focus() {
      this.is_focus = true
      this.suggestion_index = -1
      this.getSearchSuggestion()
    },
    blur() {
      setTimeout(() => {
        this.is_focus = false
        this.suggestion_index = -1
      }, 100);
    },
    wathcKeyboard(e) {
      if (!this.is_focus) return
      let ev = document.all ? window.event : e
      if (ev.keyCode === 40) {
        if (this.suggestion_index >= this.search_suggestion_list.length - 1) return
        this.suggestion_index += 1
        if (this.suggestion_index == -1) {
          this.key_word = ''
        } else {
          this.key_word = this.search_suggestion_list[this.suggestion_index].orginal
        }
        setTimeout(() => {
          var input = document.getElementById('searchInput')
          input.focus()
          input.setSelectionRange(input.value.length, input.value.length)
        }, 10);
      } else if (ev.keyCode === 38) {
        if (this.suggestion_index <= -1) return
        this.suggestion_index -= 1
        if (this.suggestion_index == -1) {
          this.key_word = this.orginal_key_word
        } else {
          this.key_word = this.search_suggestion_list[this.suggestion_index].orginal
        }
        setTimeout(() => {
          var input = document.getElementById('searchInput')
          input.focus()
          input.setSelectionRange(input.value.length, input.value.length)
        }, 10);
      }
    },
    selectSuggestion(index) {
      this.key_word = this.search_suggestion_list[index].orginal
      this.search()
    },
    switchSearchMode(index) {
      window.scrollTo(0, 0);
      this.page = 0
      this.search_mode_index = index
      this.$loading.show()
      this.getPostPre()
    },
    aiExpand () {
      this.ai_is_expand = true
    },
    getAiSearchResult() {
      if (this.ai_is_getting_answer) return
      this.ai_is_getting_answer = true
      this.ai_answer = ''
      this.ai_answer_json = {}
      const url = 'https://chat.uuunnniii.com/searchpost.php?id=' + this.aisearch_id + '&token=' + localStorage.getItem('token')
      const eventSource = new EventSource(url)

      eventSource.addEventListener("open", (event) => {
        // console.log("连接已建立", JSON.stringify(event));
      })

      eventSource.addEventListener("message", (event) => {
        // console.log("接收数据：", event);
        try {
          var result = JSON.parse(event.data);
          if (result.content) {
            this.ai_answer += result.content
            let ai_answer_json = parse(this.ai_answer)
            if (ai_answer_json.answer && ai_answer_json.answer.citedPostID) {
              this.one_list.forEach((one_item) => {
                if (one_item.type == 'post' && one_item.data.post_id == ai_answer_json.answer.citedPostID) {
                  ai_answer_json.answer.one_item = one_item
                }
              })
            }
            if (ai_answer_json.sections) {
              Reflect.ownKeys(ai_answer_json.sections).forEach((section_key) => {
                let section = ai_answer_json.sections[section_key]
                if (section.citedPostID) {
                  this.one_list.forEach((one_item) => {
                    if (one_item.type == 'post' && one_item.data.post_id == section.citedPostID) {
                      section.one_item = one_item
                    }
                  })
                }
              })
            }
            this.ai_answer_json = ai_answer_json
          }
        } catch (error) {
          console.log(error);
        }
      })

      eventSource.addEventListener("error", (event) => {
        // console.error("发生错误：", JSON.stringify(event));
      })

      eventSource.addEventListener("close", (event) => {
        eventSource.close();
        this.ai_is_getting_answer = false
      })
    },
    search() {
      this.$loading.show()
      this.$router.push('/search/' + this.key_word)
      this.$refs.input.blur()
      this.suggestion_index = -1
      this.orginal_key_word = ''
      this.getPostPre()
      this.getPlaceholder()
    },
    getSearchSuggestion() {
      this.is_focus = true
      this.orginal_key_word = this.key_word
      this.suggestion_index = -1
      const keyword = this.key_word
      request("/info/searchsuggestion", {
        key_word: keyword
      })
        .then((res) => {
          if (!res) return
          if (keyword != this.key_word) return
          this.search_suggestion_list = []
          res.search_suggestion_list.forEach((suggestion) => {
            var parts = suggestion.split(new RegExp(this.key_word, "i"))
            this.search_suggestion_list.push({
              orginal: suggestion,
              before: parts[0],
              matched: this.key_word,
              after: parts[1]
            })
          })
        })
    },
    getPostPre() {
      if (this.last_key_word != this.key_word) {
        this.last_key_word = this.key_word
        window.scrollTo(0, 0);
        this.page = 0
      }
      if (!this.key_word) {
        this.getPostByTopic()
      } else {
        this.getPostBySearch()
      }
    },
    getPostBySearch() {
      request("/post/list/search", {
        key_word: this.key_word,
        search_mode: this.search_mode_list[this.search_mode_index].mode,
        page: this.page
      })
        .then((res) => {
          this.$loading.hide()
          if (this.finishRefresh) this.finishRefresh()
          if (!res) return
          this.is_loading_more = false
          this.is_last = res.is_last
          res.one_list.forEach((one_item) => {
            if (one_item.type == 'post') {
              one_item.data.post_topic = localizeTopic(one_item.data.post_topic)
            }
          })
          if (this.page == 0) {
            this.one_list = res.one_list
            this.ai_is_expand = false
          } else {
            this.one_list = this.one_list.concat(res.one_list)
          }
          if (res.ai_search_result) {
            let ai_answer_json = res.ai_search_result
            if (ai_answer_json.answer && ai_answer_json.answer.citedPostID) {
              this.one_list.forEach((one_item) => {
                if (one_item.type == 'post' && one_item.data.post_id == ai_answer_json.answer.citedPostID) {
                  ai_answer_json.answer.one_item = one_item
                }
              })
            }
            if (ai_answer_json.sections) {
              Reflect.ownKeys(ai_answer_json.sections).forEach((section_key) => {
                let section = ai_answer_json.sections[section_key]
                if (section.citedPostID) {
                  this.one_list.forEach((one_item) => {
                    if (one_item.type == 'post' && one_item.data.post_id == section.citedPostID) {
                      section.one_item = one_item
                    }
                  })
                }
              })
            }
            this.ai_answer_json = ai_answer_json
            this.aisearch_id = 1
          } else if (res.ai_search_id) {
            this.aisearch_id = res.ai_search_id
            this.getAiSearchResult()
          } else {
            if (this.page == 0) this.aisearch_id = null
          }
        })
    },
    getPostByTopic() {
      const user_school_label = localStorage.getItem('user_school_label')
      var post_topic = ''
      if (user_school_label == 'HKU') post_topic = 'ONE'
      if (user_school_label == 'CUHK') post_topic = '猫抓板'
      if (user_school_label == 'UST') post_topic = '小火鸟'
      request("/post/list/topic", {
        post_topic: post_topic,
        page: this.page
      })
        .then((res) => {
          this.$loading.hide()
          if (this.finishRefresh) this.finishRefresh()
          if (!res) return
          this.is_loading_more = false
          this.is_last = res.is_last
          res.one_list.forEach((one_item) => {
            if (one_item.type == 'post') {
              one_item.data.post_topic = localizeTopic(one_item.data.post_topic)
            }
          })
          if (this.page == 0) {
            this.one_list = res.one_list
          } else {
            this.one_list = this.one_list.concat(res.one_list)
          }
        })
    },
    getPlaceholder() {
      request("/info/placeholder")
        .then((res) => {
          if (!res) return
          localizeString(res.placeholder).then((result) => {
            this.placeholder = result
          })
        })
    },
    onRefresh(finishRefresh) {
      this.finishRefresh = finishRefresh
      this.page = 0
      this.is_loading_more = true
      this.getPostPre()
    },
    loadMore() {
      if (this.is_loading_more) return;
      if (this.is_last) return;
      this.is_loading_more = true
      this.page = this.page + 1
      this.getPostPre()
    },
    handleScroll() {
      const scrollHeight = document.documentElement.scrollHeight
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const clientHeight = document.documentElement.clientHeight
      if (scrollTop + clientHeight >= scrollHeight - 1400) {
        this.loadMore()
      }
    },

  }
}
</script>

<style scoped>
.nav-bar-title {
  font-size: 1.3rem;
  line-height: 1.5rem;
  font-weight: 600;
  padding-top: 0.1rem;
  background-color: var(--bg);
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 99;
  border-bottom: 0.1rem solid var(--border-grey);
}

.input-outer {
  height: 50px;
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--bg);
  margin: 1rem 2rem 0 2rem;
  border: 0.1rem solid var(--border-grey);
  border-radius: 25px;
  outline: none;
  z-index: 100;
}

.input-container-focus {
  border: 0.1rem solid var(--main);
}

.input-icon-search {
  position: absolute;
  top: 0.8rem;
  left: 1rem;
  width: 1.4rem;
  height: 1.4rem;
}

.input-icon-clear {
  position: absolute;
  top: 0.9rem;
  right: 1.1rem;
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
}

.input {
  padding: 0.8rem 3rem;
  gap: 0.5rem;
  font-size: 1rem;
  color: var(--text);
  width: calc(100% - 6rem);
  background-color: transparent;
  border-radius: 25px;
  border: 0.1rem solid transparent;
}

.input:focus {
  outline: none;
  border: 0.1rem solid transparent;
}

.search-suggestion-item {
  font-size: 1rem;
  color: var(--text);
  padding: 0 3rem;
  font-weight: normal;
  border-top: 0.1rem solid var(--border-grey);
  line-height: 3rem;
  cursor: pointer;
}

.search-suggestion-item:hover {
  background-color: var(--border-grey);
}

.search-suggestion-item-selected {
  background-color: var(--border-grey);
}

.search-suggestion-item-last {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.search-suggestion-item-bold {
  font-weight: 600;
}

.topic-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  background-color: var(--bg);
  margin-bottom: 0.1rem;
}

.topic-tab::-webkit-scrollbar {
  display: none;
}

.topic-button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 16px 0;
  transition: 0.3s;
  font-size: 1rem;
  color: var(--text);
  border-radius: 10px;
  flex: 1;
  position: relative;
  min-width: 50px;
}

.topic-button:hover {
  background-color: var(--border-grey);
  position: relative;
}

.topic-button.active {
  color: var(--text);
  font-weight: 600;
}

.topic-button.active:hover {
  background-color: var(--border-grey);
}

.topic-button.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 35%;
  right: 35%;
  height: 0.3rem;
  width: 30%;
  background: var(--main);
  border-radius: 0.3rem;
}

.pm-container {
  /* width: 100%; */
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  /* padding-top: 17px; */
  border-bottom: 0.1rem solid var(--border-grey);

}

.pm-main {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  flex-direction: row;
}

.pm-avatar {
  width: 40px;
  height: 40px;
  margin-top: 3px;
}

.pm-msg {
  max-width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 6px;
}

.pm-msg-text-container {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
}

.pm-msg-text {
  max-width: 100%;
  overflow-x: hide;
  line-height: 22px;
  font-size: 16px;
  color: var(--text);
  padding: 0 10px;
  margin-top: 4px;
  box-sizing: border-box;
  border-radius: 16px;
  word-wrap: break-word;
  word-break: break-all;
  background-color: var(--border-grey);
}

.ai-mask {
  position: absolute;
  background-image: linear-gradient(to bottom, rgb(255, 255, 255, 0), rgb(255, 255, 255, 0), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1));
  height: 220px;
  width: 100%;
  margin: 0 -20px;
}

.ai-mask-prompt {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 20px;
  color: #FFFFFF;
  background-color: var(--main);
  cursor: pointer;
}

.ai-mask-prompt:hover {
  background-color: var(--main-darker);
}

.ai-title {
  font-size: 20px;
  font-weight: bold;
  padding: 20px 0 10px 0;
}

.ai-outer {
  padding: 0 20px 20px 20px;
  border-bottom: 0.1rem solid var(--border-grey);
  min-height: 200px;
  overflow: hidden;
}

.ai-answer-block {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.ai-answer-emoji {
  margin-top: 5px;
  font-size: 24px;
  line-height: 24px;
  margin-right: 15px;
}

.ai-answer-msg {
  min-height: 24px;
}

.ai-post {
  border: 0.15rem solid var(--border-grey);
  overflow: hidden;
  border-radius: 16px;
}

@media (hover: none) {
  .topic-button:hover {
    background-color: inherit;
  }

  .topic-button.active:hover {
    background-color: inherit;
  }

}

@media only screen and (max-width: 700px) {
  .input-container {
    margin: 1rem 1rem 0 1rem;
  }

  .input-icon-search {
    top: 0.9rem;
    left: 0.8rem;
    width: 1.2rem;
    height: 1.2rem;
  }

  .input-icon-clear {
    top: 1rem;
    right: 0.9rem;
    width: 1.2rem;
    height: 1.2rem;
  }

  .input {
    padding: 0.8rem 2.5rem;
    /* font-size: 0.9rem; */
  }

  .topic-button {
    padding: 12px 0;
    /* font-size: 0.9rem; */
  }

  .search-suggestion-item {
    /* font-size: 0.9rem; */
    padding: 0 2.5rem;
    line-height: 2.5rem;
  }
}
</style>