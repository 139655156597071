<template>
    <div class="container" v-if="!is_last">
        <img :src="src" class="icon" v-show="show" />
    </div>
    <div class="container-nomore" v-else>
        <div>{{ $t('LoadMore.no_more') }}</div>
    </div>
</template>
      
<script>

export default {
    name: 'UniLoadMore',
    created(){
        const user_school_label = localStorage.getItem('user_school_label');
        if(user_school_label){
            this.src = require('../assets/loading-' + localStorage.getItem('user_school_label') + '.svg');
        }else{
            this.src = require('../assets/loading.svg');
        }
    },
    props: {
        title: String,
        is_last: Boolean
    },
    data() {
        return{
            src: null,
            show: true
        }
    },
    methods: {
    }
}
</script>
  
<style scoped>

.container{
    width:100%;
    height: 400px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.container-nomore{
    width:100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: var(--text-grey);
    font-size: 16px;
}

.icon{
    width: 3rem;
}

@media only screen and (max-width: 700px) {
    .container {
        height: 300px;
    }

    .container-nomore{
        height: 80px;
        font-size: 15px;
    }
}

</style>