import { render, staticRenderFns } from "./OrgWrite.vue?vue&type=template&id=592fbccc&scoped=true&"
import script from "./OrgWrite.vue?vue&type=script&lang=js&"
export * from "./OrgWrite.vue?vue&type=script&lang=js&"
import style0 from "./OrgWrite.vue?vue&type=style&index=0&id=592fbccc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "592fbccc",
  null
  
)

export default component.exports