import Vue from "vue";
import componentReport from "./Report.vue";

const comReport = Vue.extend(componentReport);

const instance = new comReport({
    el: document.createElement("div"),
});

instance.show = false;
const report = {
    show({ uni_post_id, comment_msg, comment_order = null, success = null }) {
        instance.show = true
        instance.uni_post_id = uni_post_id
        instance.comment_msg = comment_msg
        instance.comment_order = comment_order
        instance.success = success;
        document.body.appendChild(instance.$el);
    },
    hide() {
        instance.show = false;
        instance.uni_post_id = null
        instance.comment_msg = null
        instance.comment_order = null
        instance.success = null
    },
};
export default {
    install() {
        if (!Vue.$report) {
            Vue.$report= report;
        }
        Vue.mixin({
            created() {
                this.$report = Vue.$report;
            },
        });
    },
};
