<template>
    <div :class="show ? 'overlay overlay-anim-open' : 'overlay overlay-anim-close'" id="uni-reply">
        <div :class="show ? 'reply-container reply-container-anim-open' : 'reply-container reply-container-anim-close'">
            <div class="title-container">
                <div class="title">{{ title_txt }}</div>
                <img src="../../assets/delete.svg" class="close-icon" @click="close" />
            </div>

            <textarea class="textarea" :placeholder="placeholder_txt" v-model="comment_msg" />
            <div class="bottom-container">
                <div class="realname-container" v-if="true">
                    <div class="realname-info">{{ realname_txt }}</div>
                    <toggle-button class="realname-switch" :width="40" :height="20" :color="switch_color"
                        v-model="user_is_real_name" />
                </div>
                <div v-else></div>
                <div class="send-button" @click="send">{{ send_txt }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { ToggleButton } from 'vue-js-toggle-button'
import Vue from "vue"
import i18n from "../../locales";
import notice from "../../utils/notice.js";
import request from "../../utils/request.js";
import { subscribeWebpush } from '../../utils/webpush.js'
import { formatTimeToDisplay } from '../../utils/formatTime.js'

import checkEmojiFromText from '../../utils/emoji.js'

export default {
    name: "UniReply",
    components: { ToggleButton },
    props: {
        show: Boolean,
        uni_post_id: Number,
        comment_father_id: Number,
    },
    data() {
        return {
            switch_color: {
                checked: window.getComputedStyle(document.documentElement).getPropertyValue('--main'),
                unchecked: window.getComputedStyle(document.documentElement).getPropertyValue('--hover-grey')
            },
            title_txt: i18n.t('Reply.title'),
            send_txt: i18n.t('Reply.send'),
            realname_txt: i18n.t('Reply.realname'),
            placeholder_txt: i18n.t('Reply.placeholder'),
            comment_msg: '',
            user_is_real_name: false,
        }
    },
    methods: {
        close() {
            this.comment_msg = ''
            this.user_is_real_name = false
            this.placeholder_txt = i18n.t('Reply.placeholder')
            this.show = false
            let uni_reply = document.getElementById('uni-reply')
            setTimeout(() => {
                try {
                    uni_reply.parentNode.removeChild(uni_reply)
                } catch (err) { }
            }, 400)
        },
        send() {
            Vue.$loading.show()
            var user_is_real_name = 'false'
            if (this.user_is_real_name) user_is_real_name = 'true'
            var data = {
                uni_post_id: this.uni_post_id,
                comment_msg: this.comment_msg,
                user_is_real_name: user_is_real_name
            }
            if (this.comment_father_id) Object.assign(data, { comment_father_id: this.comment_father_id })
            request("/comment/post", data, true, [402])
                .then((res) => {
                    Vue.$loading.hide()
                    if (!res) return
                    if (res.code == 402) {
                        Vue.$modal.show({
                            title: i18n.t('Modal.error'),
                            content: i18n.t('/post/single/post.402', { silence_until: formatTimeToDisplay(res.silence_until) }),
                            show_cancel: false,
                        })
                        return
                    }
                    notice({
                        content: i18n.t('/comment/post.200'),
                    })
                    checkEmojiFromText(this.comment_msg)

                    this.checkWebpush()
                    this.close()
                    this.success && this.success();

                })
        },


        checkWebpush() {
            if (!("Notification" in window)) {
                return
            } else if (Notification.permission === "granted") {
                return
            } else if (Notification.permission == "denied") {
                return
            } else {
                Vue.$modal.show({
                    title: i18n.t('Webpush.comment_title'),
                    content: i18n.t('Webpush.comment_content'),
                    show_cancel: true,
                    success: (res) => {
                        if (!res) return
                        Notification.requestPermission().then((permission) => {
                            if (permission === "granted") {
                                subscribeWebpush(true)
                            } else {
                                Vue.$modal.show({
                                    title: i18n.t('Webpush.deny_title'),
                                    content: i18n.t('Webpush.deny_content'),
                                    show_cancel: false,
                                })
                            }
                        })
                    }
                })
            }
        },
    }
};
</script>

<style scoped>
.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.425);
    align-items: center;
    justify-content: center;
    display: flex;
    -webkit-transition-duration: 10s;
    transition-duration: 10s;
}

@media (prefers-color-scheme: dark) {
    .overlay {
        background-color: rgb(255, 255, 255, 0.425);
    }
}

.reply-container {
    display: block;
    padding: 1rem;
    background-color: var(--bg);
    border: 0.1rem solid var(--border-grey);
    width: 80%;
    max-width: 30rem;
    border-radius: 1rem;
}

.title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title {
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    color: var(--text);
}

.close-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.textarea {
    margin: 0.5rem 0;
    padding: 0;
    resize: none;
    width: 100%;
    height: 200px;
    border: none;
    font-size: 15px;
    font-family: "Chirp", sans-serif;
    color: var(--text);
    background-color: var(--bg);
}

.textarea:focus {
    outline: none;
}

.bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.realname-container {
    display: flex;
    flex-direction: row;
}

.realname-info {
    font-size: 14px;
    line-height: 25px;
    color: var(--text-grey);
}

.realname-switch {
    padding: 2.5px 5px;
}

.send-button {
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 20px;
    color: #FFFFFF;
    background-color: var(--main);
}

.send-button:hover {
    background-color: var(--main-darker);
    cursor: pointer;
}

.overlay-anim-open {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: overlay-anim-open;
    animation-name: overlay-anim-open;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

.overlay-anim-close {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: overlay-anim-close;
    animation-name: overlay-anim-close;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

.reply-container-anim-open {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: container-anim-open;
    animation-name: container-anim-open;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

.reply-container-anim-close {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: container-anim-close;
    animation-name: container-anim-close;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

@-webkit-keyframes container-anim-open {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0, 0, 1);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
    }
}

@keyframes container-anim-open {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0, 0, 1);
        transform: scale3d(0, 0, 1);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@-webkit-keyframes container-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale3d(0.5, 0.5, 1);
    }
}

@keyframes container-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale3d(0.5, 0.5, 1);
        transform: scale3d(0.5, 0.5, 1);
    }
}

@-webkit-keyframes overlay-anim-open {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes overlay-anim-open {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes overlay-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes overlay-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>