<template>
  <div style="width: 100%;">
    <Navbar :title="user_info.user_serial ? user_info.user_serial : ''" />
    <div class="user-outer" v-show="user_info">
      <div class="user-left-container">
        <img :src="'https://i.boatonland.com/avatar/' + user_info.user_avatar + '.svg'" class="user-avatar"
          v-show="user_info.user_avatar">
        <div class="user-serial">{{ user_info.user_serial }}</div>
      </div>
      <div class="user-button" @click.prevent="pm">{{ $t('MiddleUser.pm') }}</div>
    </div>
    <Refresh :on-refresh="onRefresh">
      <One :type="item.type" :data="item.data" v-for="(item, index) in one_list" :key="index" />
      <LoadMore :is_last="is_last" v-show="is_loading_more || is_last" />
    </Refresh>
  </div>
</template>
    
<script>
import request from '../utils/request.js'
import { subscribeWebpush } from '../utils/webpush.js'
import { localizeTopic } from '../utils/localize';

import Navbar from './Navbar.vue'
import LoadMore from './LoadMore.vue'
import One from './One.vue'

export default {
  name: 'UniMiddleUser',
  components: { Navbar, LoadMore, One },
  created() {
    this.user_school_label = this.$route.params.user_school_label
    this.user_serial = this.$route.params.user_serial
    this.$loading.show()
    this.getUserProfile()
    this.getPostByUser()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  activated() {
    document.title = this.title
  },
  data() {
    return {
      user_school_label: null,
      user_serial: null,
      page: 0,
      one_list: [],
      is_last: false,
      is_loading_more: false,
      finishRefresh: null,
      user_info: {},
      title: ''
    }
  },
  methods: {
    getUserProfile() {
      request("/user/profile/visit", {
        visit_user_serial: this.user_serial,
        visit_user_school_label: this.user_school_label
      }, true, [400])
        .then((res) => {
          if (!res) return
          if (res.code == 400) {
            this.$modal.show({
              content: this.$t('/user/profile/visit.400'),
              success: () => {
                if (window.history.length <= 1) {
                  this.$router.push('/home')
                } else {
                  this.$router.back()
                }
              }
            })
            return
          }
          if (res.user_info.user_type == 'organization') {
            this.$router.replace('/org/' + res.user_info.user_serial)
            return
          }
          this.user_info = res.user_info
          const user_school_label = localStorage.getItem('user_school_label')
          if (user_school_label == "HKU") {
            this.title = this.$t('MiddleUser.title', {user_serial: res.user_info.user_serial}) + " / " + this.$t('AppNameTitle.HKU')
          }
          if (user_school_label == "CUHK") {
            this.title = this.$t('MiddleUser.title', {user_serial: res.user_info.user_serial}) + " / " + this.$t('AppNameTitle.CUHK')
          }
          if (user_school_label == "UST") {
            this.title = this.$t('MiddleUser.title', {user_serial: res.user_info.user_serial}) + " / " + this.$t('AppNameTitle.UST')
          }
          document.title = this.title
        })
    },
    getPostByUser() {
      request("/post/list/user", {
        visit_user_serial: this.user_serial,
        visit_user_school_label: this.user_school_label,
        page: this.page
      })
        .then((res) => {
          this.$loading.hide()
          if (this.finishRefresh) this.finishRefresh()
          if (!res) return
          this.is_loading_more = false
          this.is_last = res.is_last
          res.one_list.forEach((one_item) => {
            if(one_item.type == 'post'){
              one_item.data.post_topic = localizeTopic(one_item.data.post_topic)
            }
          })
          if (this.page == 0) {
            this.one_list = res.one_list
          } else {
            this.one_list = this.one_list.concat(res.one_list)
          }
        })
    },
    onRefresh(finishRefresh) {
      this.finishRefresh = finishRefresh
      this.page = 0
      this.is_loading_more = true
      this.getPostByUser()
    },
    loadMore() {
      if (this.is_loading_more) return;
      if (this.is_last) return;
      this.is_loading_more = true
      this.page = this.page + 1
      this.getPostByUser()
    },
    handleScroll() {
      const scrollHeight = document.documentElement.scrollHeight
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const clientHeight = document.documentElement.clientHeight
      if (scrollTop + clientHeight >= scrollHeight - 1400) {
        this.loadMore()
      }
    },
    pm(event) {
      this.$contextmenu({
        items: [
          {
            label: this.$t('MiddleUser.realname_pm'),
            onClick: () => {
              this.createChat(true)
            }
          },
          {
            label: this.$t('MiddleUser.anonymous_pm'),
            onClick: () => {
              this.createChat(false)
            }
          },
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
      return false
    },
    createChat(is_realname) {
      this.$loading.show()
      request("/pm/chat/create", {
        sender_is_real_name: is_realname,
        to_type: 'user',
        receiver_serial: this.user_serial,
        receiver_school_label: this.user_school_label
      })
        .then((res) => {
          this.$loading.hide()
          if (!res) return
          this.$router.push('/chat/' + res.chat_id)
          this.checkWebpushPm()
        })
    },
    checkWebpushPm() {
      if (!("Notification" in window)) {
        return
      } else if (Notification.permission === "granted") {
        return
      } else if (Notification.permission == "denied") {
        return
      } else {
        this.$modal.show({
          title: this.$t('Webpush.pm_title'),
          content: this.$t('Webpush.pm_content'),
          show_cancel: true,
          success: (res) => {
            if (!res) return
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                subscribeWebpush(true)
              } else {
                this.$modal.show({
                  title: this.$t('Webpush.deny_title'),
                  content: this.$t('Webpush.deny_content'),
                  show_cancel: false,
                })
              }
            })
          }
        })
      }
    },

  }
}
</script>

<style scoped>
.user-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 0.1rem solid var(--border-grey);
  padding: 20px;
  position: relative;
}

.user-left-container {
  display: flex;
  align-items: center;
}

.user-avatar {
  height: 45px;
}

.user-serial {
  color: var(--text);
  font-weight: 600;
  margin-left: 15px;
  font-size: 24px;
}

.user-button {
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 20px;
  color: #FFFFFF;
  background-color: var(--main);
  cursor: pointer;
}

.user-button:hover {
  background-color: var(--main-darker);
}

@media only screen and (max-width: 700px) {
  .user-outer{
    padding: 15px;
  }
}
</style>