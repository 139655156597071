import Vue from 'vue'
import i18n from '../locales'
import router from '../router'
import request from './request.js'

export default async function notice({ title, content, user_avatar = null, url = null, notice_id = null } = {}) {
    if (!title) {
        title = i18n.t('Notice.title')
    }

    let image = null
    if (user_avatar) {
        image = 'https://i.boatonland.com/avatar/' + user_avatar + '.svg'
    } else {
        image = require('../assets/logo-' + localStorage.getItem('user_school_label') + '.png')
    }

    let nav2 = null

    if (url && notice_id) {
        nav2 = () => Promise.resolve().then(() => {
            if(url.startsWith('/post/') && window.location.pathname.startsWith('/post/')){
                router.push(url)
                window.location.reload()
            }else{
                router.push(url)
            }
            request("/notice/read", {
                notice_id: notice_id
            })
        })
    } else if (url) {
        nav2 = () => Promise.resolve().then(() => {
            router.push(url)
        })
    } else {
        nav2 = () => Promise.resolve().then(() => {
            return
        })
    }
    Vue.notify({
        text: 'default',
        duration: 5000,
        data: {
            title: title,
            content: content,
            image: image,
            nav2: nav2
        }
    })
}