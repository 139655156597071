<template>
  <div id="app">
    <notifications :width="notice_width">
      <template slot="body" slot-scope="props">
        <div class="notice-container" @click="props.item.data.nav2().finally(props.close)">
          <img class="notice-image" :src="props.item.data.image" />
          <div class="notice-main">
            <div class="notice-title" v-if="props.item.data.title">{{ props.item.data.title }}</div>
            <div class="notice-msg">{{ props.item.data.content }}</div>
          </div>
        </div>
      </template>
    </notifications>
    <router-view />
  </div>
</template>

<script>
import customize from './utils/customize.js';
import { launchWebSocket } from './utils/websocket.js';

export default {
  name: 'app',
  created() {
    customize()
    launchWebSocket()
  },
  mounted() {
    window.addEventListener('resize', this.handleScreenWidthChange)
    this.handleScreenWidthChange()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleScreenWidthChange)
  },
  data() {
    return {
      notice_width: 375
    }
  },
  methods: {
    handleScreenWidthChange() {
      if (window.innerWidth <= 700) {
        this.notice_width = window.innerWidth
      } else {
        this.notice_width = 375
      }
    }
  }
}
</script>

<style>
@import url("https://fonts.cdnfonts.com/css/chirp-2");


html {
  overflow-y: scroll;
  font-family: "Chirp", sans-serif;
  overscroll-behavior-y: contain;
  background-color: var(--bg);
}

:root {
  --main: #1f9bf0;
  --main-darker: #366ABD;
  --text: #000000;
  --bg: #ffffff;
  --hover-grey: #eeeeee;
  --border-grey: #f6f6f6;
  --shaddow-grey: #999999;
  --bg-grey: #dddddd;
  --text-grey: #8c8c8c;
  --vote-grey: #bbbbbb;
  --link: #0969da;
  --tip: linear-gradient(84.52deg, #FF5F5C 28.29%, #FE8A63 100%);
  --safe-bottom: env(safe-area-inset-bottom);
}

@media (prefers-color-scheme: dark) {
  :root {
    --main: #1f9bf0;
    --main-darker: #366ABD;
    --text: #ffffff;
    --bg: #000000;
    --hover-grey: #222222;
    --border-grey: #1A1A1A;
    --shaddow-grey: #777777;
    --bg-grey: #444444;
    --text-grey: #8c8c8c;
    --vote-grey: #bbbbbb;
    --link: #0969da;
    --tip: linear-gradient(84.52deg, #FF5F5C 28.29%, #FE8A63 100%);
    --safe-bottom: env(safe-area-inset-bottom);
  }
}

.notice-container {
  display: flex;
  background-color: var(--bg);
  padding: 15px;
  margin: 10px 15px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px var(--bg-grey);
}

.notice-image {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  margin-right: 15px;
}

.notice-title {
  font-size: 17px;
  font-weight: 600;
  color: var(--text);
  /* margin-bottom: 5px; */
}

.notice-msg {
  font-size: 15px;
  color: var(--text);
}

.menu-theme.menu {
  border-radius: 1rem !important;
  padding: 0 !important;
}

.menu-theme .menu_item {
  color: var(--text) !important;
  padding: 6px 16px !important;
  line-height: 40px !important;
  font-size: 15px !important;
}

.menu-theme .menu_item__available {
  border-radius: 1rem !important;
}

.menu-theme .menu_item__available:hover {
  background: var(--hover-grey) !important;
}

.menu-theme .menu_item_expand {
  border-radius: 1rem !important;
}

.menu-theme .menu_item_expand:hover {
  background: var(--hover-grey) !important;
}

@media only screen and (max-width: 700px) {
  .notice-container {
    padding: 12px 15px;
    margin: 10px;
  }

  .notice-image {
    width: 45px;
    height: 45px;
  }

  .notice-title {
    font-size: 16px;
  }

  .menu-theme .menu_item {
    line-height: 34px !important;
  }

}

.falling-item {
  position: fixed;
  top: 0;
  font-size: 40px;
  pointer-events: none;
}

@keyframes fall1 {
    0% {
        transform: translateX(0) translateY(-100px);
        opacity: 1;
    }
    50% {
        transform: translateX(30px) translateY(50vh);
        opacity: 0.9;
    }
    100% {
        transform: translateX(-30px) translateY(100vh);
        opacity: 0;
    }
}

@keyframes fall2 {
    0% {
        transform: translateX(0) translateY(-100px);
        opacity: 1;
    }
    25% {
        transform: translateX(-20px) translateY(25vh);
    }
    50% {
        transform: translateX(20px) translateY(50vh);
    }
    75% {
        transform: translateX(-20px) translateY(75vh);
        opacity: 0.8;
    }
    100% {
        transform: translateX(0px) translateY(100vh);
        opacity: 0;
    }
}

@keyframes fall3 {
    0% {
        transform: translateX(0) translateY(-100px);
        opacity: 1;
    }
    33% {
        transform: translateX(-40px) translateY(33vh);
        opacity: 0.9;
    }
    66% {
        transform: translateX(40px) translateY(66vh);
        opacity: 0.8;
    }
    100% {
        transform: translateX(0px) translateY(100vh);
        opacity: 0;
    }
}
</style>