<template>
  <div>
    <h1>文章发表</h1>
    <el-form
      label-width="80px"
      :model="postForm"
      label-position="left"
      :rules="postFormRules"
      ref="postFormRef"
    >
      <el-form-item label="社团名称" prop="org_name">
        <el-input
          v-model="postForm.org_name"
          placeholder="该项不可修改"
          :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item label="文章链接" prop="article_link">
        <el-input
          v-model="postForm.article_link"
          placeholder="以 https://mp.weixin.qq.com/s/ 开头的链接"
        ></el-input>
      </el-form-item>
      <el-form-item label="开启评论">
        <el-switch v-model="postForm.open_comment"></el-switch>
      </el-form-item>
      <el-form-item label="正文内容" v-if="postForm.open_comment">
        <el-input type="textarea" v-model="postForm.post_msg"></el-input>
      </el-form-item>
    </el-form>
    <el-button type="primary" style="margin-top: 10px" @click="post"
      >发表</el-button
    >
  </div>
</template>

<script>
import request from '../utils/request.js'
import notice from '../utils/notice.js'

export default {
  created() {
    this.getOrgInfo()
  },
  data() {
    return {
      postForm: {
        org_name: "",
        article_link: "",
        open_comment: true,
        post_msg: "",
      },
      postFormRules: {
        org_name: [
          { required: true, message: "请输入组织名称", trigger: "blur" },
        ],
        article_link: [
          { required: true, message: "请输入文章链接", trigger: "blur" },
        ]
      },
    };
  },
  methods: {
    getOrgInfo() {
      this.$loading.show()
      request("/org/profile/get")
        .then((res) => {
          this.$loading.hide()
          if (!res) return
          this.postForm.org_name = res.org_info.org_name
        })
    },
    post() {
      this.$refs.postFormRef.validate((valid) => {
        if (!valid) return
        this.$loading.show()
        request("/org/article/post", {
          article_link: this.postForm.article_link,
          article_open_comment: this.postForm.open_comment,
          post_msg: this.postForm.post_msg
        })
          .then((res) => {
            this.$loading.hide()
            if (!res) return
            notice({
              content: this.$t('/org/article/post.200'),
            })
          })
      })
    },
  },
};
</script>

<style scoped>
.el-table .cell {
  padding-right: 0;
}
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>