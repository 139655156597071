import Vue from "vue";
import componentReply from "./Reply.vue";

const comReply = Vue.extend(componentReply);

const instance = new comReply({
    el: document.createElement("div"),
});

instance.show = false;
const reply = {
    show({ uni_post_id, comment_father_id = null, comment_order = null, success = null }) {
        instance.show = true
        instance.uni_post_id = uni_post_id
        instance.comment_father_id = comment_father_id
        instance.success = success;
        if(comment_order != null){
            if(comment_order == 0){
                instance.placeholder_txt = 'Re G:'
            }else{
                instance.placeholder_txt = 'Re LG' + comment_order + ':'
            }
        }
        instance.switch_color= {
            checked: window.getComputedStyle(document.documentElement).getPropertyValue('--main'),
            unchecked: window.getComputedStyle(document.documentElement).getPropertyValue('--hover-grey')
        }
        document.body.appendChild(instance.$el);
    },
    hide() {
        instance.show = false;
        instance.uni_post_id = null
        instance.comment_father_id = null
    },
};
export default {
    install() {
        if (!Vue.$reply) {
            Vue.$reply= reply;
        }
        Vue.mixin({
            created() {
                this.$reply = Vue.$reply;
            },
        });
    },
};
