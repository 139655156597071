<template>
  <div style="width: 100%;">
    <div class="header-container">
      <div class="user-outer" v-show="user_info">
        <div class="user-left-container">
          <img :src="'https://i.boatonland.com/avatar/' + user_info.user_avatar + '.svg'" class="user-avatar"
            v-show="user_info.user_avatar">
          <div class="user-serial">{{ user_info.user_serial }}</div>
        </div>
        <div class="user-button" @click.prevent="more">{{ $t('MiddleMine.more') }}</div>
      </div>
      <div class="topic-tab">
        <button :class="(function_index == index) ? 'topic-button active' : 'topic-button'" @click="switchFunction(index)"
          v-for="(func, index) in function_list" :key="index">{{
            func.text }}<span class="count-text">{{ (index == 0) ? user_info.post_count : user_info.follow_count
  }}</span></button>
      </div>
    </div>
    <Refresh :on-refresh="onRefresh">
      <One :type="item.type" :data="item.data" v-for="(item, index) in one_list" :key="index" />
      <LoadMore :is_last="is_last" v-show="is_loading_more || is_last" />
    </Refresh>
  </div>
</template>
    
<script>
import { db, closeWebsocket } from '../utils/websocket.js'
import { unsubscribeWebpush } from '../utils/webpush.js'
import request from '../utils/request.js'
import customize from '../utils/customize.js'
import { localizeTopic } from '../utils/localize';

import Navbar from './Navbar.vue'
import LoadMore from './LoadMore.vue'
import One from './One.vue'

export default {
  name: 'UniMiddleMine',
  components: { Navbar, LoadMore, One },
  created() {
    this.$loading.show()
    this.getPostPre()
  },
  activated() {
    this.getUserProfile()
    window.addEventListener('scroll', this.handleScroll);
    document.title = this.title
  },
  deactivated() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      function_index: 0,
      function_list: [
        { "mode": "post", "text": this.$t('MiddleMine.post') },
        { "mode": "follow", "text": this.$t('MiddleMine.follow') }
      ],
      page: 0,
      one_list: [],
      is_last: false,
      is_loading_more: false,
      finishRefresh: null,
      user_info: {},
      title: ''
    }
  },
  methods: {
    getUserProfile() {
      request("/user/profile/get")
        .then((res) => {
          if (!res) return
          this.user_info = res.user_info
          const user_school_label = localStorage.getItem('user_school_label')
          if (user_school_label == "HKU") {
            this.title = this.$t('MiddleMine.title', { user_serial: res.user_info.user_serial }) + " / " + this.$t('AppNameTitle.HKU')
          }
          if (user_school_label == "CUHK") {
            this.title = this.$t('MiddleMine.title', { user_serial: res.user_info.user_serial }) + " / " + this.$t('AppNameTitle.CUHK')
          }
          if (user_school_label == "UST") {
            this.title = this.$t('MiddleMine.title', { user_serial: res.user_info.user_serial }) + " / " + this.$t('AppNameTitle.UST')
          }
          document.title = this.title
        })
    },
    getPostPre() {
      if (this.function_index == 0) {
        this.getPostByMine()
      } else if (this.function_index == 1) {
        this.getPostByFollow()
      }
    },
    getPostByMine() {
      request("/post/list/my", {
        page: this.page
      })
        .then((res) => {
          this.$loading.hide()
          if (this.finishRefresh) this.finishRefresh()
          if (!res) return
          this.is_loading_more = false
          this.is_last = res.is_last
          res.one_list.forEach((one_item) => {
            if (one_item.type == 'post') {
              one_item.data.post_topic = localizeTopic(one_item.data.post_topic)
            }
          })
          if (this.page == 0) {
            this.one_list = res.one_list
          } else {
            this.one_list = this.one_list.concat(res.one_list)
          }
        })
    },
    getPostByFollow() {
      request("/post/list/follow", {
        page: this.page
      })
        .then((res) => {
          this.$loading.hide()
          if (this.finishRefresh) this.finishRefresh()
          if (!res) return
          this.is_loading_more = false
          this.is_last = res.is_last
          res.one_list.forEach((one_item) => {
            if (one_item.type == 'post') {
              one_item.data.post_topic = localizeTopic(one_item.data.post_topic)
            }
          })
          if (this.page == 0) {
            this.one_list = res.one_list
          } else {
            this.one_list = this.one_list.concat(res.one_list)
          }
        })
    },
    switchFunction(index) {
      window.scrollTo(0, 0);
      this.page = 0
      this.function_index = index
      this.$loading.show()
      this.getPostPre()
    },
    onRefresh(finishRefresh) {
      this.finishRefresh = finishRefresh
      this.page = 0
      this.is_loading_more = true
      this.getPostPre()
    },
    loadMore() {
      if (this.is_loading_more) return;
      if (this.is_last) return;
      this.is_loading_more = true
      this.page = this.page + 1
      this.getPostPre()
    },
    handleScroll() {
      const scrollHeight = document.documentElement.scrollHeight
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const clientHeight = document.documentElement.clientHeight
      if (scrollTop + clientHeight >= scrollHeight - 1400) {
        this.loadMore()
      }
    },
    more(event) {
      this.$contextmenu({
        items: [
          {
            label: this.$t('MiddleMine.audit_user_info'),
            onClick: () => {
              this.$router.push('/edit')
            }
          },
          {
            label: this.$t('MiddleMine.switch_language'),
            onClick: () => {
              const user_language = localStorage.getItem('language') || navigator.language || navigator.userLanguage
              this.$loading.show()
              if (!user_language.startsWith('zh')) {
                localStorage.setItem('language', 'zh')
                location.reload()
              }else{
                localStorage.setItem('language', 'en')
                location.reload()
              }
            }
          },
          {
            label: this.$t('MiddleMine.switch_account'),
            onClick: () => {
              this.switchAccount()
            }
          },
          {
            label: this.$t('MiddleMine.logout'),
            onClick: () => {
              this.logout()
            }
          },
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
      return false
    },
    switchAccount() {
      window.location.href = 'https://login.tripleuni.com/TripleUni'
    },
    logout() {
      this.$modal.show({
        content: this.$t('MiddleMine.logout_modal_content'),
        show_cancel: true,
        success: (res) => {
          if (!res) return
          if (("Notification" in window) && Notification.permission === "granted") {
            unsubscribeWebpush(localStorage.getItem('token'))
          }
          db.delete()
          closeWebsocket()

          const language = localStorage.getItem('language')
          localStorage.clear()
          if (language) localStorage.setItem('language', language)

          customize()
          window.location.href = 'https://tripleuni.com/landing/'
        }
      })
    }

  }
}
</script>

<style scoped>
.count-text {
  font-size: 12px;
  margin-left: 6px;
  color: var(--text);
}

.header-container {
  position: sticky;
  top: 0;
  border-bottom: 0.1rem solid var(--border-grey);
  background: var(--bg);
  z-index: 99;
}

.user-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 20px;
  position: relative;
}

.user-left-container {
  display: flex;
  align-items: center;
}

.user-avatar {
  height: 45px;
}

.user-serial {
  color: var(--text);
  font-weight: 600;
  margin-left: 15px;
  font-size: 24px;
}

.user-button {
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 20px;
  color: var(--text-grey);
  border: 0.1rem solid var(--border-grey);
  background-color: var(--bg);
}

.user-button:hover {
  background-color: var(--hover-grey);
  cursor: pointer;
}

.topic-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  background-color: var(--bg);
  margin-bottom: 0.1rem;
}

.topic-tab::-webkit-scrollbar {
  display: none;
}

.topic-button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 16px 0;
  transition: 0.3s;
  font-size: 1rem;
  color: var(--text);
  border-radius: 10px;
  flex: 1;
  position: relative;
  min-width: 50px;
}

.topic-button:hover {
  background-color: var(--border-grey);
  position: relative;
}

.topic-button.active {
  font-weight: 600;
  color: var(--text);
}

.topic-button.active:hover {
  background-color: var(--border-grey);
}

.topic-button.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 35%;
  right: 35%;
  height: 0.3rem;
  width: 30%;
  background: var(--main);
  border-radius: 0.3rem;
}

@media (hover: none) {
  .topic-button:hover {
    background-color: inherit;
  }

  .topic-button.active:hover {
    background-color: inherit;
  }

}

@media only screen and (max-width: 700px) {
  .user-outer{
    padding: 15px;
  }

  .topic-button {
    padding: 12px 0;
    font-size: 0.9rem;
  }
}
</style>