import i18n from '../locales'

export default function customize() {
    const user_school_label = localStorage.getItem('user_school_label')
    if (user_school_label == "HKU") {
      document.documentElement.style.setProperty('--main', '#D85050');
      document.documentElement.style.setProperty('--main-darker', '#C41919');
      document.title = i18n.t('AppName.HKU');
    }else if (user_school_label == "CUHK") {
      document.documentElement.style.setProperty('--main', '#80C5D2');
      document.documentElement.style.setProperty('--main-darker', '#77A4AD');
      document.title = i18n.t('AppName.CUHK');
    }else if (user_school_label == "UST") {
      document.documentElement.style.setProperty('--main', '#7A4BED');
      document.documentElement.style.setProperty('--main-darker', '#682CFB');
      document.title = i18n.t('AppName.UST');
    }else{
      document.documentElement.style.setProperty('--main', '#1F9BF0');
      document.documentElement.style.setProperty('--main-darker', '#366ABD');
      document.title = i18n.t('AppName.UNI');
    }
}