<template>
    <div :class="sticky ? 'nav-bar-title sticky' : 'nav-bar-title'" :style="show_back ? '' : 'padding-left: 1.3rem'">
            <BackSvg class="nav-bar-title-back" v-show="show_back" @click.native="navBack" />
            <div class="title">{{ title }}</div>
    </div>
</template>
      
<script>
import BackSvg from './Icon/BackSvg.vue'

export default {
    name: 'UniNavbar',
    components: { BackSvg },
    props: {
        title: String,
        url: String,
        show_back: {
            type: Boolean,
            default: true
        },
        sticky: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        navBack() {
            if(this.url){
                this.$router.push(this.url);
            }else{
                if (window.history.length <= 1) {
                    this.$router.push('/home')
                } else {
                    this.$router.back()
                }
            }
        }
    }
}
</script>
  
<style scoped>
.nav-bar-title {
    font-size: 1.3rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin:0;
    display: flex;
    background-color: var(--bg);
}

.nav-bar-title-back {
    margin-top: 0.3rem;
    width: 1rem;
    height: 1rem;
    padding: 1rem;
    border-radius: 50%;
}

.nav-bar-title-back:hover {
    background-color: var(--border-grey);
}

.sticky {
    position: sticky;
    top: 0;
    z-index: 99;
    /* backdrop-filter: blur(20rem);
    -webkit-backdrop-filter: blur(20rem); */
    border-bottom: 0.1rem solid var(--border-grey);
}

.title {
    padding-left: 0rem;
    margin:1rem 0;
    color: var(--text);
}
</style>