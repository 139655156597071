<template>
    <div :class="show ? 'overlay overlay-anim-open' : 'overlay overlay-anim-close'" id="uni-loading" v-show="finish_loading">
        <img class="icon" :src="src" />
    </div>
</template>
<script>
export default {
    name: "UniLoading",
    props: {
        show: Boolean,
        finish_loading: Boolean,
        src: Object
    },
};
</script>
  
<style scoped>
.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: rgb(255, 255, 255, 0.7);
    align-items: center;
    justify-content: center;
    display: flex;
}

@media (prefers-color-scheme: dark) {
    .overlay{
        background-color: rgb(0, 0, 0, 0.7);
    }
}

.icon {
    width: 3rem;
}

.overlay-anim-open {
    -webkit-animation-duration: 0.1s;
    animation-duration: 0.1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: overlay-anim-open;
    animation-name: overlay-anim-open;
    -webkit-animation-timing-function: cubic-bezier(0.6,0,0.4,1);
    animation-timing-function: cubic-bezier(0.6,0,0.4,1);
}

.overlay-anim-close {
    -webkit-animation-duration: 0.1s;
    animation-duration: 0.1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: overlay-anim-close;
    animation-name: overlay-anim-close;
    -webkit-animation-timing-function: cubic-bezier(0.6,0,0.4,1);
    animation-timing-function: cubic-bezier(0.6,0,0.4,1);
}


@-webkit-keyframes overlay-anim-open {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes overlay-anim-open {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes overlay-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes overlay-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>
  