<template>
  <div style="width: 100%;position: relative;">
    <Navbar :title="title" v-show="chat_id" url="/chat" />
    <div class="null-container" v-show="!chat_id">
      <div>
        <div class="null-title">{{ $t('RightChat.null_title') }}</div>
        <div class="null-info">{{ $t('RightChat.null_content') }}</div>
      </div>
    </div>
    <template v-if="chat_id">
      <div class="pm-list-container">
        <div class="pm-container" v-for="(pm, index) in pm_list" :key="index"
          @contextmenu.prevent="rightClick($event, pm)">
          <div class="pm-time"
            v-if="index == 0 || pm_list[index].pm_create_time - pm_list[index - 1].pm_create_time > 60 * 5">
            {{ formatTime(pm.pm_create_time) }}</div>
          <div :class="pm.pm_is_from_me ? 'pm-main-me' : 'pm-main'">
            <img class="pm-avatar" :src="'https://i.boatonland.com/avatar/' + pm.user_avatar + '.svg'"
              @click.stop="nav2User(pm)" />
            <div :class="pm.pm_is_from_me ? 'pm-msg-me' : 'pm-msg'">
              <div :class="pm.pm_is_from_me ? 'pm-msg-text-container-me' : 'pm-msg-text-container'">
                <div v-if="pm.pm_media && pm.pm_media.type == 'image'" class="pm-image">
                  <img :src="pm.pm_media.image_link" @click.stop="previewImage(pm.pm_media.image_link)" />
                </div>
                <div v-else :class="pm.pm_is_from_me ? 'pm-msg-text-me' : 'pm-msg-text'">{{ pm.pm_msg }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="message-bar">
        <img src="../assets/image.svg" class="message-bar-icon-image" @click="clickUploadButton">
        <input type="file" ref="fileInput" accept="image/*" @change="getFile" style="display: none" />
        <input class="input" v-model="pm_msg" @keyup.enter="send" />
        <img src="../assets/send.svg" class="message-bar-icon-send" @click="send">
      </div>
    </template>
  </div>
</template>
    
<script>
import Navbar from './Navbar.vue'

import { useRoute } from 'vue2-helpers/vue-router'
import { useObservable } from '@vueuse/rxjs'
import { liveQuery } from 'dexie'
import request from '../utils/request.js'
import { db } from '../utils/websocket.js'
import formatTime, { formatTimeToDisplay } from '../utils/formatTime.js'
import { upload } from '../utils/upload.js'
import notice from '../utils/notice.js'

export default {
  name: 'UniRightChat',
  components: { Navbar },
  created() {
    this.setData()
    const user_school_label = localStorage.getItem('user_school_label')
    if (user_school_label == "HKU") {
      document.title = this.$t('MiddlePm.title') + " / " + this.$t('AppNameTitle.HKU')
    }
    if (user_school_label == "CUHK") {
      document.title = this.$t('MiddlePm.title') + " / " + this.$t('AppNameTitle.CUHK')
    }
    if (user_school_label == "UST") {
      document.title = this.$t('MiddlePm.title') + " / " + this.$t('AppNameTitle.UST')
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (!this.$route.params.chat_id) return
      var chatBox = document.querySelector('.pm-list-container');
      if (!chatBox) return
      document.querySelector('.message-bar').style.width = chatBox.offsetWidth + 'px'
    }, true);
  },
  destroyed() {
    window.removeEventListener('resize', () => {
      if (!this.$route.params.chat_id) return
      var chatBox = document.querySelector('.pm-list-container');
      if (!chatBox) return
      document.querySelector('.message-bar').style.width = chatBox.offsetWidth + 'px'
    }, true);
  },
  setup() {
    if (useRoute().params.chat_id) {
      const chat_id = parseInt(useRoute().params.chat_id)
      return {
        pm_list: useObservable(
          liveQuery(async () => {
            return await db.pm.where("chat_id").equals(chat_id).toArray()
          })
        )
      };
    } else {
      return {
        pm_list: []
      }
    }
  },
  data() {
    return {
      chat_id: null,
      title: '',
      pm_msg: '',
      selected_pm: null,
      is_long_press: false
    }
  },
  methods: {
    setData() {
      if (this.$route.params.chat_id) {
        this.chat_id = this.$route.params.chat_id
        db.chat.where("chat_id").equals(parseInt(this.chat_id)).limit(1).toArray()
          .then((chat_list) => {
            if (chat_list.length > 0) {
              var chat = chat_list[0]
              this.title = chat.user_alias
              chat.chat_unread_count = 0
              db.chat.put(chat)
              var chatBox = document.querySelector('.pm-list-container');
              chatBox.scrollTop = chatBox.scrollHeight
              document.querySelector('.message-bar').style.width = chatBox.offsetWidth + 'px'
              const user_school_label = localStorage.getItem('user_school_label')
              if (user_school_label == "HKU") {
                document.title = this.$t('RightChat.title', { user_alias: chat.user_alias }) + " / " + this.$t('AppNameTitle.HKU')
              }
              if (user_school_label == "CUHK") {
                document.title = this.$t('RightChat.title', { user_alias: chat.user_alias }) + " / " + this.$t('AppNameTitle.CUHK')
              }
              if (user_school_label == "UST") {
                document.title = this.$t('RightChat.title', { user_alias: chat.user_alias }) + " / " + this.$t('AppNameTitle.UST')
              }
            } else {
              request("/pm/chat/get", {
                chat_id: this.chat_id
              }).then((res) => {
                if (!res) return
                var chat_detail = res.chat_detail
                chat_detail.chat_unread_count = 0
                db.chat.where("chat_id").equals(parseInt(this.chat_id)).limit(1).toArray()
                  .then((res) => {
                    var chat_list = res
                    if (!chat_list[0]) {
                      db.chat.add(chat_detail)
                        .then(() => {
                          this.setData()
                        })
                    }
                  })
              })
            }
          })
      }
    },
    send() {
      const pm_msg = this.pm_msg
      const pm_create_time = Math.floor(Date.now() / 1000)
      const pm_list = this.pm_list
      var pm_is_real_name = false
      var user_alias = ''
      var user_serial = ''
      var user_avatar = ''
      for (let i = 0; i < pm_list.length; i++) {
        if (pm_list[i].pm_is_from_me) {
          pm_is_real_name = pm_list[i].pm_is_real_name
          user_alias = pm_list[i].user_alias
          user_serial = pm_list[i].user_serial
          user_avatar = pm_list[i].user_avatar
          break
        }
      }
      this.pm_msg = ''
      this.pm_list.push({
        chat_id: this.chat_id,
        pm_create_time: pm_create_time,
        pm_id: null,
        pm_is_from_me: true,
        pm_is_real_name: pm_is_real_name,
        pm_msg: pm_msg,
        pm_media: null,
        user_avatar: user_avatar,
        user_alias: user_alias,
        user_serial: user_serial
      })
      setTimeout(() => {
        var chatBox = document.querySelector('.pm-list-container');
        chatBox.scrollTop = chatBox.scrollHeight;
      }, 50);
      request("/pm/message/send", {
        chat_id: this.chat_id,
        pm_msg: pm_msg
      }, true, [400])
        .then((res) => {
          if (!res) return
          if (res.code == 400) {
            this.$modal.show({
              title: this.$t('Modal.error'),
              content: this.$t('/pm/message/send.400', { silence_until: formatTimeToDisplay(res.silence_until) }),
              show_cancel: false,
            })
            return
          }
        })
    },
    sendImage(image_link) {
      const pm_msg = "[Image]"
      const pm_create_time = Math.floor(Date.now() / 1000)
      const pm_list = this.pm_list
      const pm_media = JSON.stringify({
        type: 'image',
        image_link: image_link
      })
      // console.log(pm_media)
      var pm_is_real_name = false
      var user_alias = ''
      var user_serial = ''
      var user_avatar = ''
      for (let i = 0; i < pm_list.length; i++) {
        if (pm_list[i].pm_is_from_me) {
          pm_is_real_name = pm_list[i].pm_is_real_name
          user_alias = pm_list[i].user_alias
          user_serial = pm_list[i].user_serial
          user_avatar = pm_list[i].user_avatar
          break
        }
      }
      this.pm_list.push({
        chat_id: this.chat_id,
        pm_create_time: pm_create_time,
        pm_id: null,
        pm_is_from_me: true,
        pm_is_real_name: pm_is_real_name,
        pm_msg: pm_msg,
        pm_media: pm_media,
        user_avatar: user_avatar,
        user_alias: user_alias,
        user_serial: user_serial
      })
      this.$loading.hide()
      setTimeout(() => {
        var chatBox = document.querySelector('.pm-list-container');
        chatBox.scrollTop = chatBox.scrollHeight;
      }, 50);
      request("/pm/message/send", {
        chat_id: this.chat_id,
        pm_msg: pm_msg,
        pm_media: pm_media
      })
    },
    previewImage(url) {
      this.$hevueImgPreview({
        url: url,
        clickMaskCLose: true,
        controlBar: false
      })
    },
    clickUploadButton() {
      this.$refs.fileInput.click()
    },
    getFile(event) {
      this.$loading.show()
      upload(event.target.files[0], localStorage.getItem('user_school_label'), 'pm')
        .then((image_link) => {
          this.sendImage(image_link)
        })
    },
    copy() {
      const pm = this.selected_pm
      this.selected_pm = null
      try {
        navigator.clipboard.writeText(pm.pm_msg)
        notice({
          content: this.$t('RightChat.copy_success'),
        })
      } catch (e) {
        // console.log(e)
        notice({
          content: this.$t('RightChat.copy_fail'),
        })
      }
    },
    delete() {
      const pm = this.selected_pm
      this.selected_pm = null
      notice({
        content: this.$t('RightChat.delete_success'),
      })
      db.pm.delete(pm.pm_id)
      request("/pm/message/delete", {
        pm_id: pm.pm_id
      })
    },
    rightClick(event, pm) {
      this.selected_pm = pm
      this.$contextmenu({
        items: [
          {
            label: this.$t('RightChat.copy'),
            onClick: () => {
              this.copy()
            }
          },
          {
            label: this.$t('RightChat.delete'),
            onClick: () => {
              this.delete()
            }
          },
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
      return false
    },
    touchStart(event, pm) {
      this.is_long_press = false
      this.timer = setTimeout(() => {
        this.is_long_press = true
        const pointerEvent = new PointerEvent('pointerdown', {
          pointerId: event.touches[0].identifier,
          clientX: event.touches[0].clientX,
          clientY: event.touches[0].clientY,
        });
        this.rightClick(pointerEvent, pm)
      }, 500)
    },
    touchEnd() {
      clearTimeout(this.timer)
    },
    nav2User(pm) {
      if (pm.pm_is_from_me) {
        this.$router.push('/mine')
        return
      }
      if (pm.pm_is_real_name) {
        this.$router.push('/user/' + pm.user_school_label + '/' + pm.user_serial)
        return
      }
    },
    formatTime(e) {
      return formatTime(e)
    }
  }
}
</script>

<style scoped>
.null-container {
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow-y: scroll;
}

.null-title {
  font-size: 2rem;
  font-weight: bold;
  line-height: 3.5rem;
  color: var(--text);
}

.null-info {
  color: var(--text-grey);
}

.pm-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px 70px 16px;
  padding-bottom: calc(70px + env(safe-area-inset-bottom));
  height: calc(100vh - 57.5px);
  height: calc(100vh - 57.5px - env(safe-area-inset-bottom));
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.pm-list-container::-webkit-scrollbar {
  display: none;
}

.pm-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding-top: 17px;
}

.pm-time {
  font-size: 11px;
  color: var(--text-grey);
  margin-bottom: 16px;
}

.pm-main {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  flex-direction: row;
}

.pm-main-me {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  flex-direction: row-reverse;
}

.pm-avatar {
  width: 40px;
  height: 40px;
  margin-top: 3px;
  cursor: pointer;
}

.pm-image {
  /* height: 200px;
  max-width: 100%; */
  height: 200px;
  display: block;
  justify-content: center;
  align-items: center;
}

.pm-image img {
  max-width: 100%;
  max-height: 200px;
  height: auto;
  border-radius: 16px;
  object-fit: contain;
}


.pm-msg {
  max-width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 6px;
}

.pm-msg-me {
  max-width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 6px;
}

.pm-msg-text-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
}

.pm-msg-text-container-me {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;
}

.pm-msg-text {
  line-height: 22px;
  font-size: 16px;
  color: var(--text);
  padding: 9px 10px;
  margin-top: 4px;
  box-sizing: border-box;
  border-radius: 16px;
  word-wrap: break-word;
  word-break: break-all;
  background-color: var(--border-grey);
}

.pm-msg-text-me {
  line-height: 22px;
  font-size: 16px;
  color: #FFFFFF;
  padding: 9px 10px;
  margin-top: 4px;
  box-sizing: border-box;
  border-radius: 16px;
  word-wrap: break-word;
  word-break: break-all;
  background-color: var(--main);
}

.message-bar {
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  z-index: 99;
  width: 100%;
  height: 55px;
  height: calc(55px + env(safe-area-inset-bottom));
  display: flex;
  border-top: 0.1rem solid var(--border-grey);
  background-color: var(--border-grey);
  padding-bottom: env(safe-area-inset-bottom);
}

.message-bar-icon-image {
  background-color: var(--border-grey);
  margin: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.5rem;
  border-radius: 1.25rem;
}

.message-bar-icon-send {
  background-color: var(--main);
  margin: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.5rem;
  border-radius: 1.25rem;
}

.message-bar-icon-image:hover {
  background-color: var(--hover-grey);
  cursor: pointer;
}

.message-bar-icon-send:hover {
  background-color: var(--main-darker);
  cursor: pointer;
}


.input {
  width: calc(100% - 9rem);
  padding: 0.8rem 1rem;
  margin: 0.3rem 0;
  font-size: 1rem;
  color: var(--text);
  border: 0.1rem solid var(--border-grey);
  /* width: calc(100% - 6rem); */
  background-color: var(--bg);
  border-radius: 2rem;
}

.input:focus {
  outline: none;
  border: 0.1rem solid var(--main);
}
</style>