<template>
    <div :class="show ? 'overlay overlay-anim-open' : 'overlay overlay-anim-close'" id="uni-report">
        <div :class="show ? 'report-container report-container-anim-open' : 'report-container report-container-anim-close'">
            <div class="title-container">
                <div class="title">{{ title_txt }}</div>
                <img src="../../assets/delete.svg" class="close-icon" @click="close" />
            </div>

            <textarea class="textarea" :placeholder="placeholder_txt" v-model="report_msg" />
            <div class="bottom-container">
                <div></div>
                <div class="send-button" @click="send">{{ send_txt }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue"
import i18n from "../../locales"
import notice from "../../utils/notice.js"
import request from "../../utils/request.js"

export default {
    name: "UniReport",
    props: {
        show: Boolean,
        uni_post_id: Number,
        comment_msg: String,
        comment_order: Number,
    },
    data() {
        return {
            title_txt: i18n.t('Report.title'),
            send_txt: i18n.t('Report.send'),
            placeholder_txt: i18n.t('Report.placeholder'),
            report_msg: '',
        }
    },
    methods: {
        close() {
            this.report_msg = ''
            this.placeholder_txt = i18n.t('Report.placeholder')
            this.show = false
            let uni_report = document.getElementById('uni-report')
            setTimeout(() => {
                try {
                    uni_report.parentNode.removeChild(uni_report)
                } catch (err) { }
            }, 400)
        },
        send() {
            if (!this.report_msg) {
                notice({
                    content: i18n.t('/post/single/report.501'),
                })
                return
            }
            Vue.$loading.show()
            request("/post/single/report", {
                uni_post_id: this.uni_post_id,
                comment_order: this.comment_order,
                comment_msg: this.comment_msg,
                report_msg: this.report_msg
            })
                .then((res) => {
                    Vue.$loading.hide()
                    if (!res) return
                    notice({
                        content: i18n.t('/post/single/report.200'),
                    })
                    this.close()
                    this.success && this.success()
                })
        },
    }
};
</script>
  
<style scoped>
.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.425);
    align-items: center;
    justify-content: center;
    display: flex;
    -webkit-transition-duration: 10s;
    transition-duration: 10s;
}

@media (prefers-color-scheme: dark) {
    .overlay {
        background-color: rgb(255, 255, 255, 0.425);
    }
}

.report-container {
    display: block;
    padding: 1rem;
    background-color: var(--bg);
    border: 0.1rem solid var(--border-grey);
    width: 80%;
    max-width: 30rem;
    border-radius: 1rem;
}

.title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title {
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    color: var(--text);
}

.close-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.textarea {
    margin: 0.5rem 0;
    padding: 0;
    resize: none;
    width: 100%;
    height: 200px;
    border: none;
    font-size: 15px;
    font-family: "Chirp", sans-serif;
    color: var(--text);
    background-color: var(--bg);
}

.textarea:focus {
    outline: none;
}

.bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.realname-container {
    display: flex;
    flex-direction: row;
}

.realname-info {
    font-size: 14px;
    line-height: 25px;
    color: var(--text-grey);
}

.realname-switch {
    padding: 2.5px 5px;
}

.send-button {
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 20px;
    color: #FFFFFF;
    background-color: var(--main);
}

.send-button:hover {
    background-color: var(--main-darker);
    cursor: pointer;
}

.overlay-anim-open {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: overlay-anim-open;
    animation-name: overlay-anim-open;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

.overlay-anim-close {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: overlay-anim-close;
    animation-name: overlay-anim-close;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

.report-container-anim-open {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: container-anim-open;
    animation-name: container-anim-open;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

.report-container-anim-close {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: container-anim-close;
    animation-name: container-anim-close;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

@-webkit-keyframes container-anim-open {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0, 0, 1);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
    }
}

@keyframes container-anim-open {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0, 0, 1);
        transform: scale3d(0, 0, 1);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@-webkit-keyframes container-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale3d(0.5, 0.5, 1);
    }
}

@keyframes container-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale3d(0.5, 0.5, 1);
        transform: scale3d(0.5, 0.5, 1);
    }
}

@-webkit-keyframes overlay-anim-open {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes overlay-anim-open {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes overlay-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes overlay-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>