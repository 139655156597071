<template>
  <div style="width: 100%;position: relative;">
    <Navbar :title="$t('MiddlePm.chatbot_name')" url="/chat" />
    <div class="pm-list-container">
      <div class="pm-container" v-for="(pm, index) in pm_list" :key="index" @contextmenu.prevent="rightClick($event, pm)">
        <div class="pm-time"
          v-if="index == 0 || pm_list[index].pm_create_time - pm_list[index - 1].pm_create_time > 60 * 5">
          {{ pm.pm_create_time_display }}</div>
        <div :class="pm.pm_is_from_me ? 'pm-main-me' : 'pm-main'">
          <img class="pm-avatar" :src="'https://i.boatonland.com/avatar/' + pm.user_avatar + '.svg'" />
          <div :class="pm.pm_is_from_me ? 'pm-msg-me' : 'pm-msg'">
            <div :class="pm.pm_is_from_me ? 'pm-msg-text-container-me' : 'pm-msg-text-container'">
              <div class="pm-msg-text" v-if="!pm.pm_is_from_me"><span v-html="pm.pm_msg_html" style="max-width: 100%;overflow-x: hidden;"></span></div>
              <div class="pm-msg-text-me" v-else>{{ pm.pm_msg }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="message-bar">
      <!-- <img src="../assets/image.svg" class="message-bar-icon-image"> -->
      <input class="input" v-model="pm_msg" @keyup.enter="send" />
      <img src="../assets/send.svg" class="message-bar-icon-send" @click="send">
    </div>
  </div>
</template>
    
<script>
import Navbar from './Navbar.vue'

import request from '../utils/request.js'
import formatTime from '../utils/formatTime.js'
import notice from '../utils/notice.js'
import marked from '../utils/marked.js';

export default {
  name: 'UniRightChatbot',
  components: { Navbar },
  created() {
    this.getUserInfo()
    this.$loading.show()
    setTimeout(() => {
      var chatBox = document.querySelector('.pm-list-container');
      if (!chatBox) return
      document.querySelector('.message-bar').style.width = chatBox.offsetWidth + 'px'
    }, 50);
    const user_school_label = localStorage.getItem('user_school_label')
    if (user_school_label == "HKU") {
      document.title = this.$t('RightChatbot.title') + " / " + this.$t('AppNameTitle.HKU')
    }
    if (user_school_label == "CUHK") {
      document.title = this.$t('RightChatbot.title') + " / " + this.$t('AppNameTitle.CUHK')
    }
    if (user_school_label == "UST") {
      document.title = this.$t('RightChatbot.title') + " / " + this.$t('AppNameTitle.UST')
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      var chatBox = document.querySelector('.pm-list-container');
      if (!chatBox) return
      document.querySelector('.message-bar').style.width = chatBox.offsetWidth + 'px'
    }, true);
  },
  destroyed() {
    window.removeEventListener('resize', () => {
      var chatBox = document.querySelector('.pm-list-container');
      if (!chatBox) return
      document.querySelector('.message-bar').style.width = chatBox.offsetWidth + 'px'
    }, true);
  },
  data() {
    return {
      is_getting_answer: false,
      pm_list: [],
      user_info: {},
      pm_msg: '',
      selected_pm: null,
      is_long_press: false
    }
  },
  methods: {
    getUserInfo() {
      request("/user/profile/get")
        .then((res) => {
          if (!res) return
          this.user_info = res.user_info
          request("/pm/chatbot/history")
            .then((res2) => {
              if (!res2) return
              const user_alias = this.user_info.user_serial
              const user_serial = this.user_info.user_serial
              const user_avatar = this.user_info.user_avatar
              res2.chatbot_record_list.reverse().forEach(chatbot_record => {
                this.pm_list.push({
                  chat_id: null,
                  pm_create_time: chatbot_record.chatbot_record_create_time,
                  pm_create_time_display: formatTime(chatbot_record.chatbot_record_create_time),
                  pm_id: null,
                  pm_is_from_me: true,
                  pm_is_real_name: true,
                  pm_msg: chatbot_record.chatbot_record_question,
                  pm_media: null,
                  user_avatar: user_avatar,
                  user_alias: user_alias,
                  user_serial: user_serial
                })
                this.pm_list.push({
                  chat_id: null,
                  pm_create_time: chatbot_record.chatbot_record_create_time,
                  pm_create_time_display: formatTime(chatbot_record.chatbot_record_create_time),
                  pm_id: null,
                  pm_is_from_me: false,
                  pm_is_real_name: false,
                  pm_msg: chatbot_record.chatbot_record_answer,
                  pm_msg_html: marked.parse(chatbot_record.chatbot_record_answer),
                  pm_media: null,
                  user_avatar: 'chatbot',
                  user_alias: this.$t('MiddlePm.chatbot_name'),
                  user_serial: this.$t('MiddlePm.chatbot_name')
                })
              });
              this.$loading.hide()
              var chatBox = document.querySelector('.pm-list-container');
              setTimeout(() => {
                chatBox.scrollTop = chatBox.scrollHeight;
              }, 50);
            })
        })
    },
    send() {
      if(this.is_getting_answer) return
      if(!this.pm_msg){
        this.$modal.show({
          content: this.$t('RightChatbot.cannot_be_empty')
        })
        return
      }
      this.is_getting_answer = true

      const pm_msg = this.pm_msg
      const pm_create_time = Math.floor(Date.now() / 1000)
      const user_alias = this.user_info.user_serial
      const user_serial = this.user_info.user_serial
      const user_avatar = this.user_info.user_avatar

      var history = []
      const filter_pm_list = this.pm_list.filter(pm => Math.floor(Date.now() / 1000) - pm.pm_create_time <= 1800);
      filter_pm_list.slice(-10).forEach(pm => {
        if (pm.pm_is_from_me) {
          history.push({ role: 'user', content: pm.pm_msg })
        } else {
          history.push({ role: 'assistant', content: pm.pm_msg })
        }
      });


      this.pm_msg = ''
      this.pm_list.push({
        chat_id: null,
        pm_create_time: pm_create_time,
        pm_create_time_display: formatTime(pm_create_time),
        pm_id: null,
        pm_is_from_me: true,
        pm_is_real_name: true,
        pm_msg: pm_msg,
        pm_media: null,
        user_avatar: user_avatar,
        user_alias: user_alias,
        user_serial: user_serial
      })
      this.pm_list.push({
        chat_id: null,
        pm_create_time: pm_create_time,
        pm_create_time_display: formatTime(pm_create_time),
        pm_id: null,
        pm_is_from_me: false,
        pm_is_real_name: false,
        pm_msg: this.$t('RightChatbot.loading'),
        pm_msg_html: marked.parse(this.$t('RightChatbot.loading')),
        pm_media: null,
        user_avatar: 'chatbot',
        user_alias: this.$t('MiddlePm.chatbot_name'),
        user_serial: this.$t('MiddlePm.chatbot_name')
      })

      var chatBox = document.querySelector('.pm-list-container');
      setTimeout(() => {
        chatBox.scrollTop = chatBox.scrollHeight;
      }, 50)

      const url = 'https://chat.uuunnniii.com?question=' + encodeURIComponent(pm_msg) + '&token=' + localStorage.getItem('token') + '&history=' + JSON.stringify(history)

      var answer = ''

      const eventSource = new EventSource(url)

      eventSource.addEventListener("open", (event) => {
        // console.log("连接已建立", JSON.stringify(event));
      })

      eventSource.addEventListener("message", (event) => {
        // console.log("接收数据：", event);
        try {
          var result = JSON.parse(event.data);
          if (result.content) {
            if(this.pm_list[this.pm_list.length - 1].pm_msg == this.$t('RightChatbot.loading'))this.pm_list[this.pm_list.length - 1].pm_msg = ''
            this.pm_list[this.pm_list.length - 1].pm_msg += result.content
            this.pm_list[this.pm_list.length - 1].pm_msg_html = marked.parse(this.pm_list[this.pm_list.length - 1].pm_msg)
            answer += result.content
            chatBox.scrollTop = chatBox.scrollHeight;
          }
        } catch (error) {
          // console.log(error);
        }
      })

      eventSource.addEventListener("error", (event) => {
        // console.error("发生错误：", JSON.stringify(event));
      })

      eventSource.addEventListener("close", (event) => {
        eventSource.close();
        this.is_getting_answer = false
      })
    },
    copy() {
      const pm = this.selected_pm
      this.selected_pm = null
      try {
        navigator.clipboard.writeText(pm.pm_msg)
        notice({
          content: this.$t('RightChat.copy_success'),
        })
      } catch (e) {
        // console.log(e)
        notice({
          content: this.$t('RightChat.copy_fail'),
        })
      }
    },
    rightClick(event, pm) {
      this.selected_pm = pm
      this.$contextmenu({
        items: [
          {
            label: this.$t('RightChat.copy'),
            onClick: () => {
              this.copy()
            }
          }
        ],
        event,
        customClass: "menu-theme",
        zIndex: 99,
        minWidth: 150
      });
      return false
    },
    touchStart(event, pm) {
      this.is_long_press = false
      this.timer = setTimeout(() => {
        this.is_long_press = true
        const pointerEvent = new PointerEvent('pointerdown', {
          pointerId: event.touches[0].identifier,
          clientX: event.touches[0].clientX,
          clientY: event.touches[0].clientY,
        });
        this.rightClick(pointerEvent, pm)
      }, 500)
    },
    touchEnd() {
      clearTimeout(this.timer)
    },
  }
}
</script>

<style scoped>
.pm-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px 70px 16px;
  padding-bottom: calc(70px + env(safe-area-inset-bottom));
  height: calc(100vh - 57.5px);
  height: calc(100vh - 57.5px - env(safe-area-inset-bottom));
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.pm-list-container::-webkit-scrollbar{
  display: none;
}

.pm-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding-top: 17px;
}

.pm-time {
  font-size: 11px;
  color: var(--text-grey);
  margin-bottom: 16px;
}

.pm-main {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  flex-direction: row;
}

.pm-main-me {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  flex-direction: row-reverse;
}

.pm-avatar {
  width: 40px;
  height: 40px;
  margin-top: 3px;
}

.pm-image {
  /* height: 200px;
  max-width: 100%; */
  height: 200px;
  display: block;
  justify-content: center;
  align-items: center;
}

.pm-image img {
  max-width: 100%;
  max-height: 200px;
  height: auto;
  border-radius: 16px;
  object-fit: contain;
}


.pm-msg {
  max-width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 6px;
}

.pm-msg-me {
  max-width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 6px;
}

.pm-msg-text-container {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
}

.pm-msg-text-container-me {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;
}

.pm-msg-text {
  max-width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  line-height: 22px;
  font-size: 16px;
  color: var(--text);
  padding: 0 10px;
  margin-top: 4px;
  box-sizing: border-box;
  border-radius: 16px;
  word-wrap: break-word;
  word-break: break-all;
  background-color: var(--border-grey);
}

.pm-msg-text::-webkit-scrollbar {
  display: none;
}

.pm-msg-text-me {
  line-height: 22px;
  font-size: 16px;
  color: #FFFFFF;
  padding: 9px 10px;
  margin-top: 4px;
  box-sizing: border-box;
  border-radius: 16px;
  word-wrap: break-word;
  word-break: break-all;
  background-color: var(--main);
}

.message-bar {
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  z-index: 99;
  width: 100%;
  height: 55px;
  height: calc(55px + env(safe-area-inset-bottom));
  display: flex;
  border-top: 0.1rem solid var(--border-grey);
  background-color: var(--border-grey);
  padding-bottom: env(safe-area-inset-bottom);
}

.message-bar-icon-image {
  background-color: var(--border-grey);
  margin: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.5rem;
  border-radius: 1.25rem;
}

.message-bar-icon-send {
  background-color: var(--main);
  margin: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.5rem;
  border-radius: 1.25rem;
}

.message-bar-icon-image:hover {
  background-color: var(--hover-grey);
  cursor: pointer;
}

.message-bar-icon-send:hover {
  background-color: var(--main-darker);
  cursor: pointer;
}


.input {
  width: calc(100% - 4.5rem);
  padding: 0.8rem 1rem;
  margin: 0.3rem 0;
  margin-left: 0.5rem;
  font-size: 1rem;
  color: var(--text);
  border: 0.1rem solid var(--border-grey);
  /* width: calc(100% - 6rem); */
  background-color: var(--bg);
  border-radius: 2rem;
}

.input:focus {
  outline: none;
  border: 0.1rem solid var(--main);
}
</style>