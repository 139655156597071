<template>
    <el-container class="home_container">
        <el-container>
            <el-aside width="80px">

                <el-menu class="el-menu-vertical-demo" :collapse="true" router>

                <el-menu-item index="/home">
                    <i class="el-icon-back"></i>
                    <span slot="title">退出后台</span>
                </el-menu-item>

                <el-menu-item index="/orgadmin/write">
                    <i class="el-icon-s-promotion"></i>
                    <span slot="title">文章发表</span>
                </el-menu-item>

                <el-menu-item index="/orgadmin/profile">
                    <i class="el-icon-menu"></i>
                    <span slot="title">主页定制</span>
                </el-menu-item>
                
                </el-menu>

            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
export default {
    created() {
        const user_school_label = localStorage.getItem('user_school_label')
        if (user_school_label == "HKU") {
            document.title = this.$t('OrgMain.title') + " / " + this.$t('AppNameTitle.HKU')
        }
        if (user_school_label == "CUHK") {
            document.title = this.$t('OrgMain.title') + " / " + this.$t('AppNameTitle.CUHK')
        }
        if (user_school_label == "UST") {
            document.title = this.$t('OrgMain.title') + " / " + this.$t('AppNameTitle.UST')
        }
    },
    data() {
      return {

      }
    },
    methods: {
    }
  }
</script>

<style scoped>
.home_container{
    height: 100%;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

</style>