<template>
    <div :class="show ? 'overlay overlay-anim-open' : 'overlay overlay-anim-close'" id="uni-media">
        <div :class="show ? 'media-container media-container-anim-open' : 'media-container media-container-anim-close'">
            <div class="title-container">
                <div class="title">{{ title_txt }}</div>
                <img src="../../assets/delete.svg" class="close-icon" @click="close" />
            </div>

            <div class="media-info">{{ info_txt }}</div>

            <template v-if="media_type == 'netease' || media_type == 'bilibili'">
                <input class="media-input" :placeholder="placeholder_txt" v-model="media_input" @keyup.enter="getMedia" />
                <div class="media-btn-main" @click="getMedia">
                    <p style="font-weight: 600">{{ button_txt }}</p>
                </div>
            </template>

            <template v-if="media_type == 'quote'">
                <input class="media-input" :placeholder="placeholder_txt_quote_post_id" v-model="post_id_input"
                    @keyup.enter="getQuote" />
                <input class="media-input" :placeholder="placeholder_txt_quote_comment_order" v-model="comment_order_input"
                    @keyup.enter="getQuote" />
                <div class="media-btn-main" @click="getQuote">
                    <p style="font-weight: 600">{{ button_txt }}</p>
                </div>
            </template>

            <template v-if="media_type == 'vote'">

                <div class="vote-outer">
                    <div class="vote-container">
                        <input class="vote-title" :placeholder="vote_title_placeholder_txt" v-model="vote_title">

                        <div class="vote-subtitle" v-if="vote_is_multi">{{ vote_multi_true_txt }}</div>
                        <div class="vote-subtitle" v-else>{{ vote_multi_false_txt }}</div>

                        <div class="vote-option-list" v-for="(option, index) in vote_option_list" :key="index" style="cursor: auto;">

                            <div class="vote-option-lower">
                                <input class="vote-option-lower-text-left" :placeholder="vote_option_placeholder_txt" v-model="option.option_title" />
                                <div class="vote-option-lower-text-right">
                                    <img src="../../assets/delete.svg" @click="removeOption(index)" style="cursor: pointer;" />
                                </div>
                            </div>

                        </div>

                        <div class="vote-option-list" @click="addOption">

                            <div class="vote-option-lower">
                                <div class="vote-option-lower-text-left-add">{{ vote_add_option_txt }}</div>
                                <div class="vote-option-lower-text-right">
                                    <img :src="plus_src" />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="vote-bottom-container">
                    <div class="vote-multi-container" v-if="true">
                        <div class="vote-multi-info">{{ vote_is_multi_txt }}</div>
                        <toggle-button class="vote-multi-switch" :width="40" :height="20" :color="switch_color"
                            v-model="vote_is_multi" />
                    </div>
                    <div v-else></div>
                    <div class="vote-send-button" @click="getVote">{{ button_txt }}</div>
                </div>

            </template>

        </div>
    </div>
</template>
<script>
import { ToggleButton } from 'vue-js-toggle-button'
import Vue from "vue"
import i18n from "../../locales";
import notice from "../../utils/notice.js";
import request from "../../utils/request.js";

export default {
    name: "UniMedia",
    components: { ToggleButton },
    props: {
        show: Boolean,
        media_type: String,
    },
    data() {
        return {
            switch_color: {
                checked: window.getComputedStyle(document.documentElement).getPropertyValue('--main'),
                unchecked: window.getComputedStyle(document.documentElement).getPropertyValue('--hover-grey')
            },
            title_txt: '',
            info_txt: '',
            placeholder_txt: '',
            button_txt: '',
            placeholder_txt_quote_post_id: i18n.t('Media.quote_placeholder_post_id'),
            placeholder_txt_quote_comment_order: i18n.t('Media.quote_placeholder_comment_order'),
            vote_is_multi_txt: i18n.t('Media.vote_multi_true'),
            vote_title_placeholder_txt: i18n.t('Media.vote_title_placeholder'),
            vote_option_placeholder_txt: i18n.t('Media.vote_option_placeholder'),
            vote_multi_true_txt: i18n.t('Media.vote_multi_true'),
            vote_multi_false_txt: i18n.t('Media.vote_multi_false'),
            vote_add_option_txt: i18n.t('Media.vote_add_option'),
            media_input: '',
            post_id_input: '',
            comment_order_input: '',
            vote_title: '',
            vote_is_multi: false,
            vote_option_list: []
        }
    },
    methods: {
        close() {
            this.show = false
            this.media_input = ''
            this.post_id_input = ''
            this.comment_order_input = ''
            this.vote_title = '',
            this.vote_is_multi = false,
            this.vote_option_list = []
            let uni_media = document.getElementById('uni-media')
            setTimeout(() => {
                try {
                    uni_media.parentNode.removeChild(uni_media)
                } catch (err) {}
            }, 400)
        },
        getMedia() {
            if (this.media_type == 'netease') {
                this.getNetease()
            } else if (this.media_type == 'bilibili') {
                this.getBilibili()
            }
        },
        getNetease() {
            Vue.$loading.show()
            request("/post/media/netease/create", {
                netease_link: this.media_input
            })
                .then((res) => {
                    Vue.$loading.hide()
                    if (!res) return
                    this.close()
                    this.success && this.success(res.post_media);
                })
        },
        getBilibili() {
            Vue.$loading.show()
            request("/post/media/bilibili/create", {
                bilibili_bv: this.media_input
            })
                .then((res) => {
                    Vue.$loading.hide()
                    if (!res) return
                    this.close()
                    this.success && this.success(res.post_media);
                })
        },
        getQuote() {
            Vue.$loading.show()
            var post_id = null
            var comment_order = null

            var post_id_input = this.post_id_input
            var comment_order_input = this.comment_order_input

            if (post_id_input.charAt(0) === '#') {
                post_id_input = post_id_input.substring(1);
            }

            post_id = parseInt(post_id_input);
            if (!isNaN(post_id) && post_id.toString() === post_id_input) {
            } else {
                Vue.$loading.hide()
                notice({
                    content: i18n.t('Media.quote_post_id_error'),
                })
                return
            }

            if (comment_order_input == '') {
                comment_order = 'null'
            } else if (comment_order_input == 'g' || comment_order_input == 'G') {
                comment_order = 0
            } else {
                if (comment_order_input.slice(0, 2) === 'LG' || comment_order_input.slice(0, 2) === 'lg' || comment_order_input.slice(0, 2) === 'Lg') {
                    comment_order_input = comment_order_input.substring(2);
                }

                comment_order = parseInt(comment_order_input);
                if (!isNaN(comment_order) && comment_order.toString() === comment_order_input) {
                } else {
                    Vue.$loading.hide()
                    notice({
                        content: i18n.t('Media.quote_comment_order_error'),
                    })
                    return
                }

            }

            request("/post/media/quote/create", {
                post_id: post_id,
                comment_order: comment_order
            })
                .then((res) => {
                    Vue.$loading.hide()
                    if (!res) return
                    this.close()
                    this.success && this.success(res.post_media, res.post_media_show);
                })
        },
        addOption() {
            this.vote_option_list.push({
                option_title: ''
            })
        },
        removeOption(index){
            this.vote_option_list.splice(index, 1);
        },
        getVote() {
            Vue.$loading.show()
            var vote_is_multi = 'false'
            if(this.vote_is_multi){
                vote_is_multi = 'true'
            }
            request("/post/media/vote/create", {
                vote_title: this.vote_title,
                vote_is_multi: vote_is_multi,
                vote_option_list: JSON.stringify(this.vote_option_list)
            })
                .then((res) => {
                    Vue.$loading.hide()
                    if (!res) return
                    this.close()
                    this.success && this.success(res.post_media, res.post_media_show);
                })
        },
    }
};
</script>
  
<style scoped>
.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.425);
    align-items: center;
    justify-content: center;
    display: flex;
}

@media (prefers-color-scheme: dark) {
    .overlay{
        background-color: rgb(255, 255, 255, 0.425);
    }
}

.media-container {
    display: block;
    padding: 1rem;
    background-color: var(--bg);
    border: 0.1rem solid var(--border-grey);
    width: 80%;
    max-width: 30rem;
    border-radius: 1rem;
}

.title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title {
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    color: var(--text);
}

.close-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.media-info {
    font-size: 15px;
    color: var(--text-grey);
    margin: 10px 0;
}

.media-input {
    display: flex;
    margin: 0;
    margin-top: 20px;
    padding: 0.8rem 1rem;
    gap: 0.5rem;
    background-color: var(--bg);
    border-radius: 2rem;
    border: 0.1rem solid var(--border-grey);
    width: calc(100% - 2rem);
    font-size: 1rem;
    color: var(--text);
    text-align: center;
}

.media-input:focus {
    outline: none;
    border: 0.1rem solid var(--main);
}

.media-btn-main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0rem;
    padding: 0.8rem 1rem;
    gap: 0.5rem;
    background-color: var(--main);
    border-radius: 2rem;
}

.media-btn-main p {
    font-size: 1rem;
    color: var(--bg);
    margin: 0;
}

.media-btn-main:hover {
    background-color: var(--main-darker);
    cursor: pointer;
}

.vote-outer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vote-container {
    margin-top: 10px;
    text-align: center;
    background-color: var(--bg);
    border-radius: 16px;
    width: calc(100% - 0.2rem);
    max-width: 350px;
    overflow: hidden;
    border: 0.1rem solid var(--border-grey);
}

.vote-title {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    width: calc(100% - 30px);
    margin: 10px 15px 0 15px;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    color: var(--text);
}

.vote-title:focus {
  outline: none;
}

.vote-subtitle {
    margin: 10px 15px 0 15px;
    text-align: left;
    font-size: 13px;
    color: var(--text);
}

.vote-option-list {
    background-color: var(--bg);
    height: 35px;
    margin: 10px 10px 5px 10px;
    border-radius: 16px;
    position: relative;
    border: 0.1rem solid var(--border-grey);
    cursor: pointer;
}

.vote-option-lower {
    height: 100%;
    width: 100%;
    background-color: var(--bg);
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
}

.vote-option-upper {
    height: 100%;
    width: 0;
    background-color: var(--vote-grey);
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

.vote-option-upper-voted {
    height: 100%;
    width: 0;
    background-color: var(--main);
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

.vote-option-upper-inner {
    width: 100%;
    height: 100%;
    position: relative;
}

.vote-option-lower-text-left {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: var(--text);
    font-size: 15px;
    position: absolute;
    text-align: left;
    left: 15px;
    right: 50px;
    top: 7.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.vote-option-lower-text-left:focus {
  outline: none;
}

.vote-option-lower-text-left-add {
    color: var(--main);
    font-size: 15px;
    position: absolute;
    text-align: left;
    left: 15px;
    right: 50px;
    top: 7.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.vote-option-lower-text-right {
    color: var(--vote-grey);
    font-size: 15px;
    position: absolute;
    right: 15px;
    top: 7.5px;
    white-space: nowrap;
}

.vote-option-lower-text-right img {
    width: 22px;
    height: 22px;
}

.vote-option-lower-text-left-voted {
    color: var(--main);
    font-size: 15px;
    position: absolute;
    text-align: left;
    left: 15px;
    right: 50px;
    top: 7.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.vote-option-lower-text-right-voted {
    color: var(--main);
    font-size: 15px;
    position: absolute;
    right: 15px;
    top: 7.5px;
    white-space: nowrap;
}

.vote-option-upper-text-left {
    color: var(--bg);
    font-size: 15px;
    position: absolute;
    left: 15px;
    top: 7.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.vote-option-upper-text-right {
    color: var(--bg);
    font-size: 15px;
    position: absolute;
    right: 15px;
    top: 7.5px;
    white-space: nowrap;
}

.vote-bottom-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.vote-multi-container {
    display: flex;
    flex-direction: row;
}

.vote-multi-info {
    font-size: 14px;
    line-height: 25px;
    color: var(--text-grey);
}

.vote-multi-switch {
    padding: 2.5px 5px;
}

.vote-send-button {
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 20px;
    color: var(--bg);
    background-color: var(--main);
}

.vote-send-button:hover {
    background-color: var(--main-darker);
    cursor: pointer;
}

.overlay-anim-open {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: overlay-anim-open;
    animation-name: overlay-anim-open;
    -webkit-animation-timing-function: cubic-bezier(0.6,0,0.4,1);
    animation-timing-function: cubic-bezier(0.6,0,0.4,1);
}

.overlay-anim-close {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: overlay-anim-close;
    animation-name: overlay-anim-close;
    -webkit-animation-timing-function: cubic-bezier(0.6,0,0.4,1);
    animation-timing-function: cubic-bezier(0.6,0,0.4,1);
}

.media-container-anim-open {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: container-anim-open;
    animation-name: container-anim-open;
    -webkit-animation-timing-function: cubic-bezier(0.6,0,0.4,1);
    animation-timing-function: cubic-bezier(0.6,0,0.4,1);
}

.media-container-anim-close {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: container-anim-close;
    animation-name: container-anim-close;
    -webkit-animation-timing-function: cubic-bezier(0.6,0,0.4,1);
    animation-timing-function: cubic-bezier(0.6,0,0.4,1);
}

@-webkit-keyframes container-anim-open {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0, 0, 1);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
    }
}

@keyframes container-anim-open {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0, 0, 1);
        transform: scale3d(0, 0, 1);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@-webkit-keyframes container-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale3d(0.5, 0.5, 1);
    }
}

@keyframes container-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale3d(0.5, 0.5, 1);
        transform: scale3d(0.5, 0.5, 1);
    }
}

@-webkit-keyframes overlay-anim-open {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes overlay-anim-open {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes overlay-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes overlay-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>