export let finish_loading = false

export let finishLoading = () => {
    finish_loading = true
}

if (["fullscreen", "standalone", "minimal-ui"].some(
    (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
)) {
    finish_loading = true
}