import request from '../utils/request.js'
import notice from '../utils/notice.js'
import { checkUnreadNotice } from '../utils/unread.js'

import Dexie from 'dexie';
const db_version = 1

const ws_url = "wss://ws.uuunnniii.com:7230"
let webSocket = ''
let getting_chat_list = []
export let db = null

export const launchWebSocket = () => {
    if (!localStorage.getItem('token')) return
    if (!webSocket) {
        // console.log("先获取历史消息")
        getHistoryMessage().then(() => {
            // console.log("然后链接")
            connectWebSocket()
        }).catch(() => {
            connectWebSocket()
        })
    } else {
        var message = {
            type: 'ping',
        }
        if (webSocket) {
            webSocket.send(JSON.stringify(message))
        }
    }
    setTimeout(() => {
        // console.log("每10秒检测一次")
        launchWebSocket()
    }, 10000);
}

export const closeWebsocket = () => {
    if (webSocket) webSocket.close()
    webSocket = ''
}

const connectWebSocket = () => {
    if (document.hidden) return
    if (webSocket) return

    webSocket = new WebSocket(ws_url)

    webSocket.onopen = () => {
        // console.log('WebSocket已连接')
        var message = {
            type: 'bind',
            token: localStorage.getItem('token')
        }
        webSocket.send(JSON.stringify(message))
    }

    webSocket.onmessage = (res) => {
        messageHandler(res.data)
    }

    webSocket.onerror = () => {
        webSocket.close()
        // console.log('出现问题')
        // console.log(res)
    }

    webSocket.onclose = () => {
        // console.log('WebSocket已关闭')
        webSocket = null
    }
}

const messageHandler = (data) => {
    data = JSON.parse(data)
    switch (data.type) {
        case "bind":
            if (data.bind_result) {
                //   console.log("uid绑定成功")
            } else {
                //   console.log("uid绑定失败")
                webSocket.close()
                setTimeout(() => {
                    // console.log("重新连接")
                    connectWebSocket()
                }, 5000)
            }
            break
        case "message":
            var pm_detail = data.content
            // console.log(pm_detail)
            addPmToDb(pm_detail)
            if (pm_detail.pm_is_from_me || window.location.pathname == '/chat' || window.location.pathname == '/chat/' + pm_detail.chat_id) return;
            notice({
                title: pm_detail.user_alias,
                content: pm_detail.pm_msg,
                user_avatar: pm_detail.user_avatar,
                url: '/chat/' + pm_detail.chat_id
            })
            break
        case "notice":
            var notice_detail = data.content
            var push_notice_data = {
                content: notice_detail.notice_msg,
                notice_id: notice_detail.notice_id
            }
            if (notice_detail.notice_data.uni_post_id) {
                if (window.location.pathname == '/post/' + notice_detail.notice_data.uni_post_id) return;
                Object.assign(push_notice_data, { url: '/post/' + notice_detail.notice_data.uni_post_id });
            }
            notice(push_notice_data)
            checkUnreadNotice()
            break
    }
}

const initDatabase = () => {
    const user_itsc = localStorage.getItem('user_itsc')
    const user_school_label = localStorage.getItem('user_school_label')
    db = new Dexie(user_itsc + '@' + user_school_label)
    db.version(db_version).stores({
        pm: "&pm_id, chat_id, pm_create_time",
        chat: "&chat_id, chat_update_time"
    })
}

const getHistoryMessage = () => {
    return new Promise(function (resolve, reject) {
        initDatabase()
        db.pm.orderBy("pm_id").reverse().limit(1).toArray()
            .then((res) => {
                var latset_pm_id_list = res
                var latset_pm_id = 0
                if (latset_pm_id_list[0]) {
                    latset_pm_id = latset_pm_id_list[0].pm_id
                }
                // console.log("latset_pm_id为" + latset_pm_id)
                request("/pm/message/history", {
                    pm_id: latset_pm_id
                }).then(res => {
                    if (!res) {
                        reject()
                        return
                    }
                    var pm_list = res.pm_list
                    pm_list.forEach(item => {
                        addPmToDb(item)
                    })
                    resolve()
                })
            })

    })
}

const addPmToDb = (item) => {
    db.pm.where("pm_id").equals(parseInt(item.pm_id)).toArray()
        .then((res) => {
            var pm_list = res
            if (pm_list[0]) return
            db.pm.add(item)
            db.chat.where("chat_id").equals(parseInt(item.chat_id)).limit(1).toArray()
                .then((res) => {
                    var chat_list = res
                    if (!chat_list[0]) {
                        addChatToDb(item.chat_id, !item.pm_is_from_me)
                    } else {
                        var chat_detail = chat_list[0]
                        chat_detail.chat_latest_msg = item.pm_msg
                        chat_detail.chat_update_time = item.pm_create_time
                        if (!item.pm_is_from_me && window.location.pathname != '/chat/' + item.chat_id) {
                            chat_detail.chat_unread_count += 1
                        }
                        if (window.location.pathname == '/chat/' + item.chat_id) {
                            setTimeout(() => {
                                var chatBox = document.querySelector('.pm-list-container');
                                chatBox.scrollTop = chatBox.scrollHeight + 9999
                            }, 100);
                        }
                        db.chat.put(chat_detail)
                    }
                })
        })
}

const addChatToDb = (chat_id, is_unread) => {
    if (getting_chat_list.includes(chat_id)) return
    getting_chat_list.push(chat_id)
    request("/pm/chat/get", {
        chat_id: chat_id
    }, true, [400]).then((res) => {
        if (!res) return
        if (res.code == 400) return
        var chat_detail = res.chat_detail
        if (is_unread) {
            chat_detail.chat_unread_count = 0
        } else {
            chat_detail.chat_unread_count = 1
        }
        db.chat.where("chat_id").equals(parseInt(chat_id)).limit(1).toArray()
            .then((res) => {
                var chat_list = res
                if (!chat_list[0]) {
                    db.chat.add(chat_detail)
                }
            })
    })

}