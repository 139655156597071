<template>
    <div>
        <div class="topic-container">
            <div class="topic-title">{{ $t('RightWrite.choose_topic') }}</div>
            <div class="topic-list">
                <div v-for="(topic, index) in topic_list" :key="index"
                    :class="(topic.topic == post_topic) ? 'topic-item-active' : 'topic-item'" @click="changeTopic(topic)">
                    {{ topic.topic_show }}
                </div>
            </div>
        </div>

        <div class="option-container">
            <div class="option-item">
                <div class="option-item-title">{{ $t('RightWrite.public') }}</div>
                <toggle-button class="option-title-switch" :width="40" :height="20" :color="switch_color"
                    v-model="post_is_public" @change="changePublic" />
            </div>
            <div class="option-item">
                <div class="option-item-title">{{ $t('RightWrite.realname') }}</div>
                <toggle-button class="option-title-switch" :width="40" :height="20" :color="switch_color"
                    v-model="user_is_real_name" @change="changeRealname" />
            </div>
            <div class="option-item">
                <div class="option-item-title">{{ $t('RightWrite.uni') }}</div>
                <toggle-button class="option-title-switch" :width="40" :height="20" :color="switch_color"
                    v-model="post_is_uni" @change="changeUni" />
            </div>
            <div class="option-item">
                <div class="option-item-title">{{ $t('RightWrite.markdown') }}</div>
                <toggle-button class="option-title-switch" :width="40" :height="20" :color="switch_color"
                    v-model="post_is_markdown" @change="changeMarkdown" />
            </div>
        </div>

        <div class="send-button-group">
            <div class="send-button-sub-vice" @click="setMedia">{{ $t('RightWrite.insert') }}</div>
            <div class="send-button-sub-main" @click="post">{{ $t('RightWrite.post') }}</div>
        </div>

    </div>
</template>
      
<script>
import request from '../utils/request.js'
import { localizeTopic } from '../utils/localize';
import { ToggleButton } from 'vue-js-toggle-button'

export default {
    name: 'UniRightWrite',
    components: { ToggleButton },
    created() {
        this.getTopicList()
    },
    data() {
        return {
            post_topic: '',
            post_topic_show: '',
            topic_list: [],
            post_is_public: true,
            post_public: 1,
            user_is_real_name: false,
            post_is_uni: false,
            post_is_markdown: false,
            switch_color: {
                checked: window.getComputedStyle(document.documentElement).getPropertyValue('--main'),
                unchecked: window.getComputedStyle(document.documentElement).getPropertyValue('--hover-grey')
            }
        }
    },
    methods: {
        getTopicList() {
            request("/info/posttopic")
                .then((res) => {
                    if (!res) return
                    this.topic_list = res.topic_list.map(topic => {
                        return { topic: topic, topic_show: localizeTopic(topic) };
                    })
                })
        },
        changeTopic(topic) {
            this.post_topic = topic.topic
            this.post_topic_show = topic.topic_show
            window.dispatchEvent(new CustomEvent("writeChangeTopic", {
                detail: { post_topic: this.post_topic, post_topic_show: this.post_topic_show }
            }))
        },
        changePublic(e) {
            if (e.value) {
                this.post_public = 1
            } else {
                this.post_public = 2
            }
            window.dispatchEvent(new CustomEvent("writeChangePublic", {
                detail: { post_public: this.post_public }
            }))
        },
        changeRealname() {
            window.dispatchEvent(new CustomEvent("writeChangeRealname", {
                detail: { user_is_real_name: this.user_is_real_name }
            }))
        },
        changeUni() {
            window.dispatchEvent(new CustomEvent("writeChangeUni", {
                detail: { post_is_uni: this.post_is_uni }
            }))
        },
        changeMarkdown() {
            window.dispatchEvent(new CustomEvent("writeChangeMarkdown", {
                detail: { post_is_markdown: this.post_is_markdown }
            }))
        },
        setMedia(event) {
            this.$contextmenu({
                items: [
                    {
                        label: this.$t('MiddleWrite.media_image'),
                        onClick: () => {
                            window.dispatchEvent(new CustomEvent("writeChangeMedia", {
                                detail: { media_type: "image" }
                            }))
                        }
                    },
                    {
                        label: this.$t('MiddleWrite.media_netease'),
                        onClick: () => {
                            window.dispatchEvent(new CustomEvent("writeChangeMedia", {
                                detail: { media_type: "netease" }
                            }))
                        }
                    },
                    {
                        label: this.$t('MiddleWrite.media_bilibili'),
                        onClick: () => {
                            window.dispatchEvent(new CustomEvent("writeChangeMedia", {
                                detail: { media_type: "bilibili" }
                            }))
                        }
                    },
                    {
                        label: this.$t('MiddleWrite.media_vote'),
                        onClick: () => {
                            window.dispatchEvent(new CustomEvent("writeChangeMedia", {
                                detail: { media_type: "vote" }
                            }))
                        }
                    },
                    {
                        label: this.$t('MiddleWrite.media_quote'),
                        onClick: () => {
                            window.dispatchEvent(new CustomEvent("writeChangeMedia", {
                                detail: { media_type: "quote" }
                            }))
                        }
                    },
                    {
                        label: this.$t('MiddleWrite.media_file'),
                        onClick: () => {
                            window.dispatchEvent(new CustomEvent("writeChangeMedia", {
                                detail: { media_type: "file" }
                            }))
                        }
                    }
                ],
                event,
                customClass: "menu-theme",
                zIndex: 99,
                minWidth: 150
            });
        },
        post() {
            window.dispatchEvent(new Event("writePost"))
        }
    }
}
</script>

<style scoped>
.topic-container {
    margin: 20px;
    padding: 20px 20px 10px 20px;
    border-radius: 16px;
    border: 0.1rem solid var(--border-grey);
}

.topic-title {
    font-size: 16px;
    color: var(--text);
    font-weight: bold;
}

.topic-list {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.topic-item {
    padding: 10px 15px;
    margin: 5px 10px;
    border: 2px solid var(--border-grey);
    border-radius: 20px;
    font-size: 16px;
    line-height: 20px;
    color: var(--text);
    cursor: pointer;
}

.topic-item-active {
    padding: 10px 15px;
    margin: 5px 10px;
    border: 2px solid var(--main);
    border-radius: 20px;
    font-size: 16px;
    line-height: 20px;
    color: var(--text);
}

.option-container {
    margin: 20px;
    padding: 0 20px;
    border-radius: 16px;
    border: 0.1rem solid var(--border-grey);
}

.option-item {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.option-item-title {
    font-size: 16px;
    line-height: 20px;
    color: var(--text);
}

.option-item-switch {
    padding: 2.5px 5px;
}

.send-button-group {
    margin: 20px 0;
    display: flex;
}

.send-button-sub-vice {
    flex: 1;
    padding: 15px 0;
    margin: 0 20px;
    border-radius: 25px;
    background-color: var(--border-grey);
    font-size: 16px;
    line-height: 20px;
    color: var(--text);
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}

.send-button-sub-vice:hover {
    background-color: var(--bg-grey);
}

.send-button-sub-main {
    flex: 1;
    padding: 15px 0;
    margin: 0 20px;
    border-radius: 25px;
    background-color: var(--main);
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}

.send-button-sub-main:hover {
    background-color: var(--main-darker);
}

.send-button {
    margin: 20px;
    padding: 15px 0;
    border-radius: 25px;
    background-color: var(--main);
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}

.send-button:hover {
    background-color: var(--main-darker);
}
</style>