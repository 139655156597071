<template>
  <div>
    <h1>主页定制</h1>
    <el-form label-width="80px" :model="postForm" label-position="left" :rules="postFormRules" ref="postFormRef">

      <el-form-item label="组织名称" prop="org_name">
        <el-input v-model="postForm.org_name" placeholder="该项不可修改" :disabled="true" @input="redraw"></el-input>
      </el-form-item>

      <el-form-item label="简介" prop="org_intro">
        <el-input type="textarea" v-model="postForm.org_intro" @input="redraw"></el-input>
      </el-form-item>

      <el-form-item label="头像" prop="org_avatar">
        <el-image style="height: 100px" :src="postForm.org_avatar" :preview-src-list="[postForm.org_avatar]" v-if="postForm.org_avatar">
        </el-image>
        <el-upload class="upload-demo" action="#" accept=".png,.jpg,.jpeg" :limit="1" drag
          :http-request="uploadAvatar" :show-file-list="false">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">比例为1:1</div>
        </el-upload>
      </el-form-item>

      <el-form-item label="背景图" prop="org_bg_image">
        <el-image style="height: 100px" :src="postForm.org_bg_image" :preview-src-list="[postForm.org_bg_image]" v-if="postForm.org_bg_image">
        </el-image>
        <el-upload class="upload-demo" action="#" accept=".png,.jpg,.jpeg" :limit="1" drag
          :http-request="uploadBgImage" :show-file-list="false">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">比例为3:4</div>
        </el-upload>
      </el-form-item>

      <el-button type="primary" @click="addBanner" style="margin: 40px 20px 20px 0;">新增轮播图</el-button>

      <el-form-item label="轮播图" prop="org_banner" v-for="(item, index) in postForm.org_banner" :key="'banner' + index" style="margin-bottom: 20px;;">
        <el-image style="height: 100px" :src="item.banner_image" :preview-src-list="[item.banner_image]" v-if="item.banner_image">
        </el-image>
        <el-button type="danger" @click="deleteBanner(index)" style="margin-left: 20px;">删除</el-button>
        <el-upload class="upload-demo" action="#" accept=".png,.jpg,.jpeg" :limit="1" drag
          :http-request="(e) => uploadBanner(e, index)" :show-file-list="false">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">比例为2.35:1</div>
        </el-upload>

        <el-form-item label="链接类型">
          <el-select v-model="item.banner_type" placeholder="点击轮播图触发的链接" @change="redraw">
            <el-option label="公众号文章" value="article"></el-option>
            <el-option label="树洞" value="post"></el-option>
            <el-option label="无" value="none"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文章链接" v-if="item.banner_type == 'article'">
          <el-input v-model="item.article_link" placeholder="https://mp.weixin.qq.com/s/ 开头的链接"
            @input="redraw"></el-input>
        </el-form-item>
        <el-form-item label="UNI树洞号" v-if="item.banner_type == 'post'">
          <el-input v-model="item.uni_post_id" placeholder="UNI树洞号在地址栏查看：https://tripleuni.com/post/{UNI树洞号}" @input="redraw"></el-input>
        </el-form-item>
      </el-form-item>

      <el-button type="primary" @click="addFunction" style="margin: 40px 0 20px 0;">新增功能</el-button>

      <el-form-item label="功能" prop="org_model_half" v-for="(item, index) in postForm.org_function" :key="'function' + index">
        <el-button type="danger" @click="deleteFunction(index)">删除</el-button>

        <el-form-item label="功能名称">
          <el-input v-model="item.function_title" placeholder="功能名称，显示在社团主页" @input="redraw"></el-input>
        </el-form-item>

        <el-form-item label="功能类型">
          <el-select v-model="item.function_type" placeholder="请选择功能类型" @change="redraw">
            <el-option label="公众号文章" value="article"></el-option>
            <el-option label="树洞" value="post"></el-option>
            <el-option label="私信" value="pm"></el-option>
            <el-option label="二级菜单" value="list"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="文章链接" v-if="item.function_type == 'article'">
          <el-input v-model="item.article_link" placeholder="https://mp.weixin.qq.com/s/ 开头的链接"
            @input="redraw"></el-input>
        </el-form-item>

        <el-form-item label="UNI树洞号" v-if="item.function_type == 'post'">
          <el-input v-model="item.uni_post_id" placeholder="UNI树洞号在地址栏查看：https://tripleuni.com/post/{UNI树洞号}" @input="redraw"></el-input>
        </el-form-item>

        <el-form-item label="二级菜单" v-if="item.function_type == 'list'">
          <el-button type="primary" @click="addSecondFunction(index)">新增二级选项</el-button>
          <el-form-item label="二级选项" prop="org_model_half" v-for="(item2, index2) in item.function_list" :key="'subfunction' + index2">
            <el-button type="danger" @click="deleteSecondFunction(index, index2)">删除</el-button>
            <el-form-item label="功能名称">
              <el-input v-model="item2.function_title" placeholder="功能名称，显示在二级菜单" @input="redraw"></el-input>
            </el-form-item>
            <el-form-item label="功能类型">
              <el-select v-model="item2.function_type" placeholder="请选择功能类型" @change="redraw">
                <el-option label="公众号文章" value="article"></el-option>
                <el-option label="树洞" value="post"></el-option>
                <el-option label="私信" value="pm"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文章链接" v-if="item2.function_type == 'article'">
              <el-input v-model="item2.article_link" placeholder="https://mp.weixin.qq.com/s/ 开头的链接"
                @input="redraw"></el-input>
            </el-form-item>
            <el-form-item label="UNI树洞号" v-if="item2.function_type == 'post'">
              <el-input v-model="item2.uni_post_id" placeholder="UNI树洞号在地址栏查看：https://tripleuni.com/post/{UNI树洞号}" @input="redraw"></el-input>
            </el-form-item>
          </el-form-item>
        </el-form-item>
      </el-form-item>

    </el-form>
    <el-button type="primary" style="margin-top: 40px" @click="post">保存</el-button>
  </div>
</template>

<script>
import request from '../utils/request.js'
import notice from '../utils/notice.js'
import { upload } from '../utils/upload.js'

export default {
  created() {
    this.getOrgInfo()
  },
  data() {
    return {
      postForm: {
        org_name: "",
        org_intro: "",
        org_avatar: "",
        org_bg_image: "",
        org_banner: [],
        org_function: []
      },
      postFormRules: {
        org_name: [
          { required: true, message: "请输入组织名称", trigger: "blur" },
        ],
        org_intro: [
          { required: true, message: "请输入简介", trigger: "blur" },
        ],
        org_avatar: [
          { required: true, message: "请上传头像", trigger: "blur" },
        ],
        org_bg_image: [
          { required: true, message: "请上传背景图", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    getOrgInfo() {
      this.$loading.show()
      request("/org/profile/get")
        .then((res) => {
          this.$loading.hide()
          if (!res) return
          this.postForm.org_name = res.org_info.org_name
          this.postForm.org_intro = res.org_info.org_intro
          this.postForm.org_avatar = res.org_info.org_avatar
          this.postForm.org_bg_image = res.org_info.org_bg_image
          this.postForm.org_banner = res.org_info.org_banner
          this.postForm.org_function = res.org_info.org_function
        })
    },
    post() {
      this.$loading.show()
      request("/org/profile/update", {
        org_name: this.postForm.org_name,
        org_intro: this.postForm.org_intro,
        org_avatar:  this.postForm.org_avatar,
        org_bg_image:  this.postForm.org_bg_image,
        org_banner:  JSON.stringify(this.postForm.org_banner),
        org_function:  JSON.stringify(this.postForm.org_function)
      })
        .then((res) => {
          this.$loading.hide()
          if (!res) return
          notice({
            content: this.$t('/org/profile/update.200'),
          })
        })
    },
    addBanner() {
      if (!this.postForm.org_banner) this.postForm.org_banner = []
      if (this.postForm.org_banner.length < 6) {
        this.postForm.org_banner.push({
          banner_title: '',
          banner_image: '',
          banner_type: ''
        })
      } else {
        notice({
          content: "轮播图最多6个",
        })
      }
      this.$forceUpdate()
    },
    deleteBanner(index) {
      this.postForm.org_banner.splice(index, 1)
      this.$forceUpdate()
    },
    addFunction() {
      if (!this.postForm.org_function) this.postForm.org_function = []
      if (this.postForm.org_function.length < 5) {
        this.postForm.org_function.push({
          function_title: '',
          function_type: '',
        })
      } else {
        notice({
          content: "功能最多5个",
        })
      }
      this.$forceUpdate()
    },
    deleteFunction(index) {
      this.postForm.org_function.splice(index, 1)
      this.$forceUpdate()
    },
    addSecondFunction(index) {
      if (!this.postForm.org_function[index].function_list) this.postForm.org_function[index].function_list = []
      if (this.postForm.org_function[index].function_list.length < 6) {
        this.postForm.org_function[index].function_list.push({
          function_title: '',
          function_type: '',
        })
      } else {
        notice({
          content: "二级选项最多6个",
        })
      }
      this.$forceUpdate()
    },
    deleteSecondFunction(index, index2) {
      this.postForm.org_function[index].function_list.splice(index2, 1)
      this.$forceUpdate()
    },
    uploadAvatar(e) {
      this.$loading.show()
      upload(e.file, localStorage.getItem('user_school_label'), 'org')
        .then((image_link) => {
          this.postForm.org_avatar = image_link
          this.$loading.hide()
          return false
        })
    },
    uploadBgImage(e) {
      this.$loading.show()
      upload(e.file, localStorage.getItem('user_school_label'), 'org')
        .then((image_link) => {
          this.postForm.org_bg_image = image_link
          this.$loading.hide()
          return false
        })
    },
    uploadBanner(e, index) {
      this.$loading.show()
      upload(e.file, localStorage.getItem('user_school_label'), 'org')
        .then((image_link) => {
          this.postForm.org_banner[index].banner_image = image_link
          this.$loading.hide()
        })
    },
    redraw() {
      this.$forceUpdate();
    }
  },
};
</script>

<style scoped>
.el-table .cell {
  padding-right: 0;
}

.el-select .el-input {
  width: 200px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>