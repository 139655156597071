import Vue from 'vue'
import { Button, Form, FormItem, Input, Container, Aside, Main, Menu, MenuItem, Select, Option, Upload, Switch, Image } from 'element-ui'
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Upload)
Vue.use(Switch)
Vue.use(Image)