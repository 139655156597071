<template>
    <div>
        <div class="body" v-if="!is_login">
            <div class="prompt-container">
                <div class="prompt-inner">
                    <div class="title_container">
                        <h2>{{ $t('MiddleCUCampus.title') }}</h2>
                    </div>
                    <p><span style="font-weight: bold;">{{ $t('MiddleCUCampus.cucampus') }}</span>
                        {{ $t('MiddleCUCampus.introduction') }}
                    </p>
                    <p>{{ $t('MiddleCUCampus.disclaimer_1') }}
                        <span style="font-weight: bold; text-decoration: underline; cursor: pointer;"
                            @click="nav2Agreement">{{ $t('MiddleCUCampus.disclaimer_agreement') }}</span>
                        {{ $t('MiddleCUCampus.disclaimer_2') }}
                        <span style="font-weight: bold; text-decoration: underline; cursor: pointer;"
                            @click="nav2Privacy">{{ $t('MiddleCUCampus.disclaimer_privacy') }}</span>
                        {{ $t('MiddleCUCampus.disclaimer_3') }}
                    </p>
                    <div class="prompt-button-group">
                        <div class="prompt-button-sub-vice" @click="navBack">{{ $t('MiddleCUCampus.disagree') }}</div>
                        <div class="prompt-button-sub-main" @click="visit">{{ $t('MiddleCUCampus.agree') }}</div>
                    </div>
                </div>

            </div>
        </div>
        <iframe :src="iframe_url" id="iframe"></iframe>
    </div>
</template>

<script>
import Navbar from './Navbar.vue'

export default {
    name: 'UniMiddleCUCampus',
    components: { Navbar },
    created() {
        if (this.$route.params.url) {
            this.url = this.$route.params.url
        } else {
            this.url = ''
        }
        this.iframe_url = 'https://cucampus.one/' + this.url
        console.log(this.iframe_url)
        const user_school_label = localStorage.getItem('user_school_label')
        if (user_school_label != 'CUHK') this.$router.push('/home')
        const user_is_org = localStorage.getItem('user_is_org')
        if (user_is_org) this.$router.push('/home')

        if (localStorage.getItem('cu_campus_is_login')) {
            this.is_login = true

            let current_timestamp = new Date().getTime()
            current_timestamp = Math.floor(current_timestamp / 1000)
            let login_timestamp = localStorage.getItem('cu_campus_login_timestamp')

            if (!login_timestamp || current_timestamp - login_timestamp > 604800) {
                this.iframe_url = 'https://cucampus.one/login?token=' + localStorage.getItem('token') + '&callback=' + this.url
                console.log(this.iframe_url)
                localStorage.setItem('cu_campus_login_timestamp', current_timestamp)
            }
        }
    },
    data() {
        return {
            is_login: false,
            iframe_url: 'https://cucampus.one/',
            url: ''
        }
    },
    methods: {
        visit() {
            const user_school_label = localStorage.getItem('user_school_label')
            if (user_school_label != 'CUHK') {
                this.$modal.show({
                    title: this.$t('Modal.title'),
                    content: this.$t('MiddleCUCampus.prompt_only_cuhk'),
                    show_cancel: false,
                    success: () => {
                        this.$router.push('/home')
                    }
                })
                return
            }
            const user_is_org = localStorage.getItem('user_is_org')
            if (user_is_org) {
                this.$modal.show({
                    title: this.$t('Modal.title'),
                    content: this.$t('MiddleCUCampus.prompt_no_org'),
                    show_cancel: false,
                    success: () => {
                        this.$router.push('/home')
                    }
                })
                return
            }
            this.is_login = true
            this.iframe_url = 'https://cucampus.one/login?token=' + localStorage.getItem('token') + '&callback=/' + this.url
            console.log(this.iframe_url)
            localStorage.setItem('cu_campus_is_login', true)

            let current_timestamp = new Date().getTime()
            current_timestamp = Math.floor(current_timestamp / 1000)
            localStorage.setItem('cu_campus_login_timestamp', current_timestamp)
        },
        navBack() {
            if (window.history.length <= 1) {
                this.$router.push('/home')
            } else {
                this.$router.back()
            }
        },
        nav2Agreement() {
            window.open('https://terms.tripleuni.com/CUCampus/agreement')
        },
        nav2Privacy() {
            window.open('https://terms.tripleuni.com/CUCampus/privacy')
        }
    }
}
</script>

<style scoped>
iframe {
    width: 100%;
    height: 100vh;
    height: calc(100vh - env(safe-area-inset-bottom));
    border: none;
    margin: 0;
    display: flex;
}

.body {
    position: absolute;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    color: var(--text);
    margin: 0;
    font-family: "Chirp", sans-serif;
    overflow-x: hidden;
    display: flex;
    height: 100vh;
    height: calc(100vh - env(safe-area-inset-bottom));
    width: 100%;
    align-items: center;
}

.prompt-container {
    display: block;
    padding: 1rem;
    background-color: var(--bg);
    box-shadow: 0px 0px 10px var(--bg-grey);
    border-radius: 1rem;
    width: 80%;
    max-width: 30rem;
    margin: 1rem auto;
}

.title_container {
    width: 100%;
    border-bottom: 0.1rem solid var(--border-grey);
}

.prompt-container p {
    color: var(--text);
    font-size: 1rem;
    line-height: 1.5rem;
}

.prompt-button-group {
    margin-top: 30px;
    display: flex;
}

.prompt-button-sub-vice {
    flex: 1;
    padding: 15px 0;
    margin: 0 15px;
    margin-left: 0;
    border-radius: 25px;
    background-color: var(--border-grey);
    font-size: 16px;
    line-height: 20px;
    color: var(--text);
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}

.prompt-button-sub-vice:hover {
    background-color: var(--bg-grey);
}

.prompt-button-sub-main {
    flex: 1;
    padding: 15px 0;
    margin: 0 15px;
    margin-right: 0;
    border-radius: 25px;
    background-color: var(--main);
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}

.prompt-button-sub-main:hover {
    background-color: var(--main-darker);
}

.prompt-button {
    margin: 20px;
    padding: 15px 0;
    border-radius: 25px;
    background-color: var(--main);
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}

.prompt-button:hover {
    background-color: var(--main-darker);
}

@media only screen and (max-width: 700px) {
    iframe {
        height: calc(100vh - 55px);
        height: calc(100vh - 55px - env(safe-area-inset-bottom));
    }

    .body {
        height: calc(100vh - 55px);
        height: calc(100vh - 55px - env(safe-area-inset-bottom));
    }

    .prompt-container {
        width: calc(100vw - 3rem);
        height: calc(100vh - 3rem);
        height: calc(100vh - 3rem - env(safe-area-inset-bottom));
        margin: 0;
        border: none;
        padding: 1.5rem;
        border-radius: 0;
        max-width: none;
        align-items: center;
        display: flex;
    }

    .prompt-inner {
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 450px;
        padding-bottom: 100px;
    }
}
</style>