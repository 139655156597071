import i18n from '../locales'

export default function formatTime(timestamp) {
    var s = new Date(timestamp * 1000);
    var today = new Date();
    var day_diff = today.setHours(0, 0, 0, 0) - s.setHours(0, 0, 0, 0)
    s = new Date(timestamp * 1000);
    if (day_diff == 0) {
        return i18n.t('Time.today') + " " + String(s.getHours()).padStart(2, "0") + ":" + String(s.getMinutes()).padStart(2, "0");
    } else if (day_diff == 86400000) {
        return i18n.t('Time.yesterday') + " " + String(s.getHours()).padStart(2, "0") + ":" + String(s.getMinutes()).padStart(2, "0");
    } else if (day_diff == 172800000) {
        return i18n.t('Time.the_day_before_yesterday') + " " + String(s.getHours()).padStart(2, "0") + ":" + String(s.getMinutes()).padStart(2, "0");
    } else {
        return (s.getYear() + 1900) + "-" + (s.getMonth() + 1) + "-" + s.getDate() + " " + String(s.getHours()).padStart(2, "0") + ":" + String(s.getMinutes()).padStart(2, "0");
    }
}

export function formatTimeToDisplay(timestamp) {
    const dateObj = new Date(timestamp * 1000)
    const year = dateObj.getFullYear()
    const month = String(dateObj.getMonth() + 1).padStart(2, "0")
    const day = String(dateObj.getDate()).padStart(2, "0")
    const hour = String(dateObj.getHours()).padStart(2, "0")
    const minute = String(dateObj.getMinutes()).padStart(2, "0")
    const second = String(dateObj.getSeconds()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`
    return formattedDate
}

export function formatTimeToDate(timestamp) {
    const dateObj = new Date(timestamp * 1000)
    const year = dateObj.getFullYear()
    const month = String(dateObj.getMonth() + 1).padStart(2, "0")
    const day = String(dateObj.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
}