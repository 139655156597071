import Vue from 'vue'
import request from './request.js'
import notice from './notice.js'
import i18n from '../locales'

export const subscribeWebpush = (show_notice = false) => {
    window.notificationRegistration
        .pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(
                'BNhUZY4dfgi1It6k03kxGSIiQp-TAQnLuPE6QqfwWyH3tc9xBWXnGvAQ-r8Q5Py7NW_89RdbusOYv60jPYyiOxk'
            ),
        })
        .then((pushSubscription) => {
            if (show_notice) Vue.$loading.show()
            const pushSubscriptionData = JSON.parse(JSON.stringify(pushSubscription))

            request("/notice/webpush/subscribe", {
                webpush_endpoint: pushSubscriptionData.endpoint,
                webpush_p256dh: pushSubscriptionData.keys.p256dh,
                webpush_auth: pushSubscriptionData.keys.auth,
            })
                .then((res) => {
                    if (show_notice) Vue.$loading.hide()
                    if (!res) return
                    if (show_notice) {
                        notice({
                            content: i18n.t('Webpush.success')
                        })
                    }
                })
        })
}

export const unsubscribeWebpush = (token) => {
    window.notificationRegistration
        .pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(
                'BNhUZY4dfgi1It6k03kxGSIiQp-TAQnLuPE6QqfwWyH3tc9xBWXnGvAQ-r8Q5Py7NW_89RdbusOYv60jPYyiOxk'
            ),
        })
        .then((pushSubscription) => {
            const pushSubscriptionData = JSON.parse(JSON.stringify(pushSubscription))
            request("/notice/webpush/unsubscribe", {
                webpush_endpoint: pushSubscriptionData.endpoint,
                token: token
            }, false)
        })
}

const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}