import Vue from "vue";
import componentModal from "./Modal.vue";
import i18n from "../../locales";

const comModal = Vue.extend(componentModal);

const instance = new comModal({
    el: document.createElement("div"),
});

instance.show = false

import { finish_loading } from '../../utils/splash.js'
instance.finish_loading = finish_loading
window.addEventListener('finishInitLoading', () => {
    instance.finish_loading = true
})

const modal = {
    show(e) {
        instance.show = true
        instance.title = e.title || i18n.t('Modal.title');
        instance.content = e.content || i18n.t('Modal.content');
        instance.show_cancel = e.show_cancel;
        instance.success = e.success || null;
        instance.confirm_txt = e.confirm_txt || i18n.t('Modal.confirm');
        instance.cancel_txt = e.cancel_txt || i18n.t('Modal.cancel');
        instance.is_terms = e.is_terms || false;
        instance.startWatch()
        document.body.appendChild(instance.$el);
    },
};
export default {
    install() {
        if (!Vue.$modal) {
            Vue.$modal = modal;
        }
        Vue.mixin({
            created() {
                this.$modal = Vue.$modal;
            },
        });
    },
};
