<template>
    <div :class="show ? 'overlay overlay-anim-open' : 'overlay overlay-anim-close'" id="uni-modal" v-show="finish_loading">
        <div :class="show ? 'modal-container modal-container-anim-open' : 'modal-container modal-container-anim-close'">
            <h3>{{ title }}</h3>
            <p v-if="!is_terms">{{ content }}</p>
            <p v-else>{{ terms_1 }}<span class="terms" @click="nav2Agreement">{{ terms_agreement }}</span>{{ terms_2 }}<span class="terms" @click="nav2Privacy">{{ terms_privacy }}</span>{{ terms_3 }}</p>
            <div class="modal-btn-confirm" @click="confirm">
                <p>{{ confirm_txt }}</p>
            </div>
            <div class="modal-btn-cancle" v-if="show_cancel" @click="cancel">
                <p>{{ cancel_txt }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import i18n from "../../locales";
export default {
    name: "UniModal",
    props: {
        show: Boolean,
        finish_loading: Boolean,
        title: String,
        content: String,
        show_cancel: Boolean,
        confirm_txt: String,
        cancel_txt: String,
        is_terms: Boolean
    },
    data() {
        return {
            terms_1: i18n.t('Modal.terms_1'),
            terms_2: i18n.t('Modal.terms_2'),
            terms_3: i18n.t('Modal.terms_3'),
            terms_agreement: i18n.t('Modal.terms_agreement'),
            terms_privacy: i18n.t('Modal.terms_privacy')
        }
    },
    methods: {
        confirm() {
            this.success && this.success(true);
            let uni_modal = document.getElementById('uni-modal');
            this.stopWatch()
            this.show = false
            setTimeout(() => {
                try {
                    uni_modal.parentNode.removeChild(uni_modal)
                } catch (err) { }
            }, 400)
        },
        cancel() {
            this.success && this.success(false);
            let uni_modal = document.getElementById('uni-modal');
            this.stopWatch()
            this.show = false
            setTimeout(() => {
                try {
                    uni_modal.parentNode.removeChild(uni_modal)
                } catch (err) { }
            }, 400)
        },
        wathcKeyboard(e) {
            let ev = document.all ? window.event : e
            if (ev.keyCode === 13 || ev.keyCode === 108) {
                this.confirm()
            } else if (ev.keyCode === 27 || ev.keyCode === 32) {
                this.cancel()
            }
        },
        startWatch() {
            document.addEventListener('keydown', this.wathcKeyboard)
        },
        stopWatch() {
            document.removeEventListener('keydown', this.wathcKeyboard)
        },
        nav2Agreement() {
            const user_school_label = localStorage.getItem('user_school_label')
            window.open('https://terms.tripleuni.com/' + user_school_label.toLowerCase() + '/agreement')
        },
        nav2Privacy() {
            const user_school_label = localStorage.getItem('user_school_label')
            window.open('https://terms.tripleuni.com/' + user_school_label.toLowerCase() + '/privacy')
        }
    }
};
</script>
  
<style scoped>
.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, 0.7);
    align-items: center;
    justify-content: center;
    display: flex;
}

.terms{
    color: var(--link);
    cursor: pointer;
    text-decoration: underline;

}

@media (prefers-color-scheme: dark) {
    .overlay {
        background-color: rgb(255, 255, 255, 0.7);
    }
}

.modal-container {
    display: block;
    padding: 1rem;
    background-color: var(--bg);
    color: var(--text);
    border: 0.1rem solid var(--border-grey);
    width: 80%;
    max-width: 20rem;
    margin: 1rem auto;
    border-radius: 1rem;
}

.modal-container h3 {
    line-height: 1rem;
}

.modal-container p {
    font-size: 1rem;
    line-height: 1.5rem;
}

.modal-btn-confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0rem;
    padding: 0.8rem 1rem;
    gap: 0.5rem;
    background-color: var(--main);
    border-radius: 2rem;
    border: 0.1rem solid var(--border-grey);
    margin-top: 2rem;
}

.modal-btn-confirm p {
    font-size: 1rem;
    color: #FFFFFF;
    margin: 0;
    font-weight: 600;
}

.modal-btn-confirm:hover {
    background-color: var(--main-darker);
    cursor: pointer;
}

.modal-btn-cancle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0rem;
    padding: 0.8rem 1rem;
    gap: 0.5rem;
    background-color: var(--bg);
    border-radius: 2rem;
    border: 0.1rem solid var(--border-grey);
}

.modal-btn-cancle p {
    font-size: 1rem;
    color: var(--text);
    margin: 0;
    font-weight: 600;
}

.modal-btn-cancle:hover {
    background-color: var(--hover-grey);
    cursor: pointer;
}

.overlay-anim-open {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: overlay-anim-open;
    animation-name: overlay-anim-open;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

.overlay-anim-close {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: overlay-anim-close;
    animation-name: overlay-anim-close;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

.modal-container-anim-open {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: container-anim-open;
    animation-name: container-anim-open;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

.modal-container-anim-close {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: container-anim-close;
    animation-name: container-anim-close;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

@-webkit-keyframes container-anim-open {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0, 0, 1);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
    }
}

@keyframes container-anim-open {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0, 0, 1);
        transform: scale3d(0, 0, 1);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@-webkit-keyframes container-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale3d(0.5, 0.5, 1);
    }
}

@keyframes container-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale3d(0.5, 0.5, 1);
        transform: scale3d(0.5, 0.5, 1);
    }
}

@-webkit-keyframes overlay-anim-open {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes overlay-anim-open {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes overlay-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes overlay-anim-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>
  