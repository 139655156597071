<template>
    <div class="body body-anim-open">
        <img src="../assets/logo-512.png" class="logo" />
        <img :src="title_src" class="title title-anim-open" />
    </div>
</template>

<script>
export default {
    name: 'UniSplash',
    data() {
        return {
            title_src: null
        }
    },
    mounted() {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            this.title_src = require('../assets/logo-title-dark.png')
        } else {
            this.title_src = require('../assets/logo-title-light.png')
        }
    }
}
</script>


<style scoped>
.body {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: var(--bg);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.logo {
    width: 30vh;
    height: 30vh;
    position: absolute;
    top: 20vh;
    left: calc(50% - 15vh);
}

.title {
    width: 26vh;
    height: 26vh;
    position: absolute;
    bottom: 16vh;
    left: calc(50% - 13vh);
}

.body-anim-open {
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: body-anim-open;
    animation-name: body-anim-open;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

@-webkit-keyframes body-anim-open {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes body-anim-open {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.title-anim-open {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: title-anim-open;
    animation-name: title-anim-open;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

@-webkit-keyframes title-anim-open {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes title-anim-open {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 700px) {
    .logo {
        width: 26vh;
        height: 26vh;
        position: absolute;
        top: 20vh;
        left: calc(50% - 13vh);
    }

    .title {
        width: 22.5vh;
        height: 22.5vh;
        position: absolute;
        bottom: 16vh;
        left: calc(50% - 11.25vh);
    }
}
</style>